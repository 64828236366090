import { useGetLocationsQuery } from "@/api/queries/locationsQueries";
import CreateLocation from "@/components/features/manage/manage-locations/CreateLocation";
import DeleteLocation from "@/components/features/manage/manage-locations/DeleteLocation";
import EditLocation from "@/components/features/manage/manage-locations/EditLocation";
import { TanstackTable } from "@/components/features/manage/tanstack-table/TanstackTable";
import { Button } from "@/components/ui/button/Button";
import { AlertDialog } from "@/components/ui/dialog/AlertDialog";
import { Dialog } from "@/components/ui/dialog/Dialog";
import { Checkbox } from "@/components/ui/input/checkbox/Checkbox";
import { LocationI } from "@/types/locations";
import { selectedRowsI } from "@/types/table";
import { ColumnDef } from "@tanstack/react-table";
import { Edit, Plus, Trash2 } from "lucide-react";
import { useMemo, useState } from "react";

export default function ManageLocations() {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openLocationAdd, setOpenLocationAdd] = useState<boolean>(false);

  const [selectedRows, setSelectedRows] = useState<selectedRowsI<LocationI>[]>(
    [],
  );
  const [selectedLocations, setSelectedLocations] = useState<
    LocationI | undefined
  >(undefined);

  const { data = [], isPending } = useGetLocationsQuery();

  const getSelectedRowsFn = (rows: LocationI[]) => {
    setSelectedRows(
      rows.map((row) => {
        return { data: row, status: "isIdle" };
      }),
    );
    setOpenDelete(true);
  };

  const triggerPopupEdit = (location: LocationI) => {
    if (location) {
      setSelectedLocations(location);
      setOpenEdit(true);
    }
  };

  const triggerPopupAddLocation = () => {
    setOpenLocationAdd(true);
  };

  const addGroup = useMemo(() => {
    return [
      {
        id: 1,
        label: "Dodaj Lokację",
        icon: <Plus className="h-4 w-4" />,
        func: () => triggerPopupAddLocation(),
      },
    ];
  }, []);

  const columns: ColumnDef<LocationI>[] = useMemo(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected()
                ? true
                : table.getIsSomePageRowsSelected()
                  ? "indeterminate"
                  : false
            }
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label={"Select row"}
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        meta: "Miejsce",
        id: "name",
        accessorKey: "name",
        header: () => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              className={"w-fit"}
            >
              Miejsce
            </Button>
          );
        },
      },
      {
        meta: "Opis",
        id: "desc",
        accessorKey: "desc",
        header: () => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              className={"w-fit"}
            >
              Opis
            </Button>
          );
        },
      },
      {
        meta: "Współrzędne",
        id: "coordinates",
        accessorKey: "coordinates",
        header: () => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              className={"w-fit"}
            >
              Współrzędne
            </Button>
          );
        },
      },
      {
        id: "actions",
        enableHiding: false,
        enableSorting: false,
        cell: ({ row }) => {
          const rowOrigin = row.original;
          return (
            <div className={"flex w-full justify-end"}>
              <Button
                size={"sm"}
                variant={"ghost"}
                variantColor={"destructive"}
                iconPosition={"only"}
                icon={<Trash2 />}
                onClick={() => getSelectedRowsFn([rowOrigin])}
              />
              <Button
                size={"sm"}
                variant={"ghost"}
                variantColor={"muted"}
                iconPosition={"only"}
                icon={<Edit />}
                onClick={() => triggerPopupEdit(rowOrigin)}
              />
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Dialog open={openLocationAdd} onOpenChange={setOpenLocationAdd}>
        <CreateLocation onOpenChange={setOpenLocationAdd} />
      </Dialog>
      <Dialog open={openEdit} onOpenChange={setOpenEdit}>
        <EditLocation
          location={selectedLocations}
          onClose={() => {
            setOpenEdit(false);
            setSelectedLocations(undefined);
          }}
        />
      </Dialog>
      <AlertDialog open={openDelete} onOpenChange={setOpenDelete}>
        <DeleteLocation
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          open={openDelete}
          onOpenChange={setOpenDelete}
        />
      </AlertDialog>
      <div className={"default-page-col"}>
        <TanstackTable
          className={
            "viewport-height sticky top-4 flex flex-col gap-4 md:h-auto sm:relative"
          }
          label={"Lokacje"}
          name={"locations"}
          isLoading={isPending}
          columns={columns}
          defaultColumnVisibility={{
            desc: false,
          }}
          data={data}
          getSelectedRows={getSelectedRowsFn}
          filterBy={[]}
          addGroup={addGroup}
        />
      </div>
    </>
  );
}
