import axios, { AxiosError, AxiosResponse, isAxiosError } from "axios";

const BASE_URL = import.meta.env.VITE_API_DOMAIN;
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

type ArgsErrorData =
  | {
      title?: string;
      detail?: string;
    }
  | undefined;

const defaultErrorData = {
  title: "Wystąpił Błąd",
  detail: "Wystąpił nieoczekiwany błąd",
};

interface HttpData {
  title: string;
  detail: string;
  status?: string | number;
}

export class HttpError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "HttpError";
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

function httpErrorHandler(
  error: Error | AxiosError<HttpData> | null | undefined,
  errorData: ArgsErrorData = undefined,
): HttpData {
  if (isAxiosError(error)) {
    //here we have a type guard check, error inside this if will be treated as AxiosError
    const response = error?.response;
    const request = error?.request;
    // const config = error?.config; //here we have access the config used to make the api call (we can make a retry using this conf)

    if (error.code === "ERR_NETWORK") {
      return {
        title: "Network Error",
        detail: "Please check your internet connection",
      };
    } else if (error.code === "ERR_CANCELED") {
      return {
        title: "Request Canceled",
        detail: "The request was canceled",
      };
    }
    if (response) {
      //The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
      const statusCode = response?.status;
      return {
        title:
          errorData?.title || response.data.title || defaultErrorData.title,
        detail:
          errorData?.detail || response.data.detail || defaultErrorData.detail,
        status: statusCode,
      };
    } else if (request) {
      //The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js
      return {
        title: "Request Error",
        detail: "No response received from the server",
      };
    }
  }
  //Something happened in setting up the request and triggered an Error
  return {
    title: errorData?.title || defaultErrorData.title,
    detail: errorData?.detail || error?.message || defaultErrorData.detail,
  };
}

function responseHandler(response: AxiosResponse<any>) {
  if (response.status == 200) {
    const data = response?.data;
    if (!data) {
      throw new HttpError("API Error. No data!");
    }
    return data;
  }
  throw new HttpError("API Error! Invalid status code!");
}

axiosInstance.interceptors.response.use(responseHandler);
export { axiosInstance, responseHandler, httpErrorHandler };
