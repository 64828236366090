class WebPushService {
  static hasPermission() {
    return Notification.permission === "granted";
  }

  static isSupported() {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

    const isSafari =
      isIOS && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    return (
      (!isIOS || isSafari) &&
      "serviceWorker" in navigator &&
      "PushManager" in window &&
      "Notification" in window
    );
  }

  static async requestNotificationPermission() {
    return await Notification.requestPermission();
  }

  static async getSubscription() {
    return await navigator.serviceWorker.ready.then(async (registration) => {
      return await registration.pushManager.getSubscription();
    });
  }

  static async subscribe() {
    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: import.meta.env.VITE_VAPID_PUBLIC_KEY,
    });
    return subscription;
  }

  static async unsubscribe() {
    const subscription = await this.getSubscription();
    if (subscription) {
      await subscription.unsubscribe();
    }
    return subscription;
  }
}

export default WebPushService;
