import {
  addGroup,
  deleteGroup,
  editGroup,
  getAssignedGroups,
  getGroup,
  getGroupMeetings,
  getGroupUsers,
  getGroups,
} from "@/api/endpoints/groups";
import { GetMeetingsPropsT } from "@/api/endpoints/meetings";
import { queryClient } from "@/api/query-client";
import {
  GroupAddSchemaType,
  GroupEditSchemaType,
} from "@/schemas/group.schema";
import { AssignedGroupI, GroupsI } from "@/types/groups";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";

export const useGetGroupsInfiniteQuery = ({
  name,
  enabled = true,
}: {
  name?: string;
  enabled?: boolean;
}) => {
  return useInfiniteQuery({
    queryKey: ["groupsInfinite", name],
    queryFn: ({ pageParam }) => getGroups({ ...pageParam, name }),
    initialPageParam: { page: 0, pageSize: 20, name: undefined },
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length < lastPageParam.pageSize) {
        return undefined;
      }
      return { ...lastPageParam, page: lastPageParam.page + 1 };
    },
    enabled: enabled || !!name ? true : false,
  });
};

export const useGetAssignedGroupsInfiniteQuery = ({
  name,
}: {
  name?: string;
}) => {
  return useInfiniteQuery({
    queryKey: ["assignedGroupsInfinite", name],
    queryFn: ({ pageParam }) => getAssignedGroups({ ...pageParam, name }),
    initialPageParam: { page: 0, pageSize: 20, name: undefined },
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length < lastPageParam.pageSize) {
        return undefined;
      }
      return { ...lastPageParam, page: lastPageParam.page + 1 };
    },
  });
};

export const useGetGroupsQuery = () => {
  return useQuery({
    queryKey: ["groups"],
    queryFn: () => getGroups(),
  });
};

export const useGetGroupQuery = (id?: number) => {
  return useQuery({
    queryKey: ["groups", id],
    queryFn: () => getGroup(id),
    enabled: !!id, // only run the query if id is not null or undefined
  });
};

export const useGetGroupMeetingsInfiniteQuery = (
  queryParams: GetMeetingsPropsT,
) => {
  return useInfiniteQuery({
    queryKey: ["groupMeetingsInfinite", queryParams],
    queryFn: ({ pageParam }) => {
      return getGroupMeetings(pageParam);
    },
    initialPageParam: {
      page: 0,
      pageSize: 20,
      name: undefined,
      sortOrder: "ASC",
      ...queryParams,
    },
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length < lastPageParam.pageSize) {
        return undefined;
      }
      return { ...lastPageParam, page: lastPageParam.page + 1 };
    },
  });
};

export const getGroupPrefetch = ({
  id,
  initialData,
}: {
  id: number;
  initialData: AssignedGroupI;
}) => {
  queryClient.prefetchQuery({
    queryKey: ["groups", id],
    queryFn: () => getGroup(id),
    initialData: initialData,
    staleTime: 60000,
  });
};

export const useGetGroupUsersQuery = (id?: number) => {
  return useQuery({
    queryKey: ["groupUsers", id],
    queryFn: () => getGroupUsers(id),
    enabled: !!id, // only run the query if id is not null or undefined
  });
};

export const usePostGroupMutation = () => {
  return useMutation({
    mutationFn: (data: GroupAddSchemaType) => addGroup(data),
    onSuccess: (data) => {
      queryClient.setQueryData(["groups"], (curr: GroupsI[]) => [
        ...curr,
        data,
      ]);
    },
  });
};

export const useEditGroupMutation = () => {
  return useMutation({
    mutationFn: (data: GroupEditSchemaType) => editGroup(data),

    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["groups"],
      });
      queryClient.invalidateQueries({
        queryKey: ["groups", variables?.id],
      });
      queryClient.invalidateQueries({
        queryKey: ["groupUsers", variables?.id],
      });

      variables.memberChanges?.forEach((memberChange) => {
        memberChange.userIds.forEach((userId) => {
          queryClient.invalidateQueries({
            queryKey: ["userGroups", userId],
          });
        });
      });
    },
  });
};

export const useDeleteGroupMutation = () => {
  return useMutation({
    mutationFn: (data: { id: number }) => deleteGroup(data),
    onSuccess: (data, { id }) => {
      queryClient.setQueryData(["groups"], (prev: GroupsI[]) =>
        prev.filter((group: GroupsI) => group.id !== id),
      );
    },
  });
};
