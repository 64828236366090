import { Button } from "@/components/ui/button/Button";
import { CheckCheck } from "lucide-react";

type NotificationsSheetFooterProps = {
  makeAllVisibleNotificationsRead: () => void;
};

export default function NotificationsSheetFooter(
  props: NotificationsSheetFooterProps,
) {
  const { makeAllVisibleNotificationsRead } = props;
  return (
    <Button
      onClick={makeAllVisibleNotificationsRead}
      size={"sm"}
      variant={"ghost"}
      variantColor={"brand"}
      iconPosition={"left"}
      icon={<CheckCheck />}
    >
      Zaznacz jako przeczytane
    </Button>
  );
}
