import { FileIcon as File, FolderOpen } from "lucide-react";

export const FileIcon = () => {
  return (
    <span className={"grid h-10 w-10 shrink-0 place-items-center rounded-sm"}>
      <File className={"h-4 w-4 stroke-fg-muted"} />
    </span>
  );
};
export const DirIcon = () => {
  return (
    <span className={"grid h-10 w-10 shrink-0 place-items-center rounded-sm"}>
      <FolderOpen className={"h-4 w-4 stroke-fg-muted"} />
    </span>
  );
};
