import { Button } from "@/components/ui/button/Button";
import { ArrowUpRight } from "lucide-react";

interface GroupElementHeaderProps {
  callback?: () => void;
  label: string;
}

export default function GroupElementHeader({
  callback,
  label,
}: GroupElementHeaderProps) {
  return (
    <header className={"flex items-center justify-between gap-2 p-4"}>
      <h3 className={"w-full truncate text-lg font-semibold"}>{label}</h3>
      {callback && (
        <Button
          size={"sm"}
          variant={"ghost"}
          variantColor={"muted"}
          onClick={callback}
          icon={<ArrowUpRight />}
          iconPosition={"only"}
        />
      )}
    </header>
  );
}
