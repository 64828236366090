import { PermissionsI } from "@/types/files";
import { useMemo } from "react";

interface useFSEntryPermissionsProps {
  permissions?: PermissionsI;
}

export default function useFSEntryPermissions({
  permissions,
}: useFSEntryPermissionsProps) {
  return useMemo(() => {
    const readable = { type: "read", title: "Przeglądający" };
    const writable = {
      type: "write",
      title: "Edytujący",
    };
    const editable = { type: "edit", title: "Pełen dostęp" };

    return {
      permissionsArray: [readable, writable, editable],
      currentPermission: permissions?.edit
        ? editable
        : permissions?.write
          ? writable
          : readable,
    };
  }, [permissions]);
}
