import ChatIllustration from "@/assets/images/chat.svg?react";
import Message, {
  ChatMessageSkeleton,
} from "@/components/features/chat/Message";
import { InfiniteScroll } from "@/components/ui/infinite-scroll/InfiniteScroll";
import { useChatStore } from "@/store/chatStore";
import { conversationTypeT } from "@/types/chat";
import { forwardRef, useMemo } from "react";

interface ChatConversationContentProps {
  getHistory: () => void;
  onDelete: (id: number) => void;
  conversationType: conversationTypeT;
}

const ChatContent = forwardRef<HTMLDivElement, ChatConversationContentProps>(
  ({ getHistory, onDelete, conversationType }, ref) => {
    const messages = useChatStore.useMessages();
    const hasNextPage = useChatStore.useHasPreviousPage();
    const isDataLoading = useChatStore.useIsDataLoading();
    const isError = useChatStore.useIsError();
    const isEmpty = useMemo(
      () => messages.length === 0 && !hasNextPage,
      [messages, hasNextPage],
    );

    const nextPage = () => {
      getHistory();
    };

    return (
      <div
        ref={ref}
        className={
          "flex h-full w-full grow flex-col-reverse overflow-x-hidden overflow-y-scroll overscroll-contain scroll-smooth p-4"
        }
        style={{
          overflowAnchor: "none",
        }}
      >
        <div className={"flex w-full flex-col gap-2"}>
          <InfiniteScroll
            hasNextPage={hasNextPage}
            isLoading={isDataLoading}
            isFetching={isDataLoading}
            next={nextPage}
            threshold={0.65}
            reverse={true}
            rootMargin={"0px 0px 100px 0px"}
          >
            {hasNextPage && !isError && <ChatMessageSkeleton />}
          </InfiniteScroll>
          {messages.map((message) => (
            <Message
              key={message.id ? message.id : message.tempUUID}
              message={message}
              onDelete={onDelete}
              conversationType={conversationType}
            />
          ))}
          {isEmpty ? (
            <div
              className={
                "flex flex-col items-center justify-center gap-4 py-14"
              }
            >
              <ChatIllustration className={"max-h-[10rem] max-w-[10rem]"} />
              <p
                className={"text-center text-lg font-semibold text-fg-primary"}
              >
                Brak wiadomości
              </p>
              <p className={"text-center text-xs text-fg-muted"}>
                Brak wiadomości dla wskazanego chatu, <br />
                bądź pierwszym który wyśle wiadomość.
              </p>
            </div>
          ) : null}
        </div>
      </div>
    );
  },
);
ChatContent.displayName = "ChatContent";

export default ChatContent;
