import { TimeSlider, TimeSliderInstance, VolumeSlider } from "@vidstack/react";
import { useEffect, useRef } from "react";

export function Volume() {
  return (
    <VolumeSlider.Root className="volume-slider group relative mx-[7.5px] inline-flex h-10 w-full max-w-[80px] cursor-pointer touch-none select-none items-center outline-none aria-hidden:hidden">
      <VolumeSlider.Track className="ring-media-focus bg-white/30 relative z-0 h-[5px] w-full rounded-sm group-data-[focus]:ring-[3px]">
        <VolumeSlider.TrackFill className="bg-media-brand absolute h-full w-[var(--slider-fill)] rounded-sm will-change-[width]" />
      </VolumeSlider.Track>

      <VolumeSlider.Preview
        className="pointer-events-none flex flex-col items-center opacity-0 transition-opacity duration-200 data-[visible]:opacity-100"
        noClamp
      >
        <VolumeSlider.Value className="bg-black rounded-sm px-2 py-px text-xs font-medium" />
      </VolumeSlider.Preview>
      <VolumeSlider.Thumb className="bg-white ring-white/40 absolute left-[var(--slider-fill)] top-1/2 z-20 h-[15px] w-[15px] -translate-x-1/2 -translate-y-1/2 rounded-full border border-[#cacaca] opacity-0 transition-opacity will-change-[left] group-data-[active]:opacity-100 group-data-[dragging]:ring-4" />
    </VolumeSlider.Root>
  );
}

export interface TimeSliderProps {
  thumbnails?: string;
}

export function Time({ thumbnails }: TimeSliderProps) {
  const sliderRef = useRef<TimeSliderInstance | null>(null);

  const preventEvent = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      const events = [
        "scroll",
        "sliderAmount",
        "pointerdown",
        "pointerup",
        "slidesChanged",
        "settle",
        "slidesInView",
        "touchstart",
        "touchmove",
        "touchend",
      ];
      events.forEach((event) => slider.addEventListener(event, preventEvent));
    }

    return () => {
      if (slider) {
        const events = [
          "scroll",
          "sliderAmount",
          "pointerdown",
          "pointerup",
          "slidesChanged",
          "settle",
          "slidesInView",
          "touchstart",
          "touchmove",
          "touchend",
        ];
        events.forEach((event) =>
          slider.removeEventListener(event, preventEvent),
        );
      }
    };
  }, []);

  return (
    <TimeSlider.Root
      ref={sliderRef}
      className={
        "time-slider group pointer-events-auto relative mx-1 inline-flex h-10 w-full cursor-pointer touch-none select-none items-center overscroll-contain outline-none"
      }
    >
      <TimeSlider.Chapters
        className={"relative flex h-full w-full items-center rounded-sm"}
      >
        {(cues, forwardRef) =>
          cues.map((cue) => (
            <div
              className={
                "last-child:mr-0 relative mr-0.5 flex h-full w-full items-center rounded-sm"
              }
              style={{ contain: "layout style" }}
              key={cue.startTime}
              ref={forwardRef}
            >
              <TimeSlider.Track
                className={
                  "ring-media-focus relative z-0 h-2 w-full rounded-sm bg-bg-element/30 group-data-[focus]:ring-1"
                }
              >
                <TimeSlider.Progress
                  className={
                    "absolute h-full w-[var(--chapter-progress)] rounded-sm bg-bg-element/70 will-change-[width]"
                  }
                />
                <TimeSlider.TrackFill
                  className={
                    "absolute z-10 h-full w-[var(--chapter-fill)] rounded-sm bg-bg-brand will-change-[width]"
                  }
                />
              </TimeSlider.Track>
            </div>
          ))
        }
      </TimeSlider.Chapters>
      <TimeSlider.Thumb
        className={
          "absolute left-[var(--slider-fill)] top-1/2 z-20 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rounded-full border border-border bg-bg-container opacity-0 ring-ring transition-opacity will-change-[left] group-data-[active]:opacity-100 group-data-[dragging]:ring-2"
        }
      />

      <TimeSlider.Preview
        className={
          "pointer-events-none flex flex-col items-center opacity-0 transition-opacity duration-200 data-[visible]:opacity-100"
        }
      >
        {thumbnails ? (
          <TimeSlider.Thumbnail.Root
            src={thumbnails}
            className={
              "border-white block h-[var(--thumbnail-height)] max-h-[160px] min-h-[80px] w-[var(--thumbnail-width)] min-w-[120px] max-w-[180px] overflow-hidden border bg-bg-container"
            }
          >
            <TimeSlider.Thumbnail.Img />
          </TimeSlider.Thumbnail.Root>
        ) : null}

        <TimeSlider.ChapterTitle className={"mt-2 text-xs"} />

        <TimeSlider.Value className={"text-xs"} />
      </TimeSlider.Preview>
    </TimeSlider.Root>
  );
}
