import { cn } from "@/lib/utils";
import * as SwitchPrimitives from "@radix-ui/react-switch";
import { VariantProps, cva } from "class-variance-authority";
import React from "react";

const switchVariants = cva(
  "peer inline-flex h-7 w-12 p-1 shrink-0 cursor-pointer items-center rounded-2xl border-2 border-transparent transition-colors ring-offset-bg focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50",
  {
    variants: {
      variantColor: {
        brand:
          "data-[state=checked]:bg-bg-brand data-[state=checked]:hover:bg-bg-brand/80 data-[state=unchecked]:bg-bg-muted-subtle data-[state=unchecked]:hover:bg-bg-muted",
        accent:
          "data-[state=checked]:bg-bg-accent data-[state=checked]:hover:bg-bg-accent/80 data-[state=unchecked]:bg-bg-muted-subtle data-[state=unchecked]:hover:bg-bg-muted",
      },
    },
    defaultVariants: {
      variantColor: "brand",
    },
  },
);
interface switchProps
  extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>,
    VariantProps<typeof switchVariants> {}

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  switchProps
>(({ className, variantColor, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(switchVariants({ variantColor }), className)}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        "pointer-events-none block h-5 min-h-5 w-5 min-w-5 rounded-full bg-bg-container shadow-sm ring-0 transition-transform data-[state=checked]:translate-x-0 data-[state=unchecked]:translate-x-4",
      )}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
