import { httpErrorHandler } from "@/api/api";
import {
  useEditDirectoriesMutation,
  useGetDirectoryQuery,
} from "@/api/queries/directoryQueries";
import { useEditFilesMutation } from "@/api/queries/filesQueries";
import {
  DirectorySmallCard,
  DirectorySmallCardSkeleton,
} from "@/components/features/files/DirectoryCard";
import FSPath from "@/components/features/files/FsPath";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/dialog/AlertDialog";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@/components/ui/dialog/Dialog";
import { useToast } from "@/components/ui/toast/useToast";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import { useFilesStorage } from "@/store/filesStore";
import { DirectoryDirI, DirectoryElementsI } from "@/types/files";
import { DialogTitle } from "@radix-ui/react-dialog";
import {
  ChevronRight,
  FolderInput,
  FolderKey,
  FolderLock,
  HardDrive,
} from "lucide-react";
import { Fragment, useMemo, useState } from "react";
import { useParams } from "react-router";

interface StorageElementMoveFormProps {
  elements: DirectoryElementsI;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  submitCallback?: () => void;
  directoryId?: string;
}

export default function MoveFSEntry({
  elements,
  open,
  onOpenChange,
  submitCallback,
  directoryId,
}: StorageElementMoveFormProps) {
  const { toast } = useToast();

  const { model } = useFilesStorage();
  const { directoryId: defaultDirectoryId } = useCredentials();
  const { id } = useParams();
  const pageDirID = useMemo(() => {
    return id ? id : defaultDirectoryId;
  }, [id, defaultDirectoryId]);

  const selectedLength = useMemo(
    () => elements.directories.length + elements.files.length,
    [elements],
  );

  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [selectedDir, setSelectedDir] = useState<DirectoryDirI | null>(null);
  const [currentDirectoryId, setCurrentDirectoryId] =
    useState<string>(pageDirID);

  const {
    data: directory,
    isLoading,
    isSuccess,
    isError,
  } = useGetDirectoryQuery(currentDirectoryId, model === "my_drive");

  const {
    executeMutations: filesMutations,
    isPending: filesMutationIsPending,
  } = useEditFilesMutation(directoryId);
  const {
    executeMutations: directoriesMutations,
    isPending: directoriesMutationIsPending,
  } = useEditDirectoriesMutation(directoryId);

  const directories = useMemo(() => {
    if (!isSuccess) {return [];}
    return directory.childDirs.map((dir) => {
      if (elements.directories.includes(dir)) {
        return {
          dir: dir,
          isSelected: true,
          isWritable: dir.permissions.write,
        };
      }
      return {
        dir: dir,
        isSelected: false,
        isWritable: dir.permissions.write,
      };
    });
  }, [directory?.childDirs, elements]);

  const onChangeSelect = (dir: DirectoryDirI) => {
    if (dir.id === selectedDir?.id) {
      setSelectedDir(null);
    } else {
      setSelectedDir(dir);
    }
  };

  const onRouteChange = (id: string) => {
    setCurrentDirectoryId(id);
    setSelectedDir(null);
  };

  const onAlertDialogOpen = () => {
    setOpenAlertDialog(true);
  };

  const handleSubmit = async () => {
    if (selectedDir === null) {return;}
    const { files, directories } = { ...elements };

    await filesMutations(
      files.map((file) => ({ id: file.id, directoryId: selectedDir.id })),
    ).catch((error) => {
      const { title, detail } = httpErrorHandler(error);
      toast({
        title: title,
        description: detail,
        variant: "destructive",
      });
    });
    await directoriesMutations(
      directories.map((directory) => ({
        id: directory.id,
        parentDirId: selectedDir.id,
      })),
    ).catch((error) => {
      const { title, detail } = httpErrorHandler(error);
      toast({
        title: title,
        description: detail,
        variant: "destructive",
      });
    });

    submitCallback?.();
    handleClose();
  };

  const handleClose = () => {
    onOpenChange(false);
    setOpenAlertDialog(false);
  };

  return (
    <Fragment>
      <AlertDialog open={openAlertDialog} onOpenChange={setOpenAlertDialog}>
        <AlertDialogContent className={"max-w-[47ch]"}>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Czy na pewno chcesz przenieść element{selectedLength > 1 && "ów"}{" "}
              ?
            </AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogBody className={"flex flex-col gap-4"}>
            <p className={"text-fm-muted text-sm"}>
              Ten element będzie widoczny dla wszystkich użytkowników, którzy
              mogą wyświetlać folder „{selectedDir?.name}”
            </p>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              onClick={() => setOpenAlertDialog(false)}
              variant={"ghost"}
              variantColor={"muted"}
            >
              Anuluj
            </Button>
            <Button onClick={handleSubmit} variant={"flat"}>
              Potwierdź
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Dialog onOpenChange={onOpenChange} open={open}>
        <DialogContent className={"h-[654px] w-[50ch] sm:h-full sm:w-full"}>
          <DialogHeader>
            <DialogTitle>
              Przenieś: {selectedLength} element{selectedLength > 1 && "ów"}
            </DialogTitle>
          </DialogHeader>
          <DialogBody className={"flex flex-col gap-4"}>
            <FSPath
              fsEntry={directory}
              isLoading={isLoading}
              isError={isError}
              onRouteChange={onRouteChange}
            />
            <div className={"flex items-center gap-2"}>
              <Button
                size={"sm"}
                onClick={() => onRouteChange(pageDirID)}
                variant={"outline"}
                variantColor={"muted"}
                iconPosition={"left"}
                icon={<HardDrive />}
              >
                Aktualna lokacja
              </Button>
            </div>
            <div className="flex flex-col gap-1">
              {directories.map(({ dir, isSelected, isWritable }) => {
                const isDisabled = isSelected || !isWritable;
                let icon = null;
                let menuButton = null;
                if (isSelected) {
                  icon = <FolderKey className={"h-4 w-4 text-fg-muted"} />;
                  menuButton = (
                    <Badge variant={"outline"} size={"sm"}>
                      Folder Przenoszony
                    </Badge>
                  );
                } else if (!isWritable) {
                  icon = <FolderLock className={"h-4 w-4 text-fg-muted"} />;
                  menuButton = (
                    <Badge variant={"destructive"} size={"sm"}>
                      Tylko do odczytu
                    </Badge>
                  );
                } else {
                  icon = <FolderInput className={"h-4 w-4 text-fg-muted"} />;
                  menuButton = (
                    <div className="flex shrink-0">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          onAlertDialogOpen();
                          setSelectedDir(dir);
                        }}
                        size={"sm"}
                        variant={"ghost"}
                        variantColor={"muted"}
                      >
                        Przenieś
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          onRouteChange(dir.id);
                        }}
                        size={"sm"}
                        variant={"ghost"}
                        variantColor={"muted"}
                        iconPosition={"only"}
                        icon={<ChevronRight />}
                      />
                    </div>
                  );
                }
                return (
                  <DirectorySmallCard
                    tabIndex={0}
                    key={dir.id}
                    directory={dir}
                    icon={icon}
                    menuButton={menuButton}
                    onClick={() => {
                      if (isDisabled) {return;}
                      onChangeSelect(dir);
                    }}
                    className={cn(
                      isDisabled ? "cursor-not-allowed opacity-50" : "",
                      selectedDir?.id === dir.id ? "bg-bg-muted-subtle" : "",
                    )}
                  />
                );
              })}
              {isLoading && (
                <>
                  <DirectorySmallCardSkeleton />
                  <DirectorySmallCardSkeleton />
                  <DirectorySmallCardSkeleton />
                </>
              )}
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              variant={"flat"}
              variantColor={"muted"}
              onClick={handleClose}
            >
              Anuluj
            </Button>
            <Button
              isLoading={{
                state: directoriesMutationIsPending || filesMutationIsPending,
              }}
              disabled={!selectedDir}
              variant={"flat"}
              variantColor={"brand"}
              onClick={onAlertDialogOpen}
            >
              Przenieś
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
