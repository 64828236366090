import {
  Locale,
  addWeeks,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isThisWeek,
  isThisYear,
  isToday,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { pl } from "date-fns/locale";

function formatDate(date: Date, options?: { locale?: Locale }): string {
  const { locale = pl } = options || {};

  if (isToday(date)) {
    return format(date, "HH:mm", { locale: locale });
  } else if (isThisWeek(date, { weekStartsOn: 1 })) {
    return format(date, "EEE", { locale: locale }); // Mon-Sun
  } else if (isThisYear(date)) {
    return format(date, "dd MMM", { locale: locale }); // Date and month
  } else {
    return format(date, "dd MMM yyyy", { locale: locale }); // Date, month, and year
  }
}

function getMonth(date: Date, options?: { fixedWeeks?: boolean }): Date[] {
  const start = startOfMonth(date);
  const end = endOfMonth(date);

  if (options?.fixedWeeks) {
    const weekStart = startOfWeek(start, { weekStartsOn: 1 }); // week starts on Monday
    let weekEnd = addWeeks(weekStart, 5);
    weekEnd = endOfWeek(weekEnd, { weekStartsOn: 1 }); // week starts on Monday
    return eachDayOfInterval({ start: weekStart, end: weekEnd });
  }

  return eachDayOfInterval({ start, end });
}

function getWeek(date: Date): Date[] {
  const start = startOfWeek(date, { weekStartsOn: 1 }); // week starts on Monday
  const end = endOfWeek(date, { weekStartsOn: 1 }); // week starts on Monday

  return eachDayOfInterval({ start, end });
}

export { formatDate, getWeek, getMonth };
