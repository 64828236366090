import { toggleVariants } from "@/components/ui/toggle/Toggle";
import { cn } from "@/lib/utils";
import { useDOMRef } from "@/utils/dom";
import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";
import { VariantProps } from "class-variance-authority";
import { X } from "lucide-react";
import * as React from "react";

const ToggleGroupContext = React.createContext<
  VariantProps<typeof toggleVariants> & { removable?: boolean }
>({
  size: "md",
  variant: "default",
  removable: false,
});

const ToggleGroup = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root> &
    VariantProps<typeof toggleVariants> & { removable?: boolean }
>(({ className, variant, size, children, removable, ...props }, ref) => (
  <ToggleGroupPrimitive.Root
    ref={ref}
    className={cn("flex items-center justify-center gap-2", className)}
    {...props}
  >
    <ToggleGroupContext.Provider value={{ variant, size, removable }}>
      {children}
    </ToggleGroupContext.Provider>
  </ToggleGroupPrimitive.Root>
));

ToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName;

const ToggleGroupItem = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item> &
    VariantProps<typeof toggleVariants>
>(({ className, children, variant, size, ...props }, ref) => {
  const context = React.useContext(ToggleGroupContext);
  const domRef = useDOMRef(ref);

  const handleRemove = () => {
    if (domRef) {
      const nextSibling = domRef.current?.nextSibling;
      if (nextSibling) {
        (nextSibling as HTMLElement).focus();
      }
    }
  };

  return (
    <ToggleGroupPrimitive.Item
      ref={domRef}
      onKeyDown={(event) => {
        if (context.removable && (event.key === "Enter" || event.key === " ")) {
          handleRemove();
        }
      }}
      className={cn(
        toggleVariants({
          variant: context.variant || variant,
          size: context.size || size,
        }),
        size == "lg" && "pr-10",
        size == "md" && "pr-9",
        size == "sm" && "pr-8",
        className,
      )}
      {...props}
    >
      {children}
      {context.removable && (
        <span
          onClick={handleRemove}
          className={cn(
            "absolute right-1 rounded-sm transition-all duration-100 hover:backdrop-contrast-[90%]",
            size == "lg" && "h-8 w-8",
            size == "md" && "h-7 w-7",
            size == "sm" && "h-6 w-6",
          )}
        >
          <X className={"absolute inset-0 m-auto h-4 w-4"} />
        </span>
      )}
    </ToggleGroupPrimitive.Item>
  );
});

ToggleGroupItem.displayName = ToggleGroupPrimitive.Item.displayName;

function ToggleParseToIds<TData extends { id: string | number }>(
  data: TData[],
): string[] {
  return React.useMemo(
    () => data.map((entity: TData) => entity.id.toString()),
    [data],
  );
}

function ToggleDeleteEntity<T extends { id: string | number }>(
  useReactState: React.Dispatch<React.SetStateAction<T[]>>,
  value: string[],
): void {
  useReactState((entities: T[]) => {
    return entities.filter((entity: T) => value.includes(entity.id.toString()));
  });
}

export { ToggleGroup, ToggleGroupItem, ToggleParseToIds, ToggleDeleteEntity };
