import { memberChangesT } from "@/api/endpoints/meetings";
import { InferType, number, object, string } from "yup";

const GroupAddSchema = object({
  name: string().required("Nazwa jest wymagana"),
  desc: string().optional(),
});

type GroupAddSchemaType = InferType<typeof GroupAddSchema>;

const GroupEditSchema = object({
  id: number().required(),
  name: string().required("Nazwa jest wymagana"),
  desc: string().optional(),
});

type GroupEditSchemaType = Partial<
  Omit<InferType<typeof GroupEditSchema>, "id">
> &
  Pick<InferType<typeof GroupEditSchema>, "id"> & {
    memberChanges?: memberChangesT[];
  };

export { GroupAddSchema, GroupEditSchema };
export type { GroupAddSchemaType, GroupEditSchemaType };
