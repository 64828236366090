import { Button } from "@/components/ui/button/Button";
import { cn } from "@/lib/utils";
import { useFilesStorage } from "@/store/filesStore";
import { Download, FolderSymlinkIcon, Trash2, X } from "lucide-react";
import { useCallback, useMemo } from "react";

interface FsManageBarProps {
  className?: string;
}

export default function FsManageBar({ className }: FsManageBarProps) {
  const {
    resetSelected,
    selected,
    selectedSize,
    setOpenDelete,
    setOpenDownload,
    setOpenMove,
  } = useFilesStorage();

  const size = selectedSize();

  const permissions = useMemo(() => {
    return {
      read: [...selected.directories, ...selected.files].every(
        (el) => el.permissions.read,
      ),
      edit: [...selected.directories, ...selected.files].every(
        (el) => el.permissions.edit,
      ),
      write: [...selected.directories, ...selected.files].every((el) => {
        return el.permissions.write;
      }),
    };
  }, [selected]);

  const openDownload = useCallback(() => {
    setOpenDownload(true);
  }, []);

  const openMove = useCallback(() => {
    setOpenMove(true);
  }, []);

  const openDelete = useCallback(() => {
    setOpenDelete(true);
  }, []);

  return (
    <div
      className={cn(
        "col-[span_2] row-[2] flex h-11 w-full items-center rounded-sm border-1 border-border bg-bg px-0.5",
        size === 0 && "hidden",
        className,
      )}
    >
      <Button
        onClick={resetSelected}
        size={"sm"}
        variant={"ghost"}
        variantColor={"muted"}
        icon={<X />}
        iconPosition={"only"}
      />
      <p className={"mx-2 w-14 text-xs font-medium text-fg-muted"}>
        Wybrano: {size}
      </p>
      <Button
        onClick={openMove}
        disabled={!permissions.edit}
        size={"sm"}
        variant={"ghost"}
        variantColor={"muted"}
        icon={<FolderSymlinkIcon />}
        iconPosition={"only"}
      />
      <Button
        onClick={openDownload}
        disabled={!permissions.read}
        size={"sm"}
        variant={"ghost"}
        variantColor={"muted"}
        icon={<Download />}
        iconPosition={"only"}
      />
      <Button
        onClick={openDelete}
        disabled={!permissions.edit && !permissions.write}
        size={"sm"}
        variant={"ghost"}
        variantColor={"muted"}
        icon={<Trash2 />}
        iconPosition={"only"}
      />
    </div>
  );
}
