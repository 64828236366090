import { MediaViewerFileT } from "@/components/features/media/MediaViewer";
import { Button } from "@/components/ui/button/Button";
import { DialogBody, DialogFooter } from "@/components/ui/dialog/Dialog";
import { Link } from "@/components/ui/link/Link";
import getFileUrl from "@/utils/getFileUrl";
import { Download } from "lucide-react";
import { useMemo } from "react";

export default function MediaFileViewer({ file }: MediaViewerFileT) {
  const { isID, fileURL } = useMemo(() => {
    if ("id" in file) {
      return { isID: true, fileURL: getFileUrl(file.id) };
    }
    if (file instanceof File) {
      return { isID: false, fileURL: URL.createObjectURL(file) };
    }
    return { isID: false, fileURL: "" };
  }, [file]);

  return (
    <>
      <DialogBody>
        <div className="flex h-full w-full flex-col items-center justify-center gap-3">
          <p className="text-fg-muted">Podgląd niedostępny</p>
          {isID ? (
            <Link href={fileURL}>
              <Button icon={<Download />} iconPosition={"left"}>
                Pobierz
              </Button>
            </Link>
          ) : null}
        </div>
      </DialogBody>
      <DialogFooter></DialogFooter>
    </>
  );
}
