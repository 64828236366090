import TanstackTableEmpty from "@/components/features/manage/tanstack-table/TanstackTableEmpty";
import { TableCell, TableRow } from "@/components/ui/table/Table";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { flexRender } from "@tanstack/react-table";
import { Table as TableT } from "@tanstack/table-core";

export default function TanstackTableRows<TData>({
  table,
  colSpan,
}: {
  table: TableT<TData>;
  colSpan: number;
}) {
  const breakpoint = useMediaQueryHook("md");
  const tableContentLength = table.getRowModel().rows.length;
  if (!tableContentLength) {
    return <TanstackTableEmpty colSpan={colSpan} />;
  }
  return table.getRowModel().rows.map((row) => {
    if (breakpoint) {
      return (
        <div
          key={row.id}
          className={
            "flex w-full flex-col rounded-xl border-1 border-border bg-bg-container py-4"
          }
        >
          {row.getVisibleCells().map((cell, index, cells) => {
            const isLast = index === cells.length - 1;
            return (
              <div
                key={cell.id}
                className={cn(
                  "flex min-h-7 items-center justify-between gap-3 px-4",
                  isLast ? "mt-4 border-t border-border px-4 pt-4" : null,
                )}
              >
                <label className={"w-fit shrink-0 text-sm text-fg-muted"}>
                  {cell.getContext().column.columnDef.meta as string}
                </label>
                <div className={"shrink truncate"}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return (
      <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
        {row.getVisibleCells().map((cell) => {
          return (
            <TableCell key={cell.id} className={"px-3 py-1"}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          );
        })}
      </TableRow>
    );
  });
}
