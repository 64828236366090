import EditGroup from "@/components/features/manage/manage-groups/EditGroup";
import EditGroupUsers from "@/components/features/manage/manage-groups/EditGroupUsers";
import { Button } from "@/components/ui/button/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import { GroupI } from "@/types/groups";
import { ArrowLeft, MoreVertical, PenLine, Users } from "lucide-react";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

interface GroupHeaderProps {
  group?: GroupI;
  className?: string;
}

export default function GroupHeader({ className, group }: GroupHeaderProps) {
  const { lvl } = useCredentials();

  const navigate = useNavigate();
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openEditUsers, setOpenEditUsers] = useState<boolean>(false);

  const handleNavigateBack = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      {lvl > 0 && (
        <>
          <EditGroupUsers
            id={group?.id}
            open={openEditUsers}
            onOpenChange={setOpenEditUsers}
          />
          <EditGroup
            id={group?.id}
            open={openEdit}
            onOpenChange={setOpenEdit}
          />
        </>
      )}

      <div
        className={cn(
          "flex flex-col gap-3 rounded-lg border-1 border-border bg-bg-container p-4",
          className,
        )}
      >
        <div className={"flex gap-2"}>
          <Button
            variant={"ghost"}
            variantColor={"muted"}
            icon={<ArrowLeft />}
            iconPosition={"only"}
            onClick={handleNavigateBack}
          />
          <h2 className={"self-center text-xl font-semibold text-fg-primary"}>
            {group?.name}
          </h2>
          {lvl > 0 && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant={"ghost"}
                  variantColor={"muted"}
                  icon={<MoreVertical />}
                  iconPosition={"only"}
                  className="ml-auto"
                />
              </DropdownMenuTrigger>
              <DropdownMenuContent align={"end"}>
                <DropdownMenuGroup>
                  <DropdownMenuItem onClick={() => setOpenEditUsers(true)}>
                    <Users className="h-4 w-4" />
                    Edytuj użytkowników
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => setOpenEdit(true)}>
                    <PenLine className="h-4 w-4" />
                    Edytuj grupę
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
        <div className={"gap- flex flex-wrap empty:hidden"}></div>
      </div>
    </Fragment>
  );
}
