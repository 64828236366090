import ChatIllustration from "@/assets/images/chat.svg?react";
import { Button } from "@/components/ui/button/Button";

interface ChatIndexEmptyProps {
  openCreateConversation: () => void;
}

export default function ChatIndexEmpty({
  openCreateConversation,
}: ChatIndexEmptyProps) {
  return (
    <div
      className={
        "flex grow items-center justify-center rounded-lg border-1 border-border bg-bg-container p-4"
      }
    >
      <div className={"flex flex-col items-center gap-4"}>
        <ChatIllustration className={"max-w-[20rem]"} />
        <h2 className={"text-2xl font-semibold text-fg-primary"}>
          Utwórz konwersację
        </h2>
        <p className={"text-center text-xs text-fg-muted"}>
          Aby skontaktować się z dowolnym użytkownikiem,
          <br />
          naciśnij przycisk “Utwórz konwersację” <br />
          i wyszukaj użytkownika i pytaj o co chcesz.
          <br />
        </p>
        <Button
          className={"mt-2 max-w-[25ch]"}
          variant={"outline"}
          variantColor={"muted"}
          onClick={openCreateConversation}
        >
          Utwórz konwersację
        </Button>
      </div>
    </div>
  );
}
