import NotificationImage from "@/assets/images/notification.svg?react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogBody,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/dialog/AlertDialog";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { useManageNotifications } from "@/hooks/useNotifications";
import { useEffect, useState } from "react";

export default function NotificationPermissionAlert() {
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const { isDefault, enableNotifications, disableNotifications } =
    useManageNotifications();
  const [hasPrompted, setHasPrompted] = useLocalStorage<boolean>(
    "notificationPrompted",
    false,
  );

  const onAllow = () => {
    enableNotifications();
    setHasPrompted(true);
    setShowAlert(false);
  };
  const onDeny = () => {
    disableNotifications();
    setHasPrompted(true);
    setShowAlert(false);
  };

  useEffect(() => {
    if (isDefault && !hasPrompted) {
      setShowAlert(true);
    }
    setHasPrompted(true);
  }, [isDefault, hasPrompted]);

  return (
    <AlertDialog open={showAlert} onOpenChange={setShowAlert}>
      <AlertDialogContent className={"w-[45ch]"}>
        <AlertDialogHeader>
          <AlertDialogTitle className={"sr-only"}>
            Chcesz otrzymywać powiadomienia ?
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogBody className="space-y-8 pb-10">
          <NotificationImage className="mx-auto" />
          <div className="space-y-4">
            <h2 className={"text-center text-2xl font-semibold"}>
              Pozostań na bieżąco !
            </h2>
            <p className={"text-center text-xs"}>
              Wyraź zgodę na powiadomienia, aby otrzymywać najnowsze informacje.
              Możesz w każdej chwili zmienić swoje preferencje w ustawieniach
              aplikacji.
            </p>
          </div>
        </AlertDialogBody>
        <AlertDialogFooter>
          <AlertDialogCancel
            className="w-full"
            variantColor={"muted"}
            onClick={onDeny}
          >
            Odrzuć
          </AlertDialogCancel>
          <AlertDialogAction
            className="w-full"
            variantColor={"brand"}
            onClick={onAllow}
          >
            Wyrażam zgodę
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
