import ChatTimeToPresent from "@/components/features/chat/ChatTimeToPresent";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar/Avatar";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { useAuthStore } from "@/store/authStore";
import { ConversationI } from "@/types/chat";
import { MessageI } from "@/types/chat";
import { User, Users } from "lucide-react";
import { forwardRef, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

export const ConversationsListSkeletonItem = forwardRef<HTMLDivElement>(
  (props = {}, ref) => {
    return (
      <div
        ref={ref}
        className={"inline-flex items-center gap-2 rounded-md p-2"}
        {...props}
      >
        <Skeleton className={"h-10 min-h-10 w-10 min-w-10 rounded-sm"} />
        <div className={"flex w-full flex-col gap-1"}>
          <Skeleton className={"h-3 w-[20ch]"} />
          <Skeleton className={"h-3 w-[14ch]"} />
        </div>
      </div>
    );
  },
);

ConversationsListSkeletonItem.displayName = "ConversationsListSkeletonItem";

export function ConversationsListItem({
  id,
  name,
  type,
  messages,
}: ConversationI) {
  const { id: userId } = useAuthStore.useCredentials();

  const getObjectKeyByValue = useCallback(
    (
      obj: Record<string, any>,
      targetKey: number | undefined,
    ): any | undefined => {
      const entry = Object.entries(obj).find(
        ([key]) => String(key) === String(targetKey),
      );

      return entry ? entry[1] : undefined;
    },
    [],
  );

  const {
    sender = { id: 0, name: "", surname: "" },
    content = "",
    createdAt = "",
  }: Partial<Pick<MessageI, "content" | "sender" | "createdAt">> = useMemo(
    () =>
      messages[0] ||
      ({
        content: "",
        sender: { name: "", surname: "" },
        createdAt: "",
      } as Partial<Pick<MessageI, "content" | "sender" | "createdAt">>),
    [messages],
  );

  const { name: senderName = "", surname: senderSurname = "" } = sender;

  const chatName = useMemo((): string => {
    if (type === "individual") {
      return getObjectKeyByValue(JSON.parse(name), userId);
    }
    return name;
  }, [userId, name, type]);

  return (
    <Link
      to={`/chat/${String(id)}`}
      state={{ id: id, type: type, name: chatName }}
      className={
        "inline-flex w-full items-center gap-2 rounded-md p-2 opacity-100 transition-all duration-100 ease-out hover:bg-bg-muted-subtle/45 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ring disabled:opacity-50"
      }
    >
      <Avatar size={"sm"}>
        <AvatarImage src={""} alt={"avatar"} />
        <AvatarFallback>
          {type === "group" ? (
            <Users className={"h-4 w-4"} />
          ) : (
            <User className={"h-4 w-4"} />
          )}
        </AvatarFallback>
      </Avatar>
      <div className={"ga-0.5 flex w-full flex-col"}>
        <h5 className={"min-h-4 truncate text-sm font-medium text-fg-primary"}>
          {chatName}
        </h5>
        <p className={"min-h-4 truncate text-xs text-fg-secondary"}>
          {content
            ? senderName + " " + senderSurname?.at(0) + ": " + content
            : " "}
        </p>
      </div>
      <div className={"shrink-0"}>
        <ChatTimeToPresent
          createdAt={createdAt}
          className={"text-xs text-fg-secondary"}
        />
      </div>
    </Link>
  );
}
