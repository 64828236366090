import { flatQueryPages } from "@/api/api-utils";
import { GetMeetingsPropsT } from "@/api/endpoints/meetings";
import MeetingsList from "@/components/features/calendar/MeetingsList";
import MeetingsListContent from "@/components/features/calendar/MeetingsListContent";
import MeetingsListHeader from "@/components/features/calendar/MeetingsListHeader";
import { Button } from "@/components/ui/button/Button";
import { Label } from "@/components/ui/label/Label";
import { cn } from "@/lib/utils";
import { MeetingI, MeetingsI } from "@/types/meetings";
import {
  InfiniteData,
  UseInfiniteQueryResult,
  UseQueryResult,
} from "@tanstack/react-query";
import { endOfDay, startOfDay } from "date-fns";
import { ArrowLeft } from "lucide-react";
import { Dispatch, SetStateAction, useMemo, useState } from "react";

interface CalendarCardProps {
  className?: string;
  defaultType?: "schedule" | "day";
  query:
    | UseInfiniteQueryResult<
        InfiniteData<MeetingI[] | MeetingsI[], unknown>,
        Error
      >
    | UseQueryResult<MeetingI[] | MeetingsI[], Error>;
  defaultDayParams?: Omit<GetMeetingsPropsT, "id">;
  defaultScheduleParams?: Omit<GetMeetingsPropsT, "id">;
  renderNavigateContextMenu?: (
    content: JSX.Element,
    day: Date,
    key: string,
  ) => JSX.Element;

  setQueryParams: Dispatch<SetStateAction<GetMeetingsPropsT>>;
}

function isInfiniteData(
  data: any,
): data is InfiniteData<MeetingI[] | MeetingsI[], unknown> {
  return (
    data && typeof data === "object" && "pages" in data && "pageParams" in data
  );
}

export default function CalendarCard({
  className,
  query,
  defaultDayParams,
  defaultScheduleParams,
  renderNavigateContextMenu,
  setQueryParams,
  defaultType = "day",
}: CalendarCardProps) {
  const [date, setDate] = useState<Date>(new Date());
  const [type, setType] = useState<"schedule" | "day">(defaultType);

  const handleSetDate = (day: Date) => {
    setType("day");
    setQueryParams((curr) => ({
      ...curr,
      ...(defaultDayParams
        ? defaultDayParams
        : {
            after: startOfDay(day).toISOString(),
            before: endOfDay(day).toISOString(),
          }),
      page: 0,
    }));
    setDate(day);
  };

  const handleSetSchedule = () => {
    setType("schedule");
    setQueryParams((curr) => ({
      ...curr,
      ...(defaultScheduleParams && defaultScheduleParams),
      after: startOfDay(new Date()).toISOString(),
      page: 0,
    }));
    setDate(new Date());
  };

  const { data = [] } = query;
  const meetings = useMemo(() => {
    if (isInfiniteData(data)) {
      return flatQueryPages(data);
    } else if (data !== undefined) {
      return data;
    } else {
      return [];
    }
  }, [data]);

  return (
    <MeetingsList meetings={meetings} query={query}>
      <div className={cn("flex flex-col gap-4", className)}>
        <div
          className={cn(
            "flex flex-col gap-4 overflow-hidden rounded-lg border-1 border-border bg-bg-container py-4",
            type === "schedule" ? "shrink-0" : "grow",
          )}
        >
          <MeetingsListHeader
            date={date}
            setDate={handleSetDate}
            renderContextMenu={renderNavigateContextMenu}
          />
          <div
            className={cn(
              "flex h-full grow flex-col gap-4 overflow-hidden pb-1",
              type === "schedule" && "hidden",
            )}
          >
            <MeetingsListContent />
            <div className={"flex w-full justify-end px-4"}>
              <Button
                size={"sm"}
                variant={"ghost"}
                variantColor={"brand"}
                icon={<ArrowLeft />}
                iconPosition={"left"}
                onClick={handleSetSchedule}
              >
                Do nadchodzących
              </Button>
            </div>
          </div>
        </div>
        <div
          className={cn(
            "flex grow flex-col gap-5 overflow-hidden rounded-lg border-1 border-border bg-bg-container py-4",
            type === "day" && "hidden",
          )}
        >
          <Label className={"h-10 px-4 text-lg font-semibold text-fg-primary"}>
            Nadchodzące spotkania
          </Label>
          <MeetingsListContent />
        </div>
      </div>
    </MeetingsList>
  );
}
