import { cn } from "@/lib/utils";

export default function MeetingName({
  name,
  className,
}: {
  name: string;
  className?: string;
}) {
  return (
    <p
      className={cn(
        "truncate text-start text-[clamp(0.6rem,1.5vw,0.875rem)]",
        className,
      )}
    >
      {name}
    </p>
  );
}
