import FilePageContextMenu from "@/components/features/files/FilePageContextMenu";
import FilesPageError from "@/components/features/files/layouts/files-page/FilePageError";
import FilesStorageContentGrid from "@/components/features/files/layouts/files-page/FilesPageContentGrid";
import FilesPageEmpty from "@/components/features/files/layouts/files-page/FilesPageEmpty";
import FilesPageNoResults from "@/components/features/files/layouts/files-page/FilesPageNoResults";
import { FileUploadDropzone } from "@/components/ui/file-upload/FileUpload";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { DirectoryDirI, DirectoryFileI } from "@/types/files";
import { Row } from "@tanstack/react-table";
import { useCallback } from "react";

interface FilesStorageContentProps {
  files?: Row<DirectoryFileI>[];
  directories?: Row<DirectoryDirI>[];
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  dataLen: number;
}
export default function FilesPageContent({
  files = [],
  directories = [],
  dataLen,
  isLoading,
  isError,
  isSuccess,
}: FilesStorageContentProps) {
  const breakpoint = useMediaQueryHook("sm");
  const renderContent = useCallback(() => {
    const filesArray = files.map((file) => file.original);
    const directoriesArray = directories.map((directory) => directory.original);

    if (isError) {
      return <FilesPageError />;
    } else if (isSuccess && dataLen === 0) {
      return (
        <FilePageContextMenu disabled={isError || isLoading || breakpoint}>
          <FilesPageEmpty isError={isError} isLoading={isLoading} />
        </FilePageContextMenu>
      );
    } else if (isSuccess && files.length === 0 && directories.length === 0) {
      return (
        <FilePageContextMenu disabled={isError || isLoading || breakpoint}>
          <FilesPageNoResults />
        </FilePageContextMenu>
      );
    }

    return (
      <FilePageContextMenu disabled={isError || isLoading || breakpoint}>
        <FilesStorageContentGrid
          files={filesArray}
          directories={directoriesArray}
          isSuccess={isSuccess}
          isLoading={isLoading}
          isError={isError}
        />
      </FilePageContextMenu>
    );
  }, [files, directories, isError, isSuccess, dataLen, isLoading, breakpoint]);

  return (
    <FileUploadDropzone className="h-full rounded-md">
      {renderContent()}
    </FileUploadDropzone>
  );
}
