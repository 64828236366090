import { useInfiniteQueryResult } from "@/api/api-utils";
import {
  NotificationCard,
  NotificationCardSkeleton,
} from "@/components/features/notifications/NotificationCard";
import NotificationsEmpty from "@/components/features/notifications/NotificationsEmpty";
import { InfiniteScroll } from "@/components/ui/infinite-scroll/InfiniteScroll";
import { NotificationI } from "@/types/notifications";
import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";

type NotificationsListProps = UseInfiniteQueryResult<
  InfiniteData<NotificationI[], unknown>,
  Error
> & { makeNotificationsRead: (id: number[]) => void };

export default function NotificationsList(props: NotificationsListProps) {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isError,
    error,
    makeNotificationsRead,
  } = props;

  const { data: notifications, isEmpty } = useInfiniteQueryResult(data);

  const next = () => {
    if (fetchNextPage) {
      fetchNextPage();
    }
  };

  return (
    <>
      {isError ? (
        <span
          className={
            "w-full py-2 text-center text-sm italic text-fg-destructive"
          }
        >
          {error?.message}
        </span>
      ) : null}
      {isEmpty && !isLoading ? (
        <NotificationsEmpty className={"my-14"} />
      ) : null}
      {notifications?.map((notification) => (
        <NotificationCard
          key={notification.id}
          makeRead={(id) => makeNotificationsRead([id])}
          {...notification}
        />
      ))}

      {isLoading && (
        <>
          <NotificationCardSkeleton />
          <NotificationCardSkeleton />
          <NotificationCardSkeleton />
        </>
      )}

      {hasNextPage !== undefined ? (
        <InfiniteScroll
          hasNextPage={hasNextPage}
          isLoading={isFetching}
          isFetching={isFetching}
          next={next}
          threshold={0.65}
          root={null}
        >
          {hasNextPage && <NotificationCardSkeleton />}
        </InfiniteScroll>
      ) : null}
    </>
  );
}
