import { axiosInstance } from "@/api/api";
import { CalendarScheduleInstanceSchemaType } from "@/schemas/meeting.schema";
import { AttendanceI, MeetingI } from "@/types/meetings";

interface PostMeetingPropsT {
  name: string;
  desc?: string;
  type: "remote" | "stationary";
  startDate: string;
  endDate: string;
  locationId?: number;
  meetingURL?: string;
  userArr: number[];
  groupArr: number[];
}

interface PostMeetingSchedulePropsT {
  name: string;
  desc?: string;
  startDate: string;
  endDate: string;
  excludeHolidays: boolean;
  schedule: CalendarScheduleInstanceSchemaType[];
  userArr: number[];
  groupArr: number[];
}

interface memberChangesT {
  userIds?: number[];
  groupIds?: number[];
  add?: boolean;
  remove?: boolean;
}

interface EditMeetingPropsT {
  id: number;
  name?: string;
  desc?: string;
  type?: "remote" | "stationary";
  startDate?: string;
  endDate?: string;
  locationId?: number;
  meetingURL?: string;
  isCancelled?: boolean;
  memberChanges?: memberChangesT[];
}

interface GetMeetingsPropsT {
  id?: number;
  before?: string | null;
  after?: string | null;
  limit?: number;
  page?: number;
  pageSize?: number;
  sortOrder?: string;
}

export type {
  PostMeetingPropsT,
  PostMeetingSchedulePropsT,
  memberChangesT,
  EditMeetingPropsT,
  GetMeetingsPropsT,
};

export const getMeeting = async (id: number): Promise<MeetingI> => {
  return await axiosInstance.get("/api/meeting", {
    params: { id },
  });
};

export const getAssignedMeetings = async (
  params: GetMeetingsPropsT,
): Promise<MeetingI[]> => {
  return await axiosInstance.get("/api/meeting/getAssigned", {
    params: params,
  });
};

export const postMeetingSchedule = async (
  data: PostMeetingSchedulePropsT,
): Promise<MeetingI> => {
  return await axiosInstance.put("/api/meeting/schedule", data);
};

export const postMeeting = async (
  data: PostMeetingPropsT,
): Promise<MeetingI> => {
  return await axiosInstance.put("/api/meeting", data);
};

export const editMeeting = async (
  data: EditMeetingPropsT,
): Promise<MeetingI> => {
  return await axiosInstance.post("/api/meeting", data);
};

export const deleteMeeting = async (id: number): Promise<number> => {
  return await axiosInstance.delete("/api/meeting", {
    data: { id: id },
  });
};

export const putAttendance = async({id, attendanceData}:{
  id: number,
  attendanceData: Array<AttendanceI>
}): Promise<void> => {
  return await axiosInstance.put("/api/meeting/attendance", {
    meetingId: id,
    userIds: attendanceData
  })
}