import { httpErrorHandler } from "@/api/api";
import { usePostUserMutation as usePostUser } from "@/api/queries/usersQueries";
import { Button } from "@/components/ui/button/Button";
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form/Form";
import { Input } from "@/components/ui/input/Input";
import { DatetimePicker } from "@/components/ui/input/datetime-picker/DatetimePicker";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/input/select/Select";
import { Spinner } from "@/components/ui/spinner/Spinner";
import { useToast } from "@/components/ui/toast/useToast";
import { UserAddSchema, UserAddSchemaType } from "@/schemas/user.schema";
import { useCredentials } from "@/store/authStore";
import generatePassword from "@/utils/generatePassword";
import { yupResolver } from "@hookform/resolvers/yup";
import { Check, Copy, Download } from "lucide-react";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";

interface UserAddFormPropsT {
  onOpenChange: (bool: boolean) => void;
}

export default function CreateUser({ onOpenChange }: UserAddFormPropsT) {
  const [password, setPassword] = useState<string>(generatePassword(10));
  const [page, setPage] = useState<number>(0);
  const { toast } = useToast();
  const { lvl } = useCredentials();
  const [copiedPassword, setCopiedPassword] = useState<boolean>(false);

  const { mutateAsync, isPending } = usePostUser();

  const handleClose = () => {
    onOpenChange(false);
    form.reset();
    setPassword(generatePassword(10));
    setPage(0);
  };

  const copyContent = async () => {
    await navigator.clipboard.writeText(password);
    setCopiedPassword(true);
    setTimeout(() => {
      setCopiedPassword(false);
    }, 1000);
  };

  const form = useForm<UserAddSchemaType>({
    values: {
      name: "",
      surname: "",
      email: "",
      phone: "",
      birthdate: null,
      lvl: "0",
      password: password,
    },
    mode: "onBlur",
    resolver: yupResolver(UserAddSchema),
  });

  const onSubmit = (data: UserAddSchemaType) => {
    mutateAsync(data)
      .then(() => {
        setPage(1);
      })
      .catch((error) => {
        const { detail } = httpErrorHandler(error);
        toast({
          variant: "destructive",
          description: detail,
        });
      });
  };

  return (
    <DialogContent
      className={"max-w-[45ch]"}
      onCloseAutoFocus={handleClose}
      onEscapeKeyDown={handleClose}
    >
      {isPending && (
        <div
          className={
            "absolute inset-0 z-20 m-auto flex flex-wrap items-center justify-center gap-3 bg-bg-container"
          }
        >
          <Spinner size={"lg"} />
          <h5 className={"font-medium"}>Trwa tworzenie użytkownika...</h5>
        </div>
      )}
      <DialogHeader>
        <DialogTitle>Utwórz użytkownika</DialogTitle>
      </DialogHeader>
      <Form {...form}>
        <DialogBody className={"flex flex-col gap-4"}>
          {page === 0 ? (
            <form
              className={"flex flex-col gap-3"}
              onSubmit={form.handleSubmit(onSubmit)}
              noValidate
            >
              <FormField
                name={"name"}
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Imię</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder={"Imię"} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name={"surname"}
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nazwisko</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder={"Nazwisko"} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name={"email"}
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder={"Email"} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name={"lvl"}
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Poświadczenia</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={"Poświadczenia"} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value={"0"}>Uczeń</SelectItem>
                          <SelectItem value={"1"}>Nauczyciel</SelectItem>
                          <SelectItem disabled={lvl != 2} value={"2"}>
                            Administrator
                          </SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name={"phone"}
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Numer telefonu{" "}
                      <small className={"text-muted-foreground text-sm"}>
                        (opcionalne)
                      </small>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} placeholder={"Numer telefonu"} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name={"birthdate"}
                control={form.control}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>
                        Data urodzenia{" "}
                        <small className={"text-sm text-fg-muted"}>
                          (opcionalne)
                        </small>
                      </FormLabel>
                      <FormControl>
                        <DatetimePicker
                          aria-label={"date picker"}
                          value={field.value}
                          onChange={field.onChange}
                          maxValue={new Date()}
                          granularity={"day"}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </form>
          ) : (
            <>
              <div className={"flex gap-2"}>
                <Check className={"h-5 w-5 text-fg-primary"} />
                <h3 className={"font-semibold text-fg-primary"}>
                  Utworzenie użytkownika powiodło się!
                </h3>
              </div>
              <div className={"flex flex-col gap-2"}>
                <h5
                  className={
                    "border-b-1 border-border pb-2 text-sm text-fg-primary"
                  }
                >
                  Karta użytkownika
                </h5>
                <div className={"flex w-full flex-wrap justify-between gap-2"}>
                  <p className={"max-w-[30ch] text-xs text-fg-secondary"}>
                    Pobierz kartę użytkownika ( Karta zostanie automatycznie
                    wysłana użytkownikowi na wskazany adres email )
                  </p>
                  <Button
                    variant={"outline"}
                    variantColor={"muted"}
                    icon={<Download />}
                    iconPosition={"left"}
                  >
                    Pobierz
                  </Button>
                </div>
              </div>
              <div className={"flex flex-col gap-2"}>
                <h5
                  className={
                    "border-b-1 border-border pb-2 text-sm text-fg-primary"
                  }
                >
                  Hasło
                </h5>
                <div className={"flex w-full flex-wrap justify-between gap-2"}>
                  <p className={"max-w-[30ch] text-xs text-fg-secondary"}>
                    Hasło jest generowane i przydzielane do użytkownika
                    automatycznie.
                  </p>
                  <Button
                    onClick={copyContent}
                    variant={"outline"}
                    variantColor={"muted"}
                    icon={copiedPassword ? <Check /> : <Copy />}
                    iconPosition={"left"}
                  >
                    Kopiuj
                  </Button>
                </div>
              </div>
            </>
          )}
        </DialogBody>
        <DialogFooter>
          {page === 0 ? (
            <Fragment>
              <Button
                onClick={handleClose}
                type={"button"}
                variant={"flat"}
                variantColor={"muted"}
                className={"sm:w-full"}
              >
                Anuluj
              </Button>
              <Button
                onClick={form.handleSubmit(onSubmit)}
                type={"submit"}
                variant={"flat"}
                variantColor={"brand"}
                className={"sm:w-full"}
              >
                Utwórz
              </Button>
            </Fragment>
          ) : (
            <Button
              onClick={handleClose}
              type={"button"}
              variant={"flat"}
              variantColor={"muted"}
              className={"sm:w-full"}
            >
              Zamknij
            </Button>
          )}
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
