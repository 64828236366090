import { useGetGroupsQuery as useGetGroups } from "@/api/queries/groupsQueries";
import CreateGroup from "@/components/features/manage/manage-groups/CreateGroup";
import DeleteGroup from "@/components/features/manage/manage-groups/DeleteGroup";
import EditGroup from "@/components/features/manage/manage-groups/EditGroup";
import { TanstackTable } from "@/components/features/manage/tanstack-table/TanstackTable";
import { Button } from "@/components/ui/button/Button";
import { AlertDialog } from "@/components/ui/dialog/AlertDialog";
import { Dialog } from "@/components/ui/dialog/Dialog";
import { Checkbox } from "@/components/ui/input/checkbox/Checkbox";
import { GroupsI } from "@/types/groups";
import { selectedRowsI } from "@/types/table";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, Edit, Plus, Trash2 } from "lucide-react";
import { useMemo, useState } from "react";

export default function ManageGroups() {
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openGroupAdd, setOpenGroupAdd] = useState(false);

  const [selectedRows, setSelectedRows] = useState<selectedRowsI<GroupsI>[]>(
    [],
  );
  const [selectedGroup, setSelectedGroup] = useState<GroupsI | undefined>(
    undefined,
  );

  const { data = [], isPending } = useGetGroups();

  const getSelectedRowsFn = (rows: GroupsI[]) => {
    setSelectedRows(
      rows.map((row) => {
        return { data: row, status: "isIdle" };
      }),
    );
    setOpenDelete(true);
  };

  const triggerPopupEdit = (user: GroupsI) => {
    if (user) {
      setSelectedGroup(user);
      setOpenEdit(true);
    }
  };

  const triggerPopupAddGroup = () => {
    setOpenGroupAdd(true);
  };

  const addGroup = useMemo(() => {
    return [
      {
        id: 1,
        label: "Dodaj Grupę",
        icon: <Plus className="h-4 w-4" />,
        func: () => triggerPopupAddGroup(),
      },
    ];
  }, []);

  const columns: ColumnDef<GroupsI>[] = useMemo(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected()
                ? true
                : table.getIsSomePageRowsSelected()
                  ? "indeterminate"
                  : false
            }
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label={"Select row"}
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        meta: "Nazwa",
        id: "name",
        accessorKey: "name",
        header: ({ column }) => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              icon={<ArrowUpDown />}
              iconPosition={"right"}
              className={"w-fit"}
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              Nazwa
            </Button>
          );
        },
      },
      {
        meta: "Opis",
        id: "desc",
        accessorKey: "desc",
        header: () => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              className={"w-fit"}
            >
              Opis
            </Button>
          );
        },
      },
      {
        meta: "Tagi",
        id: "tags",
        accessorKey: "tags",
        header: () => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              className={"w-fit"}
            >
              Tagi
            </Button>
          );
        },
        cell: () => {
          return "TAGS";
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        id: "actions",
        enableHiding: false,
        enableSorting: false,
        cell: ({ row }) => {
          const rowOrigin = row.original;
          return (
            <div className={"flex w-full justify-end"}>
              <Button
                size={"sm"}
                variant={"ghost"}
                variantColor={"destructive"}
                iconPosition={"only"}
                icon={<Trash2 />}
                onClick={() => getSelectedRowsFn([rowOrigin])}
              />
              <Button
                size={"sm"}
                variant={"ghost"}
                variantColor={"muted"}
                iconPosition={"only"}
                icon={<Edit />}
                onClick={() => triggerPopupEdit(rowOrigin)}
              />
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Dialog open={openGroupAdd} onOpenChange={setOpenGroupAdd}>
        <CreateGroup onOpenChange={setOpenGroupAdd} />
      </Dialog>
      <EditGroup
        id={selectedGroup?.id}
        open={openEdit}
        onOpenChange={setOpenEdit}
        callback={() => {
          setSelectedGroup(undefined);
        }}
      />
      <AlertDialog open={openDelete} onOpenChange={setOpenDelete}>
        <DeleteGroup
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          open={openDelete}
          onOpenChange={setOpenDelete}
        />
      </AlertDialog>
      <div className={"default-page-col"}>
        <TanstackTable
          className={
            "viewport-height sticky top-4 flex flex-col gap-4 md:h-auto sm:relative"
          }
          label={"Grupy"}
          name={"groups"}
          isLoading={isPending}
          columns={columns}
          data={data}
          getSelectedRows={getSelectedRowsFn}
          filterBy={[]}
          addGroup={addGroup}
        />
      </div>
    </>
  );
}
