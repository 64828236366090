import NotificationPermissionAlert from "@/components/features/notifications/NotificationPermissionAlert";
import { Navigation } from "@/components/ui/navigation/Navigation";
import { Toaster } from "@/components/ui/toast/Toaster";
import { createContext, useContext, useRef } from "react";
import { Outlet } from "react-router";

interface MainLayoutPageContextT {
  ref: React.RefObject<HTMLDivElement> | null;
}

const MainLayoutPageContext = createContext<MainLayoutPageContextT>({
  ref: null,
});

export const useMainLayoutPageContext = () => {
  return useContext(MainLayoutPageContext);
};

export default function MainLayoutPage() {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <MainLayoutPageContext.Provider value={{ ref: ref }}>
      <NotificationPermissionAlert />
      <div
        className={"flex h-dvh overflow-hidden !overscroll-contain sm:flex-col"}
      >
        <Navigation />
        <div
          ref={ref}
          className={
            "relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
          }
        >
          <main className={"grow"}>
            <Outlet />
          </main>
        </div>
      </div>
      <Toaster />
    </MainLayoutPageContext.Provider>
  );
}
