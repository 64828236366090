import { httpErrorHandler } from "@/api/api";
import ArrowUpImg from "@/assets/images/arrow-up-img.svg?react";
import { InfiniteScroll } from "@/components/ui/infinite-scroll/InfiniteScroll";
import { Identifiable, UlElement } from "@/components/ui/list/List";
import { LiElementLSkeleton } from "@/components/ui/list/ListElement";
import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";
import { Fragment, ReactNode, useMemo } from "react";

interface SelectDataListProps<T extends Identifiable> {
  children: ReactNode | ReactNode[] | null;
  root?: Element | Document | null;
  query: UseInfiniteQueryResult<InfiniteData<T | any, unknown>, Error>;
  isEmpty: boolean;
  isNoResults: boolean;
  selected: T[];
  setSelected: (data: T[]) => void;
  emptyElement?: ReactNode;
  emptyMessage?: string;
  searchNoResultsMessage?: string;
  loadingElementPros?: {
    hasAvatar?: boolean;
    hasDetail?: boolean;
    hasTitle?: boolean;
  };
}

const DefaultEmptyListElement = ({ message }: { message?: string }) => {
  return (
    <div className={"flex flex-col items-center gap-4 py-11"}>
      <ArrowUpImg />
      <h5 className={"text-center font-medium text-fg-primary"}>
        {message ? message : "Wyszukaj"}
      </h5>
    </div>
  );
};

const SelectDataList = <T extends Identifiable>({
  children,
  root,
  query,
  isEmpty,
  isNoResults,
  selected,
  setSelected,
  emptyElement,
  emptyMessage,
  searchNoResultsMessage,
  loadingElementPros = {
    hasAvatar: true,
    hasDetail: true,
    hasTitle: true,
  },
}: SelectDataListProps<T>) => {
  const {
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    isFetching,
    fetchNextPage,
    error,
    status,
  } = query;

  const { title, detail } = httpErrorHandler(error);

  const next = async () => {
    await fetchNextPage();
  };

  const empty = useMemo(() => {
    const displayEmpty = isEmpty && !isFetchingNextPage && !isFetching;
    const displayNoResults = isNoResults && !isFetchingNextPage && !isFetching;

    if (displayNoResults && emptyElement) {
      return <DefaultEmptyListElement message={searchNoResultsMessage} />;
    } else if (displayNoResults) {
      return <DefaultEmptyListElement message={searchNoResultsMessage} />;
    } else if (displayEmpty && emptyElement) {
      return emptyElement;
    } else if (displayEmpty) {
      return <DefaultEmptyListElement message={emptyMessage} />;
    }
    return null;
  }, [isEmpty, isFetchingNextPage, isFetching, emptyElement]);

  if (status === "error") {
    return (
      <div className={"flex w-full flex-col gap-2 py-6"}>
        <p className="text-center font-medium text-fg-secondary">{title}</p>
        <p className="text-center text-xs text-fg-muted">{detail}</p>
      </div>
    );
  }

  return (
    <>
      <UlElement selected={selected} setSelected={setSelected}>
        {children}
      </UlElement>
      {empty}
      {isLoading && (
        <Fragment>
          <LiElementLSkeleton {...loadingElementPros} />
          <LiElementLSkeleton {...loadingElementPros} />
        </Fragment>
      )}

      <InfiniteScroll
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        isFetching={isFetching}
        next={next}
        threshold={0.65}
        root={root}
      >
        {hasNextPage ? <LiElementLSkeleton {...loadingElementPros} /> : null}
      </InfiniteScroll>
    </>
  );
};
export default SelectDataList;
