import { cn } from "@/lib/utils";
import { StyleProps } from "@react-types/shared";
import { CSSProperties, useEffect, useMemo, useRef, useState } from "react";

interface ResponsiveImageSources {
  desktop: {
    lowRes: { webp: string; jpeg: string };
    highRes: { webp: string; jpeg: string };
  };
  tablet: {
    lowRes: { webp: string; jpeg: string };
    highRes: { webp: string; jpeg: string };
  };
  mobile: {
    lowRes: { webp: string; jpeg: string };
    highRes: { webp: string; jpeg: string };
  };
}

interface ProgressiveImageProps {
  alt: string;
  className?: string;
  pictureClassName?: string;
  width?: number | string;
  height?: number | string;
  onLoad?: () => void;
  onError?: () => void;
  sources: ResponsiveImageSources;
}

function ProgressiveImageLoader(props: ProgressiveImageProps) {
  const {
    className,
    pictureClassName,
    sources,
    alt,
    width,
    height,
    onLoad,
    onError,
  } = props;
  const pictureRef = useRef<HTMLPictureElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const imageStyles = useMemo((): CSSProperties => {
    return {
      width: "100%",
      height: "100%",
      objectFit: "cover" as const,
      transition: "all 0.5s ease-in-out",
      filter: !isLoaded ? "blur(4px)" : "none",
      transform: !isLoaded ? "scale(1.025)" : "scale(1)",
    };
  }, [isLoaded]);

  useEffect(() => {
    const picture = pictureRef.current;
    if (picture) {
      const sourceElements = picture.getElementsByTagName("source");
      for (let i = 0; i < sourceElements.length; i++) {
        const source = sourceElements[i];
        const dataSrcset = source.getAttribute("data-srcset");
        if (dataSrcset) {
          source.setAttribute("srcset", dataSrcset);
        }
      }

      const img = picture.getElementsByTagName("img")[0];
      if (img) {
        const dataSrc = img.getAttribute("data-src");
        if (dataSrc) {
          const highResImage = new Image();
          highResImage.src = dataSrc;

          highResImage.onload = () => {
            img.src = dataSrc;
            onLoad?.();
            setTimeout(() => {
              setIsLoaded(true);
            }, 80);
          };

          highResImage.onerror = () => {
            onError?.();
          };
        }
      }
    }
  }, [onLoad, onError]);

  return (
    <div className={cn("relative overflow-hidden", className)}>
      <picture
        ref={pictureRef}
        className={cn("absolute inset-0 z-0", pictureClassName)}
      >
        {/* Desktop WebP */}
        <source
          media="(min-width: 1024px)"
          srcSet={`${sources.desktop.lowRes.webp}`}
          data-srcset={`${sources.desktop.highRes.webp} `}
          type="image/webp"
        />
        {/* Desktop JPEG fallback */}
        <source
          media="(min-width: 1024px)"
          srcSet={`${sources.desktop.lowRes.jpeg}`}
          data-srcset={`${sources.desktop.highRes.jpeg}`}
          type="image/jpeg"
        />

        {/* Tablet WebP */}
        <source
          media="(min-width: 768px)"
          srcSet={`${sources.tablet.lowRes.webp}`}
          data-srcset={`${sources.tablet.highRes.webp}`}
          type="image/webp"
        />
        {/* Tablet JPEG fallback */}
        <source
          media="(min-width: 768px)"
          srcSet={`${sources.tablet.lowRes.jpeg}`}
          data-srcset={`${sources.tablet.highRes.jpeg}`}
          type="image/jpeg"
        />

        {/* Mobile WebP */}
        <source
          media="(max-width: 767px)"
          srcSet={`${sources.mobile.lowRes.webp}`}
          data-srcset={`${sources.mobile.highRes.webp}`}
          type="image/webp"
        />
        {/* Mobile JPEG fallback */}
        <source
          media="(max-width: 767px)"
          srcSet={`${sources.mobile.lowRes.jpeg}`}
          data-srcset={`${sources.mobile.highRes.jpeg}`}
          type="image/jpeg"
        />

        {/* Fallback Image */}
        <img
          style={imageStyles}
          src={sources.mobile.lowRes.jpeg}
          data-src={sources.mobile.highRes.jpeg}
          alt={alt}
          width={width}
          height={height}
          onLoad={onLoad}
          onError={onError}
        />
      </picture>
    </div>
  );
}

export { ProgressiveImageLoader };
