import { httpErrorHandler } from "@/api/api";
import { usePutAttendanceMutation } from "@/api/queries/meetingsQuery";
import MeetingAttendanceToggles from "@/components/features/meeting/components/MeetingAttendanceToggles";
import UserCard from "@/components/features/user/UserCard";
import { Button } from "@/components/ui/button/Button";
import { Separator } from "@/components/ui/separator/Separator";
import { useToast } from "@/components/ui/toast/useToast";
import { cn } from "@/lib/utils";
import { AttendanceI } from "@/types/meetings";
import { BookmarkPlus } from "lucide-react";
import { useCallback, useState } from "react";

type MeetingAttendanceProps = {
  attendance: AttendanceI[];
  meetingId: number;
};

export default function MeetingAttendance(props: MeetingAttendanceProps) {
  const { attendance, meetingId } = props;
  const { toast } = useToast();

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [attendanceData, setAttendanceData] =
    useState<AttendanceI[]>(attendance);

  const setAllPresent = useCallback(() => {
    setAttendanceData((prev) => prev.map((a) => ({ ...a, status: "present" })));
    setHasChanges(true);
  }, []);
  const { isPending, mutateAsync } = usePutAttendanceMutation();

  const handleAttendanceChange = useCallback(
    (newStatus: string, userId: number) => {
      setAttendanceData((prevData) => {
        return prevData.map((a) =>
          a.userId == userId
            ? { ...a, status: newStatus as "present" | "absent" | "late" }
            : a,
        );
      });
      setHasChanges(true);
    },
    [],
  );
  const onCancel = useCallback(() => {
    setAttendanceData(attendance);
    setHasChanges(false);
  }, [attendance]);

  const onSubmit = () => {
    mutateAsync({
      id: meetingId,
      attendanceData: attendanceData,
    })
      .then(() => {
        setHasChanges(false);
      })
      .catch((error) => {
        const { title, detail } = httpErrorHandler(error);
        toast({
          title: title,
          description: detail,
          variant: "destructive",
        });
      });
  };

  return (
    <div className={"flex h-full w-full grow flex-col overflow-auto"}>
      <header className={"flex items-center justify-between gap-2 p-4"}>
        <div className={"flex h-10 items-center"}>
          <h3 className={"w-full truncate text-lg font-semibold"}>
            Goście spotkania
          </h3>
        </div>
        <Button
          onClick={setAllPresent}
          size={"sm"}
          variant={"outline"}
          variantColor={"muted"}
          iconPosition={"only"}
          icon={<BookmarkPlus />}
        />
      </header>
      <Separator />
      <div className={"h-full w-full p-4"}>
        {attendanceData.map((attendance) => {
          return (
            <div className={"w-full"} key={attendance.userId}>
              <div className={"inline-flex w-full"}>
                <UserCard user={attendance.user} />
                <div className={"m-auto mr-0"}>
                  <MeetingAttendanceToggles
                    status={attendance.status}
                    userId={attendance.userId}
                    onValueChange={handleAttendanceChange}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={"flex justify-end gap-2 px-4 pb-4"}>
        <Button
          onClick={onCancel}
          variant={"flat"}
          variantColor={"muted"}
          className={cn("w-full", !hasChanges && "invisible opacity-0")}
        >
          Anuluj
        </Button>
        <Button
          disabled={!hasChanges}
          isLoading={{ state: isPending }}
          onClick={onSubmit}
          variant={"flat"}
          className={cn("w-full", !hasChanges && "invisible opacity-0")}
        >
          Zapisz
        </Button>
      </div>
    </div>
  );
}
