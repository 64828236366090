import { Button } from "@/components/ui/button/Button";
import { cn } from "@/lib/utils";
import { LocationI } from "@/types/locations";
import {
  APIProvider,
  Map,
  MapCameraChangedEvent,
  MapCameraProps,
  Marker,
} from "@vis.gl/react-google-maps";
import { Compass } from "lucide-react";
import { useCallback, useMemo, useState } from "react";

import LocationLink from "./LocationLink";

interface LocationCardProps {
  location: LocationI;
  className?: string;
}

export default function LocationCard({
  location,
  className,
}: LocationCardProps) {
  const { lat, lng } = useMemo(() => {
    const [lat, Ing] = location.coordinates.split(",");
    return { lat: Number(lat) - 0.001, lng: Number(Ing) };
  }, [location]);

  const [cameraProps, setCameraProps] = useState<MapCameraProps>({
    zoom: 15,
    center: { lat, lng },
  });
  const handleCameraChange = useCallback(
    (ev: MapCameraChangedEvent) => setCameraProps(ev.detail),
    [],
  );

  const handleGoBackToPoint = () => {
    if (!location) {return;}
    setCameraProps({
      zoom: 15,
      center: { lat, lng },
    });
  };

  return (
    <div
      className={cn(
        "relative h-full w-full overflow-hidden rounded-lg",
        className,
      )}
    >
      <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY}>
        <Map
          defaultCenter={{ lat, lng }}
          defaultZoom={15}
          gestureHandling={"greedy"}
          disableDefaultUI={true}
          {...cameraProps}
          onCameraChanged={handleCameraChange}
        >
          <Marker position={{ lat, lng }} />
        </Map>
      </APIProvider>
      <Button
        onClick={handleGoBackToPoint}
        className={cn("absolute right-2 top-2", !location && "disabled")}
        variant={"ghost"}
        variantColor={"muted"}
        icon={<Compass />}
        iconPosition={"only"}
        size={"sm"}
      />
      <div className={"absolute bottom-0 z-10 w-full bg-bg-container"}>
        <div className={"flex justify-between gap-4 p-4"}>
          <div className={"flex flex-col justify-center gap-0.5"}>
            <h5 className={"truncate font-medium"}>{location.name}</h5>
            <p className={"truncate text-xs text-fg-muted"}>{location.desc}</p>
          </div>
          <LocationLink location={location} />
        </div>
      </div>
    </div>
  );
}
