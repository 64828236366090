import { useMemo } from "react";

export const useLvl = (lvl?: number) => {
  return useMemo(() => {
    if (lvl === 0) {return "Uczeń";}
    if (lvl === 1) {return "Nauczyciel";}
    if (lvl === 2) {return "Administrator";}
    return "";
  }, [lvl]);
};
