import { axiosInstance } from "@/api/api";
import { EditFileType } from "@/schemas/files.schema";
import { DirectoryFileI, FileI, GetPermissionsI } from "@/types/files";
import { AxiosProgressEvent } from "axios";

export interface FileUploadParams {
  directoryId: string;
  file: File;
  tempUUID?: string;
  progressCallBack: (progressEvent: AxiosProgressEvent) => void;
}

export const getFile = async (id?: string): Promise<FileI> => {
  return await axiosInstance.get("/api/fs/file", { params: { id } });
};

export const getFilePermissions = async (
  id?: string,
): Promise<GetPermissionsI> => {
  return await axiosInstance.get("/api/fs/file/getPermissions", {
    params: { id },
  });
};

export const uploadFile = async ({
  directoryId,
  file,
  abortController,
  progressCallBack,
}: FileUploadParams & {
  abortController: AbortController;
}): Promise<DirectoryFileI> => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("directoryId", directoryId);
  return await axiosInstance.put("/api/fs/file", formData, {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      progressCallBack?.(progressEvent);
    },
    signal: abortController?.signal,
  });
};

export const editFile = async (data: EditFileType): Promise<string> => {
  return await axiosInstance.post("/api/fs/file", data);
};

export const deleteFile = async (id: string): Promise<string> => {
  return await axiosInstance.delete("/api/fs/file", { data: { id } });
};
