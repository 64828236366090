import FSEntitiesMenu from "@/components/features/files/FSEntitiesMenu";
import FileCard from "@/components/features/files/FileCard";
import { Button } from "@/components/ui/button/Button";
import {
  ContextMenu,
  ContextMenuTrigger,
} from "@/components/ui/context-menu/ContextMenu";
import {
  DropdownMenu,
  DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import useLongPress from "@/hooks/useLongPress";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { useFilesStorage } from "@/store/filesStore";
import { DirectoryFileI } from "@/types/files";
import { MoreVertical } from "lucide-react";
import { type KeyboardEvent, useCallback, useRef, useState } from "react";

interface FileStorageFileElementProps {
  file: DirectoryFileI;
}
export default function File({ file }: FileStorageFileElementProps) {
  const fileRef = useRef<HTMLDivElement>(null);
  const breakpoint = useMediaQueryHook("sm");
  const [openDropdownMenu, setOpenDropdownMenu] = useState<boolean>(false);
  const [, setOpenContextMenu] = useState<boolean>(false);

  const {
    OnFSEntityClick,
    isSelected,
    selectedSize,
    setOpenViewer,
    setFileToOpen,
  } = useFilesStorage();
  const isSelectedFile = isSelected(file.id, "file");
  const size = selectedSize();

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter" && file.permissions.read) {
        setFileToOpen(file);
        setOpenViewer(true);
      }
    },
    [file, setFileToOpen, setOpenViewer],
  );

  const onDbClick = useCallback(() => {
    if (file.permissions.read) {
      setFileToOpen(file);
      setOpenViewer(true);
    }
  }, [file, setFileToOpen, setOpenViewer]);

  const onClick = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      const isTouch = event.nativeEvent.pointerType !== "mouse";
      if ((isTouch && size > 0) || !isTouch) {
        OnFSEntityClick({ file: file, event: event });
      } else {
        setFileToOpen(file);
        setOpenViewer(true);
      }
    },
    [size, OnFSEntityClick, file, setFileToOpen, setOpenViewer],
  );

  const onHold = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      OnFSEntityClick({ file: file, event: event });
    },
    [OnFSEntityClick, file],
  );

  const { onPointerDown, onPointerMove, onPointerUp } = useLongPress(onHold, {
    preventDefault: true,
  });

  const handleOpenDropdownMenu = useCallback(
    (bool: boolean) => {
      setOpenDropdownMenu(bool);
      OnFSEntityClick({ file: file });
    },
    [file],
  );
  const handleOpenContextMenu = useCallback(
    (bool: boolean) => {
      setOpenContextMenu(bool);
      OnFSEntityClick({ file: file });
    },

    [file],
  );

  return (
    <ContextMenu onOpenChange={handleOpenContextMenu}>
      <DropdownMenu
        onOpenChange={handleOpenDropdownMenu}
        open={openDropdownMenu}
      >
        <ContextMenuTrigger disabled={breakpoint} asChild>
          <FileCard
            aria-selected={isSelectedFile}
            tabIndex={0}
            ref={fileRef}
            onClick={onClick}
            onDoubleClick={onDbClick}
            onKeyDown={onKeyDown}
            onPointerDown={onPointerDown}
            onPointerMove={onPointerMove}
            onPointerUp={onPointerUp}
            file={file}
            menuButton={
              <DropdownMenuTrigger asChild>
                <Button
                  disabled={size > 1}
                  variant={"ghost"}
                  variantColor={"muted"}
                  icon={<MoreVertical />}
                  iconPosition={"only"}
                  size={"sm"}
                />
              </DropdownMenuTrigger>
            }
            className={cn(
              "bg-bg-container",
              isSelectedFile && "bg-bg-element-selected border-fg-brand",
            )}
          />
        </ContextMenuTrigger>
        <FSEntitiesMenu
          element={file}
          type={"file"}
          showContextMenu={!breakpoint}
          showDropdownMenu
        />
      </DropdownMenu>
    </ContextMenu>
  );
}
