import { httpErrorHandler } from "@/api/api";
import { MediaViewerFileT } from "@/components/features/media/MediaViewer";
import { Button } from "@/components/ui/button/Button";
import { DialogBody, DialogFooter } from "@/components/ui/dialog/Dialog";
import { Input } from "@/components/ui/input/Input";
import { Link } from "@/components/ui/link/Link";
import { Spinner } from "@/components/ui/spinner/Spinner";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import useElementData from "@/hooks/useElementData";
import { cn } from "@/lib/utils";
import getFileUrl from "@/utils/getFileUrl";
import { motion } from "framer-motion";
import {
  ChevronLeft,
  ChevronRight,
  Download,
  Minus,
  Plus,
  Scaling,
  X,
} from "lucide-react";
import { TextItem } from "pdfjs-dist/types/src/display/api";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

const MIN_SCALE = 0.25;
const MAX_SCALE = 4;
const MAX_WIDTH = 600;

type TextRendererT = {
  pageIndex: number;
  pageNumber: number;
  itemIndex: number;
} & TextItem;

enum ReducerActionT {
  SET_PAGE_NUMBER = "SET_PAGE_NUMBER",
  SET_AMOUNT_OF_PAGES = "SET_AMOUNT_OF_PAGES",
  SET_SCALE = "SET_SCALE",
  RESET_SCALE = "RESET_SCALE",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  IDLE = "IDLE",
}

interface StateT {
  scale: number;
  error?: Error;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  pageNumber: number;
  amountOfPages: number;
}

type ActionT =
  | { type: ReducerActionT.SET_AMOUNT_OF_PAGES; payload: number }
  | { type: ReducerActionT.SET_PAGE_NUMBER; payload: number }
  | { type: ReducerActionT.SET_SCALE; payload: number }
  | { type: ReducerActionT.RESET_SCALE }
  | { type: ReducerActionT.SUCCESS }
  | { type: ReducerActionT.LOADING }
  | { type: ReducerActionT.ERROR; payload: Error }
  | { type: ReducerActionT.IDLE };

const initialState: StateT = {
  scale: 1,
  error: undefined,
  isError: false,
  isSuccess: false,
  isLoading: false,
  pageNumber: 0,
  amountOfPages: 0,
};

function reducer(state: StateT = initialState, action: ActionT) {
  switch (action.type) {
    case ReducerActionT.SET_PAGE_NUMBER:
      return { ...state, pageNumber: action.payload };
    case ReducerActionT.SET_AMOUNT_OF_PAGES:
      return { ...state, amountOfPages: action.payload };
    case ReducerActionT.SET_SCALE: {
      const scaleStep = action.payload;
      const prevScale = state.scale;
      if (
        prevScale + scaleStep < MIN_SCALE ||
        prevScale + scaleStep > MAX_SCALE
      ) {
        return { ...state, scale: prevScale };
      }
      return { ...state, scale: prevScale + scaleStep };
    }
    case ReducerActionT.RESET_SCALE:
      return { ...state, scale: 1 };
    case ReducerActionT.LOADING:
      return { ...state, isLoading: true, isSuccess: false, isError: false };
    case ReducerActionT.SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        isError: false,
      };
    case ReducerActionT.ERROR:
      return {
        ...state,
        isError: true,
        isPending: false,
        isSuccess: false,
        error: action.payload,
      };
    case ReducerActionT.IDLE:
      return { ...state, isLoading: false, isSuccess: false, isError: false };
    default:
      return state;
  }
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

const options = { withCredentials: true };

export default function MediaPDFViewer({ file }: MediaViewerFileT) {
  const isFilesInstance = file instanceof File;

  const containerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [state, dispatch] = useReducer(reducer, initialState);
  const { scale, pageNumber, amountOfPages, error } = state;

  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const searchDebouncedText = useDebounceValue(searchText, 400);

  const [{ width: bodyWidth, height: bodyHeight }] = useElementData(bodyRef);

  const onLoadSuccess = ({ numPages }: { numPages: number }) => {
    dispatch({ type: ReducerActionT.SET_AMOUNT_OF_PAGES, payload: numPages });
    dispatch({ type: ReducerActionT.SUCCESS });
  };

  const onLoadError = (error: Error) => {
    dispatch({ type: ReducerActionT.ERROR, payload: error });
  };

  const highlightPattern = useCallback(
    (textItem: TextRendererT, pattern: string) => {
      const patternRegex = new RegExp(pattern, "gi");
      const { str } = textItem;

      return str.replace(patternRegex, (value) => {
        return `<mark data-tag="mark-tag">${value}</mark>`;
      });
    },
    [],
  );

  const textRenderer = useCallback(
    (textItem: TextRendererT) => {
      return highlightPattern(textItem, searchDebouncedText);
    },
    [highlightPattern, searchDebouncedText],
  );

  const onSearchTextChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [setSearchText],
  );

  const scaleUpDown = useCallback((scaleStep: number) => {
    dispatch({ type: ReducerActionT.SET_SCALE, payload: scaleStep });
  }, []);

  const restartScale = useCallback(() => {
    dispatch({ type: ReducerActionT.RESET_SCALE });
  }, []);

  const scrollToPage = (pageNum: number) => {
    if (pageNum < 1 || pageNum > amountOfPages) {
      return;
    }

    const pageElement = document.querySelector(
      `[data-page-number="${pageNum}"]`,
    );
    if (pageElement) {
      pageElement.scrollIntoView({ behavior: "instant", block: "start" });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === "f") {
        event.preventDefault();
        setSearchOpen(true);
        searchInputRef?.current?.focus();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchInputRef]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container || pageNumber == 0) {
      return;
    }
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const pageIndex = Array.from(container.childNodes).indexOf(
              entry.target,
            );
            if (pageIndex !== -1) {
              dispatch({
                type: ReducerActionT.SET_PAGE_NUMBER,
                payload: pageIndex + 1,
              });
            }
          }
        });
      },
      {
        root: bodyRef.current,
        rootMargin: "0px",
        threshold: 0.5, // 50% of the page must be visible
      },
    );

    container.childNodes.forEach((child) => {
      if (child instanceof HTMLElement) {
        observer.observe(child);
      }
    });
    return () => {
      observer.disconnect();
    };
  }, [containerRef, bodyRef, amountOfPages, !!pageNumber]);

  const fileURL = useMemo(() => {
    if (!isFilesInstance) {
      return getFileUrl(file.id);
    }
    return URL.createObjectURL(file);
  }, [file, isFilesInstance]);

  return (
    <>
      <Button
        size={"sm"}
        className={"absolute left-4 top-1/2 z-20 disabled:opacity-0 sm:hidden"}
        variant={"flat"}
        icon={<ChevronLeft />}
        iconPosition={"only"}
        variantColor={"muted"}
        onClick={() => scrollToPage(pageNumber - 1)}
        disabled={pageNumber <= 1}
      />
      <Button
        size={"sm"}
        className={"absolute right-4 top-1/2 z-20 disabled:opacity-0 sm:hidden"}
        variant={"flat"}
        icon={<ChevronRight />}
        iconPosition={"only"}
        variantColor={"muted"}
        onClick={() => scrollToPage(pageNumber + 1)}
        disabled={pageNumber >= amountOfPages}
      />

      <DialogBody ref={bodyRef}>
        <MediaVIewerFindWidget
          ref={searchInputRef}
          open={searchOpen}
          onOpenChange={setSearchOpen}
          searchText={searchText}
          setSearchText={onSearchTextChange}
        />
        <div className={"relative flex w-full justify-center"}>
          <Document
            className={"space-y-6"}
            inputRef={containerRef}
            options={options}
            file={fileURL}
            onLoadError={onLoadError}
            onLoadSuccess={onLoadSuccess}
            loading={<MediaPDFViewerDocumentLoader />}
            error={<MediaPDFViewerDocumentError error={error} />}
            noData={<MediaPDFViewerNoData />}
          >
            {Array.from(new Array(amountOfPages), (el, index) => {
              return (
                <Page
                  height={bodyHeight}
                  width={
                    bodyWidth
                      ? Math.min(bodyWidth - 16 * 2, MAX_WIDTH)
                      : MAX_WIDTH
                  }
                  scale={scale}
                  pageNumber={index + 1}
                  key={`page_${index + 1}`}
                  className={
                    "[&_mark]:rounded-[2px] [&_mark]:bg-bg-accent [&_mark]:text-sm [&_mark]:text-fg-accent-on"
                  }
                  loading={
                    <MediaPDFViewerPageLoader
                      height={bodyHeight}
                      width={
                        bodyWidth
                          ? Math.min(bodyWidth - 16 * 2, MAX_WIDTH)
                          : MAX_WIDTH
                      }
                    />
                  }
                  onRenderSuccess={() => {
                    if (index === 0) {
                      dispatch({
                        type: ReducerActionT.SET_PAGE_NUMBER,
                        payload: 1,
                      });
                    }
                  }}
                  error={<MediaPDFViewerPageError />}
                  customTextRenderer={textRenderer}
                />
              );
            })}
          </Document>
        </div>
      </DialogBody>
      <DialogFooter className={"justify-between"}>
        <div className={"flex items-center gap-3"}>
          <p
            className={cn(
              "h-fit min-w-12 max-w-13 text-center text-sm text-fg-secondary",
              amountOfPages === 0 || pageNumber === 0
                ? "opacity-10"
                : "opacity-100",
            )}
          >
            {pageNumber} / {amountOfPages}
          </p>
          <div className={"space-x-0.5"}>
            <Button
              disabled={scale >= MAX_SCALE}
              variant={"ghost"}
              variantColor={"muted"}
              icon={<Plus />}
              iconPosition={"only"}
              onClick={() => scaleUpDown(0.25)}
            />
            <Button
              disabled={scale === MIN_SCALE}
              variant={"ghost"}
              variantColor={"muted"}
              icon={<Minus />}
              iconPosition={"only"}
              onClick={() => scaleUpDown(-0.25)}
            />
            <Button
              disabled={scale === 1}
              className={
                "duration-350 transition-opacity enabled:opacity-100 disabled:opacity-0"
              }
              variant={"ghost"}
              variantColor={"muted"}
              icon={<Scaling />}
              iconPosition={"only"}
              onClick={restartScale}
            />
          </div>
        </div>
        <div className={"flex gap-2"}>
          <Link href={fileURL}>
            <Button
              variant={"ghost"}
              variantColor={"muted"}
              className={"rounded-sm"}
              icon={<Download />}
              iconPosition={"only"}
            />
          </Link>
        </div>
      </DialogFooter>
    </>
  );
}

const MediaPDFViewerDocumentLoader = memo(() => {
  return (
    <div className={"flex h-full flex-col items-center justify-center p-14"}>
      <Spinner size={"lg"} />
      <p className={"italic text-fg-muted"}>Trwa ładowanie pliku...</p>
    </div>
  );
});

MediaPDFViewerDocumentLoader.displayName = "MediaPDFViewerDocumentLoader";

interface MediaPDFViewerPageLoaderProps {
  height?: number;
  width?: number;
}

const MediaPDFViewerPageLoader = ({
  height,
  width,
}: MediaPDFViewerPageLoaderProps) => {
  return (
    <div
      style={{ height: `${height}px`, width: `${width}px` }}
      className={"flex w-full grow items-center justify-center"}
    >
      <Spinner size={"lg"} />
    </div>
  );
};

interface MediaPDFViewerDocumentErrorProps {
  error?: Error;
}

const MediaPDFViewerDocumentError = ({
  error,
}: MediaPDFViewerDocumentErrorProps) => {
  const { title, detail } = httpErrorHandler(error);
  return (
    <div className={"flex h-full items-center justify-center"}>
      <h5 className="text-center font-medium">{title}</h5>
      <p
        className={
          "max-w-[35ch] text-pretty text-center text-xs text-fg-secondary"
        }
      >
        {detail}
      </p>
    </div>
  );
};

interface MediaVIewerFindWidgetProps {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  searchText: string;
  setSearchText: (event: ChangeEvent<HTMLInputElement>) => void;
}

const MediaVIewerFindWidget = forwardRef<
  HTMLInputElement,
  MediaVIewerFindWidgetProps
>((props, inputRef) => {
  const { open, onOpenChange, searchText, setSearchText } = props;

  const ANIMATION_DURATION = 0.15;

  const onClose = useCallback(() => {
    onOpenChange(false);
    setTimeout(
      () =>
        setSearchText({
          target: { value: "" },
        } as ChangeEvent<HTMLInputElement>),
      ANIMATION_DURATION * 1000,
    );
  }, [onOpenChange, setSearchText]);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: open ? 1 : 0, scale: open ? 1 : 0 }}
      exit={{ opacity: 0, scale: 0 }}
      transition={{
        duration: ANIMATION_DURATION,
        type: "spring",
        stiffness: 225,
        damping: 15,
      }}
      className={
        "sticky left-auto right-0 top-6 z-10 flex h-fit w-fit items-center gap-2 rounded-xl border-1 bg-bg-container p-2 shadow-md"
      }
    >
      <Input
        className={"max-w-[25ch]"}
        ref={inputRef}
        type={"search"}
        id={"search"}
        placeholder={"Wyszukaj..."}
        value={searchText}
        onChange={setSearchText}
      />

      <div className={"shrink-0"}>
        <Button
          size={"sm"}
          variant={"ghost"}
          variantColor={"muted"}
          icon={<X />}
          iconPosition={"only"}
          onClick={onClose}
        />
      </div>
    </motion.div>
  );
});

MediaVIewerFindWidget.displayName = "MediaVIewerFindWidget";

const MediaPDFViewerNoData = memo(() => {
  return (
    <div className={"flex h-full items-center justify-center"}>
      <p className={"text-fg-secondary"}>Brak danych pliku.</p>
    </div>
  );
});

MediaPDFViewerNoData.displayName = "MediaPDFViewerNoData";

const MediaPDFViewerPageError = memo(() => {
  return (
    <div className={"flex h-full items-center justify-center"}>
      <p className={"text-fg-secondary"}>Nie udało się załadować strony.</p>
    </div>
  );
});

MediaPDFViewerPageError.displayName = "MediaPDFViewerPageError";
