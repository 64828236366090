import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";
import React, { forwardRef } from "react";

const hyperlinkVariants = cva(
  "relative flex gap-2 truncate cursor-pointer group items-center transition-all duration-400 ease-in-out hover:opacity-90 disabled:opacity-50 outline-none focus-visible:outline-none focus-visible:underline hover:underline disabled:cursor-not-allowed",
  {
    variants: {
      variantColor: {
        brand: "text-fg-brand",
        accent: "text-fg-accent",
        muted: "text-fg-primary",
      },
      size: {
        sm: "text-sm gap-1",
        md: "text-md",
        lg: "text-lg",
      },
    },
    defaultVariants: {
      variantColor: "brand",
      size: "md",
    },
  },
);

interface linkProps extends VariantProps<typeof hyperlinkVariants> {
  children: React.ReactNode;
  href: string;
  openInNewTab?: boolean;
  className?: string;
}

const Link = forwardRef<HTMLAnchorElement, linkProps>(
  (
    { children, className, variantColor, size, openInNewTab, ...props },
    ref,
  ) => {
    return (
      <a
        ref={ref}
        target={openInNewTab ? "_blank" : undefined}
        className={cn(hyperlinkVariants({ variantColor, size }), className)}
        {...props}
      >
        {children}
      </a>
    );
  },
);

Link.displayName = "Link";

export { Link };
