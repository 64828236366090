import {
  getNotifications,
  readNotifications,
  subscribeWebPush,
} from "@/api/endpoints/notifications";
import { queryClient } from "@/api/query-client";
import { NotificationI, ReadNotificationsI } from "@/types/notifications";
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
} from "@tanstack/react-query";
import { useEffect } from "react";

export const useGetNotificationsInfiniteQuery = () => {
  useEffect(() => {
    const eventSource = new EventSource(
      import.meta.env.VITE_API_DOMAIN + "/api/notification/sse",
      {
        withCredentials: true,
      },
    );
    eventSource.onmessage = (event: MessageEvent) => {
      try {
        const data: NotificationI[] = JSON.parse(event.data);
        const sseDataLen = data.length;
        if (sseDataLen === 0) {
          return;
        }

        queryClient.invalidateQueries({
          queryKey: ["notificationsInfinite"],
        });
      } catch {
        console.error("Failed to parse event data");
      }
    };

    return () => eventSource.close();
  }, []);

  return useInfiniteQuery({
    queryKey: ["notificationsInfinite"],
    queryFn: ({ pageParam }) => getNotifications(pageParam),
    initialPageParam: { page: 0, pageSize: 10 },
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length < lastPageParam.pageSize) {
        return undefined;
      }
      return { ...lastPageParam, page: lastPageParam.page + 1 };
    },
  });
};

export const usePostReadNotificationsQuery = () => {
  return useMutation({
    mutationKey: ["postReadNotifications"],
    mutationFn: (data: ReadNotificationsI) => readNotifications(data),
    onMutate: async (data) => {
      await queryClient.cancelQueries({
        queryKey: ["notificationsInfinite"],
      });

      // const previousData = queryClient.getQueryData<NotificationI[]>([
      //   "notificationsInfinite",
      // ]);
      queryClient.setQueryData(
        ["notificationsInfinite"],
        (curr: InfiniteData<NotificationI[], unknown> | undefined) => {
          if (!curr) {
            return;
          }
          return {
            ...curr,
            pages: curr.pages.map((page) => {
              return page.map((n) => {
                if (data.arr.includes(n.id)) {
                  return { ...n, read: true };
                }
                return n;
              });
            }),
          };
        },
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["notificationsInfinite"],
      });
    },
  });
};

export const usePostSubscribeWebPushQuery = () => {
  return useMutation({
    mutationKey: ["postSubscribeWebPush"],
    mutationFn: (data: any) => subscribeWebPush(data),
  });
};
