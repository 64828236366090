import { CredentialsI } from "@/types/credentials";
import {
  ZustandHookSelectors,
  createSelectorHooks,
} from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface Action {
  setCredentials: (payload: CredentialsI) => void;
  removeCredentials: () => void;
  isLogged: () => boolean;
}

interface State {
  credentials: CredentialsI;
}

const defaultCredentials: CredentialsI = {
  id: -1,
  name: "",
  surname: "",
  email: "",
  lvl: 0,
  directoryId: "",
  phone: undefined,
  birthdate: undefined,
  avatarURL: undefined,
};

const useAuthStoreBase = create<Action & State>()(
  persist(
    (set, get) => ({
      credentials: defaultCredentials,
      isLogged: () => {
        const credentials = get().credentials;
        return !!(credentials && credentials.id !== -1);
      },
      setCredentials: (payload: CredentialsI) => set({ credentials: payload }),
      removeCredentials: () => {
        set({ credentials: defaultCredentials });
        useAuthStore.persist.clearStorage();
      },
    }),
    {
      name: "credentials",
    },
  ),
);

const useAuthStore = createSelectorHooks(
  useAuthStoreBase,
) as typeof useAuthStoreBase & ZustandHookSelectors<Action & State>;

export const {
  useCredentials,
  useIsLogged,
  useSetCredentials,
  useRemoveCredentials,
} = useAuthStore;
export { useAuthStore, useAuthStoreBase, defaultCredentials };
