import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar/Avatar";
import { GroupsI } from "@/types/groups";
import { Users } from "lucide-react";
import { Link } from "react-router-dom";

export default function GroupCardSmall({ group }: { group: GroupsI }) {
  const { id, name, users } = group;

  return (
    <Link
      className={
        "group flex h-fit items-center justify-between gap-3 rounded-sm py-2"
      }
      to={`/group/${id}`}
    >
      <div className={"flex items-center gap-3"}>
        <Avatar size={"sm"}>
          <AvatarImage alt={"avatar"} />
          <AvatarFallback>
            <Users className={"h-4 w-4"} />
          </AvatarFallback>
        </Avatar>
        <div className={"flex flex-col gap-1"}>
          <h5 className={"w-full truncate text-md text-fg-primary"}>{name}</h5>
          <small className={"text-xs text-fg-muted"}>
            Użytkowników: {users.length}
          </small>
        </div>
      </div>
    </Link>
  );
}
