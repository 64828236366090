import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { ArrowRight } from "lucide-react";

export default function MeetingArrowTime({
  startDate,
  endDate,
  className,
}: {
  startDate: string;
  endDate: string;
  className?: string;
}) {
  return (
    <p
      className={cn(
        "flex h-fit flex-row items-center gap-0.5 truncate text-[clamp(0.6rem,1.5vw,0.875rem)] text-fg-muted",
        className,
      )}
    >
      {format(new Date(startDate), "H:mm")}
      <ArrowRight className={"h-3 w-3"} />
      {format(new Date(endDate), "H:mm")}
    </p>
  );
}
