import { cn } from "@/lib/utils";
import { Time } from "@vidstack/react";
import { Video } from "lucide-react";

interface VideoIndicatorProps {
  variant?: "default" | "minimal";
}

export function VideoIndicator({ variant }: VideoIndicatorProps) {
  return (
    <div
      className={cn(
        "absolute flex items-center gap-1 rounded-md bg-bg-container/50 px-2 py-0.5 text-fg-primary media-buffering:text-fg-muted",
        variant === "minimal"
          ? "bottom-2 left-2/4 -translate-x-2/4"
          : "left-2 top-2",
      )}
    >
      <Video className={"h-4 w-4"} />
      <Time type={"duration"} className={"time text-xs font-medium"} />
    </div>
  );
}
