import { Badge } from "@/components/ui/badge/Badge";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { cn } from "@/lib/utils";
import { MeetingI, MeetingsI } from "@/types/meetings";
import { format, parseISO } from "date-fns";
import { pl } from "date-fns/locale";
import { Building, Clock, Link2 } from "lucide-react";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { Link as RRLink, useNavigate } from "react-router-dom";

const MeetingCard = forwardRef<
  HTMLButtonElement,
  { meeting: MeetingI | MeetingsI }
>(({ meeting, ...props }, ref) => {
  const { id, name, startDate, endDate, meetingURL, isCancelled } = meeting;

  const navigate = useNavigate();

  const [isCurrent, setIsCurrent] = useState<boolean>(false);

  const date = useMemo(
    () => format(parseISO(startDate), "d", { locale: pl }),
    [startDate],
  );
  const month = useMemo(
    () => format(parseISO(startDate), "MMM", { locale: pl }),
    [startDate],
  );

  const isOnline = useMemo(
    () => meetingURL && meetingURL.length > 0,
    [meetingURL],
  );

  const hasMeetingLocation = useCallback(
    (meeting: MeetingI | MeetingsI): meeting is MeetingI => {
      return "location" in meeting && meeting.location !== null;
    },
    [meeting],
  );

  const currentMeetingCb = useCallback(() => {
    const now = new Date();
    if (
      now >= parseISO(startDate) &&
      now <= parseISO(endDate) &&
      !isCancelled
    ) {
      setIsCurrent(true);
    } else {
      setIsCurrent(false);
    }
  }, [endDate, startDate, isCancelled]);

  useEffect(() => {
    currentMeetingCb();
    const interval = setInterval(currentMeetingCb, 10000);

    return () => clearInterval(interval);
  }, [currentMeetingCb]);
  const content = useMemo(() => {
    return (
      <div className={"flex h-full flex-col justify-between"}>
        <div className={"flex gap-1"}>
          <span
            className={cn(
              "my-0.5 w-1 min-w-1 rounded-sm",
              isCancelled
                ? "bg-fg-destructive"
                : isOnline
                  ? "bg-bg-brand"
                  : "bg-bg-accent",
            )}
          />
          <p
            className={
              "truncate text-start text-md font-medium text-fg-secondary"
            }
          >
            {name}
          </p>
        </div>
        <div className={"flex flex-nowrap gap-1 overflow-hidden"}>
          <Badge
            contentLeft={<Clock />}
            variant={"muted"}
            className={cn(isCancelled && "line-through")}
          >
            {format(parseISO(startDate), "H:mm") +
              " - " +
              format(parseISO(endDate), "H:mm")}
          </Badge>
          {hasMeetingLocation(meeting) ? (
            <Badge
              contentLeft={<Building />}
              variant={"muted"}
              className={cn(isCancelled && "line-through")}
            >
              {meeting.location.name}
            </Badge>
          ) : null}
          {meetingURL ? (
            <Badge
              contentLeft={<Link2 />}
              variant={"brand"}
              className={cn(isCancelled && "line-through")}
              onClick={() => navigate(meetingURL)}
            >
              Online
            </Badge>
          ) : null}
        </div>
      </div>
    );
  }, [
    endDate,
    isCancelled,
    isOnline,
    location,
    meetingURL,
    name,
    navigate,
    startDate,
  ]);

  return (
    <RRLink
      to={`/calendar/meeting/${id}`}
      className={cn("w-full", isCancelled && "opacity-60")}
    >
      <button
        ref={ref}
        role={"button"}
        aria-label={"meeting-card"}
        data-state={!isCancelled && isCurrent}
        className={cn(
          "relative flex w-full gap-2 rounded-md border-1 border-border bg-bg-container p-2 opacity-100 transition-all duration-100 ease-out hover:bg-bg-element/20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ring data-[state=true]:border-fg-accent",
        )}
        {...props}
      >
        <span
          className={cn(
            "absolute right-2 top-2 flex h-3 w-3 items-center justify-center",
            !isCurrent && "hidden",
          )}
        >
          <span className="h-3 min-h-3 w-3 min-w-3 animate-ping rounded-full bg-bg-accent opacity-60 duration-200" />
          <span className="absolute h-3 w-3 rounded-full bg-bg-accent" />
        </span>

        <div
          className={cn(
            "flex h-11 min-h-11 w-11 min-w-11 flex-col items-center justify-between rounded-sm p-0.5",
            isCurrent ? "bg-bg-accent-subtle" : "bg-bg-element",
          )}
        >
          <p
            className={cn(
              "text-xs",
              isCurrent ? "text-fg-accent" : "text-fg-muted",
            )}
          >
            {month}
          </p>
          <div
            className={
              "flex w-full justify-center rounded-[6px] bg-bg-container p-0.5"
            }
          >
            <p className={"text-sm font-medium text-fg-secondary"}>{date}</p>
          </div>
        </div>
        {content}
      </button>
    </RRLink>
  );
});
MeetingCard.displayName = "MeetingCard";

interface MeetingCardSkeletonProps {
  className?: string;
}

const MeetingCardSkeleton = forwardRef<
  HTMLDivElement,
  MeetingCardSkeletonProps
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      className={cn(
        "flex gap-1 rounded-md border-1 border-border bg-bg-container p-2",
        className,
      )}
    >
      <Skeleton className={"h-11 min-h-11 w-11 min-w-11 rounded-sm"} />
      <div className={cn("bg-bg-border h-full w-1 min-w-1 rounded-xs")} />
      <div className={"flex flex-col justify-center gap-1"}>
        <Skeleton className={"h-3 w-14 rounded-sm"} />
        <Skeleton className={"h-3 w-12 rounded-sm"} />
      </div>
    </div>
  );
});

MeetingCardSkeleton.displayName = "MeetingCardSkeleton";

export { MeetingCard, MeetingCardSkeleton };
