import { MediaViewerFileT } from "@/components/features/media/MediaViewer";
import { Button } from "@/components/ui/button/Button";
import { DialogBody, DialogFooter } from "@/components/ui/dialog/Dialog";
import { Link } from "@/components/ui/link/Link";
import VideoPlayer from "@/components/ui/media-viewer/VideoPlayer";
import getFileUrl from "@/utils/getFileUrl";
import { VideoMimeType } from "@vidstack/react";
import { Download } from "lucide-react";
import { useMemo } from "react";

export default function MediaVideoViewer({ file }: MediaViewerFileT) {
  const isFilesInstance = "id" in file;

  const { fileURL, type } = useMemo(() => {
    if (file && "id" in file) {
      return { fileURL: getFileUrl(file.id), type: file.fileType };
    } else if (file instanceof File) {
      return { fileURL: URL.createObjectURL(file), type: file.type };
    }
    return { fileURL: "", type: "" };
  }, [file]);

  return (
    <>
      <DialogBody>
        <VideoPlayer
          src={{
            src: fileURL,
            type: type as VideoMimeType,
          }}
          title={file.name}
          className={"h-full"}
        />
      </DialogBody>
      <DialogFooter>
        {isFilesInstance ? (
          <Link href={fileURL}>
            <Button
              variant={"ghost"}
              variantColor={"muted"}
              className={"rounded-sm"}
              icon={<Download />}
              iconPosition={"only"}
            />
          </Link>
        ) : null}
      </DialogFooter>
    </>
  );
}
