import { Button } from "@/components/ui/button/Button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
} from "@/components/ui/drawer/Drawer";
import { cn } from "@/lib/utils";
import { DirectoryElementsI } from "@/types/files";
import getFileUrl from "@/utils/getFileUrl";
import { DialogTitle } from "@radix-ui/react-dialog";
import { motion } from "framer-motion";
import { ChevronDown, X } from "lucide-react";
import { useEffect, useState } from "react";

interface DownloadFSEntryProps {
  elements: DirectoryElementsI;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  submitCallback?: () => void;
}

export default function DownloadFSEntry({
  elements,
  open,
  onOpenChange,
  submitCallback,
}: DownloadFSEntryProps) {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openCollapsible, setOpenCollapsible] = useState<boolean>(false);

  const handleCollapsibleToggle = () => {
    setOpenCollapsible((prev) => !prev);
  };

  const handleDownload = () => {
    const { files, directories } = { ...elements };
    onOpenChange(false);
    submitCallback?.();

    if (files.length === 1 && directories.length === 0) {
      const url = getFileUrl(files[0].id);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", files[0].name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      alert("Multiple files download not supported yet");
    }
  };

  useEffect(() => {
    if (open) {
      handleDownload();
    }
  }, [open]);

  return (
    <Drawer open={openDrawer} onOpenChange={setOpenDrawer} modal={false}>
      <DrawerContent
        className={
          "left-auto right-6 z-20 max-h-[365px] w-[23rem] sm:left-0 sm:right-0 sm:w-full"
        }
        overlayClassName={"hidden"}
        hideDragger
        onInteractOutside={(event) => event.preventDefault()}
        onPointerDownOutside={(event) => event.preventDefault()}
      >
        <header className={"flex w-full justify-end p-2"}>
          <DialogTitle className="sr-only">
            File and Directories compress
          </DialogTitle>
          <Button
            size={"sm"}
            variant={"ghost"}
            variantColor={"muted"}
            iconPosition={"only"}
            icon={
              <ChevronDown
                className={cn(!openCollapsible && "rotate-[180deg]")}
              />
            }
            onClick={handleCollapsibleToggle}
          />
          <DrawerClose asChild>
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              iconPosition={"only"}
              icon={<X />}
            />
          </DrawerClose>
        </header>
        <motion.div
          className={"h-full overflow-auto"}
          initial={{ opacity: 0, height: "auto" }}
          animate={{
            opacity: openCollapsible ? 1 : 0,
            height: openCollapsible ? "auto" : 0,
          }}
          transition={{
            ease: "easeInOut",
            stiffness: 260,
            damping: 20,
            duration: 0.2,
          }}
        >
          <div className={"flex h-full flex-col gap-2 p-4"}></div>
        </motion.div>
      </DrawerContent>
    </Drawer>
  );
}
