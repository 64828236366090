import { Spinner } from "@/components/ui/spinner/Spinner";

export function BufferingIndicator() {
  return (
    <div
      className={
        "pointer-events-none absolute inset-0 z-20 flex h-full w-full items-center justify-center opacity-0 media-buffering:opacity-100"
      }
    >
      <Spinner
        className={"media-buffering:spinner-ease-spin"}
        variant={"muted"}
        size={"lg"}
      />
    </div>
  );
}
