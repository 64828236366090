import { useMemo } from "react";

export default function CalendarContentHours() {
  const content = useMemo(
    () => Array.from({ length: 23 }, (_, index) => index + 1),
    [],
  );

  return (
    <div className={"flex w-10 flex-col"}>
      <div className={"relative h-full"} />
      {content.map((hour) => {
        return (
          <div className={"relative h-full"} key={hour}>
            <p
              aria-label={`hour-${hour}`}
              className={"absolute -top-2 text-xs text-fg-muted"}
            >
              {hour}:00
            </p>
          </div>
        );
      })}
    </div>
  );
}
