import MeetingArrowTime from "@/components/features/meeting/components/MeetingArrowTime";
import { MeetingLink } from "@/components/features/meeting/components/MeetingLink";
import MeetingLocation from "@/components/features/meeting/components/MeetingLocation";
import MeetingName from "@/components/features/meeting/components/MeetingName";
import MeetingStartTime from "@/components/features/meeting/components/MeetingStartTime";
import useElementData from "@/hooks/useElementData";
import { cn } from "@/lib/utils";
import { MeetingI, MeetingsI } from "@/types/meetings";
import { timeToHeight } from "@/utils/timeToHeight";
import { parseISO } from "date-fns";
import {
  Ref,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";

interface MeetingTriggerContentProps {
  meeting: MeetingI | MeetingsI;
  options?: { containerHeight: number; style: { size: number; left: number } };
  className?: string;
}

const MeetingCalendarCard = forwardRef<
  Ref<HTMLDivElement>,
  MeetingTriggerContentProps
>(({ meeting, options, className, ...props }, ref) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const [{ width, height }] = useElementData(triggerRef);
  useImperativeHandle(ref, () => ({
    focus: () => {
      triggerRef.current?.focus();
    },
    current: triggerRef.current,
  }));

  const { name, startDate, endDate, type, meetingURL, isCancelled } = meeting;
  let styles = {};

  const hasMeetingLocation = useCallback(
    (meeting: MeetingI | MeetingsI): meeting is MeetingI => {
      return "location" in meeting && meeting.location !== null;
    },
    [meeting],
  );

  if (options) {
    const startHeight = timeToHeight(
      parseISO(startDate),
      options.containerHeight,
    );
    const endHeight = timeToHeight(parseISO(endDate), options.containerHeight);
    const height = endHeight - startHeight;
    styles = {
      position: "absolute",
      top: `${startHeight}px`,
      left: `${options.style.left}%`,
      height: `${height}px`,
      width: `${options.style.size}%`,
    };
  }

  let content;

  if (width <= 140) {
    content = <MeetingName name={name} />;
  } else if (height <= 48 && width < 200) {
    content = (
      <>
        <MeetingName name={name} />
        <MeetingStartTime startDate={startDate} />
      </>
    );
  } else if (height <= 48 && width > 200) {
    content = (
      <>
        <MeetingName name={name} />
        <MeetingArrowTime startDate={startDate} endDate={endDate} />
      </>
    );
  } else if (height < 96) {
    content = (
      <div className={"flex flex-col"}>
        <MeetingName name={name} />
        <MeetingArrowTime startDate={startDate} endDate={endDate} />
      </div>
    );
  } else if (height >= 96) {
    content = (
      <div className={"flex flex-col"}>
        <MeetingName name={name} />
        <MeetingArrowTime startDate={startDate} endDate={endDate} />
        {hasMeetingLocation(meeting) && (
          <MeetingLocation locationName={meeting.location.name} />
        )}
        {meetingURL && <MeetingLink meetingURL={meetingURL} />}
      </div>
    );
  }

  return (
    <div
      ref={triggerRef}
      role={"button"}
      className={cn(
        "flex shrink-0 cursor-pointer flex-row gap-2 overflow-hidden rounded-sm border-1 border-bg-container bg-bg-element p-1",
        isCancelled &&
          "bg-bg-destructive-subtle text-fg-destructive opacity-70",
        className,
      )}
      {...props}
      style={styles}
    >
      <div
        className={cn(
          "h-full w-1 min-w-1 rounded-xs",
          type === "remote" ? "bg-bg-brand" : "bg-bg-accent",
          isCancelled && "bg-bg-destructive",
        )}
      />
      {content}
    </div>
  );
});

MeetingCalendarCard.displayName = "MeetingCalendarCard";

export default MeetingCalendarCard;
