import { useMeetingListContext } from "@/components/features/calendar/MeetingsList";
import {
  MeetingCard,
  MeetingCardSkeleton,
} from "@/components/features/meeting/MeetingCard";
import { InfiniteScroll } from "@/components/ui/infinite-scroll/InfiniteScroll";
import { cn } from "@/lib/utils";
import { useMemo } from "react";

interface MeetingsListContentProps {
  className?: string;
}

export default function MeetingsListContent(props: MeetingsListContentProps) {
  const { className } = props;

  const {
    meetings,
    fetchNextPage,
    isLoading,
    isSuccess,
    isFetching,
    hasNextPage,
  } = useMeetingListContext();

  const meetingsIsEmpty = useMemo(
    () => meetings.length === 0 && !isFetching,
    [meetings, isFetching],
  );

  const next = () => {
    if (fetchNextPage) {
      fetchNextPage();
    }
  };

  return (
    <div
      className={cn(
        "flex grow flex-col gap-2 overflow-hidden overflow-y-auto overscroll-contain px-4 py-1",
        className,
      )}
    >
      {meetings.map((meeting) => (
        <MeetingCard key={meeting.id} meeting={meeting} />
      ))}

      {isLoading && (
        <>
          <MeetingCardSkeleton />
          <MeetingCardSkeleton />
        </>
      )}
      {hasNextPage !== undefined ? (
        <InfiniteScroll
          hasNextPage={hasNextPage}
          isLoading={isFetching}
          isFetching={isFetching}
          next={next}
          threshold={0.65}
          root={null}
        >
          {hasNextPage && <MeetingCardSkeleton />}
        </InfiniteScroll>
      ) : null}

      {isSuccess && meetingsIsEmpty ? (
        <p className={"py-4 text-center text-sm text-fg-muted"}>Brak spotkań</p>
      ) : null}
    </div>
  );
}
