import { httpErrorHandler } from "@/api/api";
import { useGetAssignedMeetingsQuery } from "@/api/queries/meetingsQuery";
import CalendarContentHours from "@/components/features/calendar/layout/content/CalendarContentHours";
import CalendarContentRowLines from "@/components/features/calendar/layout/content/CalendarContentRowLines";
import CalendarContentTimeLine from "@/components/features/calendar/layout/content/CalendarContentTimeLine";
import { useMapMeetingsByDate } from "@/components/features/calendar/layout/content/map-meetings-by-date";
import Meeting from "@/components/features/meeting/Meeting";
import MeetingCalendarCard from "@/components/features/meeting/MeetingCalendarCard";
import { ToastAction } from "@/components/ui/toast/Toast";
import { useToast } from "@/components/ui/toast/useToast";
import { MeetingsI } from "@/types/meetings";
import { getWeek } from "@/utils/dateFormat";
import { eventOverlap } from "@/utils/eventOverlap";
import {
  endOfDay,
  endOfWeek,
  isToday,
  startOfDay,
  startOfWeek,
} from "date-fns";
import { useEffect, useMemo } from "react";

interface CalendarContentWeekProps {
  date: Date;
  containerHeight: number;
}

export default function CalendarContentWeek({
  date,

  containerHeight,
}: CalendarContentWeekProps) {
  const week = getWeek(date);
  const { toast } = useToast();

  const queryParams = useMemo(
    () => ({
      after: startOfWeek(startOfDay(date), { weekStartsOn: 1 }).toISOString(),
      before: endOfWeek(endOfDay(date), { weekStartsOn: 1 }).toISOString(),
    }),
    [date],
  );

  const {
    data = [],
    isError,
    error,
    refetch,
  } = useGetAssignedMeetingsQuery(queryParams);

  const meetings = useMapMeetingsByDate(week, data);

  useEffect(() => {
    if (isError) {
      const { title, detail } = httpErrorHandler(error);
      toast({
        variant: "destructive",
        title: title,
        description: detail,
        action: (
          <ToastAction altText="Try again" onClick={() => refetch()}>
            Ponów
          </ToastAction>
        ),
      });
    }
  }, [error, isError, refetch, toast]);

  return (
    <div
      className={"relative flex w-full flex-row"}
      style={{ height: `${containerHeight}px` }}
    >
      <CalendarContentHours />
      <CalendarContentRowLines
        space={containerHeight / 24}
        className={"absolute h-full w-full pl-10"}
      />
      <div className={"relative flex w-full"}>
        {week.map((weekDayDate) => (
          <div
            key={weekDayDate.toISOString()}
            className={
              "relative w-full border-r-1 border-border px-1 first-of-type:border-l-1"
            }
          >
            <CalendarContentTimeLine
              hide={!isToday(weekDayDate)}
              containerHeight={containerHeight}
            />
            <CalendarContentDataWeek
              meetings={meetings[weekDayDate.toISOString()]}
              queryParams={queryParams}
              containerHeight={containerHeight}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

interface CalendarContentDataWeekProps {
  meetings: MeetingsI[];
  queryParams: { before: string; after: string };
  containerHeight: number;
}

function CalendarContentDataWeek({
  meetings,
  queryParams,
  containerHeight,
}: CalendarContentDataWeekProps) {
  const styles = useMemo(() => eventOverlap(meetings), [meetings]);

  return meetings.map((meeting) => (
    <Meeting key={meeting.id} queryParams={queryParams} meeting={meeting}>
      <MeetingCalendarCard
        meeting={meeting}
        options={{
          containerHeight: containerHeight,
          style: styles[meeting.id],
        }}
      />
    </Meeting>
  ));
}
