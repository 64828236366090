import FsFilters from "@/components/features/files/FsFilters";
import FsManageBar from "@/components/features/files/FsManageBar";
import FSPath from "@/components/features/files/FsPath";
import { Button } from "@/components/ui/button/Button";
import { FileUploadTrigger } from "@/components/ui/file-upload/FileUpload";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs/Tabs";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import { useFilesStorage } from "@/store/filesStore";
import { DirectoryI } from "@/types/files";
import { Table } from "@tanstack/react-table";
import { motion } from "framer-motion";
import {
  ArrowLeft,
  FileUp,
  FolderPlus,
  FolderUp,
  HardDrive,
  ListFilter,
  Users,
  X,
} from "lucide-react";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";

interface StorageHeaderNavigationProps<T> {
  fsEntry?: DirectoryI;
  dataModel: Table<T>;
  setGlobalFilter: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
  isError: boolean;
  showTabsList: boolean;
}

export default function FilesPageHeader<ModelType>({
  fsEntry,
  isError,
  isLoading,
  showTabsList,
  dataModel,
  setGlobalFilter,
}: StorageHeaderNavigationProps<ModelType>) {
  const breakpointLaptop = useMediaQueryHook("md");
  const navigate = useNavigate();
  const [open, onOpenChange] = useState<boolean>(false);
  const { model, permissions, setModel, setOpenCreateDir, selectedSize } =
    useFilesStorage();

  const size = selectedSize();

  const { directoryId: defaultDirectoryId } = useCredentials();
  const { id } = useParams();

  const handleOnValueChange = (str: string) => {
    setModel(str as "my_drive" | "shared_with_me");
  };

  useEffect(() => {
    onOpenChange(false);
    dataModel.setGlobalFilter("");
  }, [breakpointLaptop, dataModel]);

  const handleOnCreateDir = useCallback(() => {
    setOpenCreateDir(true);
  }, [setOpenCreateDir]);

  const handleToggleOpen = () => {
    onOpenChange((prev) => !prev);
    setGlobalFilter("");
  };

  const navigateBack = () => {
    const parentDirId = fsEntry?.parentDirId;
    if (parentDirId === defaultDirectoryId) {
      navigate("/fs");
    } else {
      navigate(`/fs/dir/${parentDirId}`);
    }
  };

  return (
    <header
      className={
        "grid auto-rows-auto grid-cols-2 gap-x-2 gap-y-3 rounded-lg border-1 border-border bg-bg-container p-4"
      }
    >
      <div className={"col-[1] row-[1] flex gap-0.5 md:col-[1_/_span_2]"}>
        <Button
          onClick={navigateBack}
          className={cn(!id && "hidden")}
          variant={"ghost"}
          variantColor={"muted"}
          icon={<ArrowLeft />}
          iconPosition={"only"}
        />
        <FSPath fsEntry={fsEntry} isLoading={isLoading} isError={isError} />
      </div>
      <div
        className={cn(
          "col-[2] row-[1] flex shrink-0 justify-end gap-2 md:row-[2] sm:gap-1",
          size > 0 && "md:hidden",
        )}
      >
        <Button
          onClick={handleOnCreateDir}
          variant={"outline"}
          variantColor={"muted"}
          icon={<FolderPlus />}
          iconPosition={"only"}
          disabled={isError || isLoading || !permissions.write}
        />
        <FileUploadTrigger multiple={true}>
          <Button
            variant={"outline"}
            variantColor={"muted"}
            icon={<FileUp />}
            iconPosition={"only"}
            disabled={isError || isLoading || !permissions.write}
          />
        </FileUploadTrigger>
        <FileUploadTrigger
          multiple={true}
          // @ts-ignore - webkitdirectory is a non-standard but widely supported attribute
          webkitdirectory={true}
          // @ts-ignore - directory is a non-standard but widely supported attribute
          directory={"true"}
          className={"sm:hidden"}
          disabled={isError || isLoading || !permissions.write}
        >
          <Button
            variant={"outline"}
            variantColor={"muted"}
            icon={<FolderUp />}
            iconPosition={"only"}
          />
        </FileUploadTrigger>
        <Button
          className={"hidden md:flex"}
          onClick={handleToggleOpen}
          variant={"outline"}
          variantColor={"muted"}
          icon={open ? <X /> : <ListFilter />}
          iconPosition={"only"}
        />
      </div>
      <FsManageBar className={"col-[1_/_span_2] row-[2]"} />
      <Tabs
        value={model}
        onValueChange={handleOnValueChange}
        className={cn(
          "col-[1] row-[2]",
          (!showTabsList || size > 0) && "hidden",
        )}
      >
        <TabsList className={"grid w-fit grid-cols-2"}>
          <TabsTrigger value={"my_drive"} className={"sm:px-4"}>
            <HardDrive className={"h-4 w-4"} />
            {!breakpointLaptop && "Mój Dysk"}
          </TabsTrigger>
          <TabsTrigger value={"shared_with_me"} className={"sm:px-4"} disabled>
            <Users className={"h-4 w-4"} />
            {!breakpointLaptop && "Udostępnione"}
          </TabsTrigger>
        </TabsList>
      </Tabs>
      <motion.div
        data-open={open}
        initial={{ opacity: 0, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        animate={{
          opacity: open || !breakpointLaptop ? 1 : 0,
          height: open || !breakpointLaptop ? "auto" : 0,
        }}
        transition={{
          ease: "easeInOut",
          stiffness: 260,
          damping: 20,
          duration: 0.25,
        }}
        className={cn(
          "col-[2] row-[2] md:col-[1_/_span_2] md:row-[3] md:data-[open=true]:flex md:data-[open=false]:hidden",
          size > 0 && "hidden md:flex",
        )}
      >
        <FsFilters dataModel={dataModel} setGlobalFilter={setGlobalFilter} />
      </motion.div>
    </header>
  );
}
