import { useCreateDirectoryMutation } from "@/api/queries/directoryQueries";
import { useFilesUploadMutation } from "@/api/queries/filesQueries";
import { FileWithPath } from "file-selector";
import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

type StateType = {
  isPending: boolean;
  isError: boolean;
  isSuccess: boolean;
  error: any | Error | null;
};

export const defaultState: StateType = {
  isPending: false,
  isError: false,
  isSuccess: false,
  error: null,
};

export type filesData = {
  directoryName?: string;
  directoryId?: string;
  file: FileWithPath | File;
  tempUUID: string;
}[];

interface DirectoryStructT {
  directoryName: string | null;
  directoryId: string | null;
  directoryUUID: string;
  children: DirectoryStructT[];
  files: (File | FileWithPath)[];
}

export default function useWriteFiles() {
  const { mutateAsync: writeFolder } = useCreateDirectoryMutation();
  const { executeMutations, statuses, clearStatuses } =
    useFilesUploadMutation();

  const getDirectoryStructure = useCallback(
    (
      fileList: (FileWithPath | DataTransferItem)[] | FileList,
      defaultDirId: string,
    ) => {
      const root: DirectoryStructT = {
        directoryName: null,
        directoryUUID: uuidv4(),
        directoryId: defaultDirId,
        children: [],
        files: [],
      };

      const extractFilePath = (
        file: FileWithPath | DataTransferItem | File,
      ): string => {
        if ("path" in file) {
          const filePathTmp = (file as FileWithPath).path;
          if (filePathTmp) {
            return filePathTmp;
          }
        } else if (file instanceof File) {
          return file.webkitRelativePath;
        } else if (file instanceof DataTransferItem) {
          const fileAsFile = file.getAsFile();
          if (fileAsFile) {
            return fileAsFile.webkitRelativePath;
          }
        }
        return "";
      };

      const extractFile = (
        file: FileWithPath | DataTransferItem | File,
      ): FileWithPath | File | undefined => {
        if (file instanceof DataTransferItem) {
          const fileAsFile = file.getAsFile();
          if (fileAsFile) {
            return fileAsFile;
          }
          return undefined;
        }
        return file;
      };

      for (const file of fileList) {
        const filePath = extractFilePath(file);
        const fileAsFile = extractFile(file);
        const parts = filePath.split("/").filter((part) => part.length > 0);
        let currentDir = root;

        for (let i = 0; i < parts.length; i++) {
          const part = parts[i];

          if (i === parts.length - 1 && fileAsFile) {
            // It's a file
            currentDir.files.push(fileAsFile);
          } else {
            // It's a directory
            let dir = currentDir.children.find(
              (child) => child.directoryName === part,
            );
            if (!dir) {
              dir = {
                directoryName: part,
                directoryUUID: uuidv4(),
                directoryId: null,
                files: [],
                children: [],
              };
              currentDir.children.push(dir);
            }
            currentDir = dir;
          }
        }
      }
      return root;
    },
    [],
  );

  const writeFilesCb = useCallback(
    async (files: File[], parentDirId: string, directoryUUID: string) => {
      const filesToWrite = files.map((file) => {
        return {
          file: file,
          tempUUID: uuidv4(),
          directoryId: parentDirId,
          directoryUUID: directoryUUID,
        };
      });

      try {
        await executeMutations(filesToWrite, { clearStatuses: false });
      } catch (error) {
        console.error(error);
        return false;
      }

      return true;
    },
    [],
  );

  const writeFoldersCb = useCallback(
    async (node: DirectoryStructT, parentDirId: string) => {
      const { directoryName, directoryUUID, files } = node;
      let currentDirId = parentDirId;
      try {
        if (directoryName !== null) {
          const response = await writeFolder({
            name: directoryName,
            parentDirId: parentDirId,
          });
          currentDirId = response.id;
        }

        // If the node has files, post the files
        if (files && files.length > 0) {
          writeFilesCb(files, currentDirId, directoryUUID);
        }

        // Traverse the children of the node, passing the currentDirId as their parentDirId
        for (const child of node.children) {
          const success = await writeFoldersCb(child, currentDirId); // Pass the current node's ID to children

          // If any child traversal fails, break the loop to stop further children processing
          if (!success) {
            return false; // Return false if a child fails
          }
        }

        return true;
      } catch (error) {
        return false; // Return false to stop further recursion
      }
    },
    [],
  );

  const writeCb = useCallback(
    async (
      files: (FileWithPath | DataTransferItem)[] | FileList,
      defaultId: string,
    ) => {
      const directoryStructure = getDirectoryStructure(files, defaultId);
      return await writeFoldersCb(directoryStructure, defaultId);
    },
    [executeMutations],
  );

  return { write: writeCb, statuses, clearStatuses };
}
