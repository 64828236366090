import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs/Tabs";

type MeetingAttendanceTogglesProps = {
  status: "present" | "absent" | "late";
  userId: number;
  onValueChange: (value: string, id: number) => void;
};

export default function MeetingAttendanceToggles(
  props: MeetingAttendanceTogglesProps,
) {
  const { status, userId, onValueChange } = props;

  const handleValueChange = (value: string) => {
    onValueChange(value, userId);
  };

  return (
    <Tabs value={status} onValueChange={handleValueChange}>
      <TabsList className={"grid w-fit grid-cols-3"}>
        <TabsTrigger
          className={
            "min-w-8 border-0 px-2 data-[state=active]:bg-bg-success-subtle data-[state=active]:text-fg-success sm:min-w-9"
          }
          value={"present"}
        >
          O
        </TabsTrigger>
        <TabsTrigger
          className={
            "min-w-8 border-0 px-2 data-[state=active]:bg-bg-warning-subtle data-[state=active]:text-fg-warning sm:min-w-9"
          }
          value={"late"}
        >
          S
        </TabsTrigger>
        <TabsTrigger
          className={
            "min-w-8 border-0 px-2 data-[state=active]:bg-bg-destructive-subtle data-[state=active]:text-fg-destructive sm:min-w-9"
          }
          value={"absent"}
        >
          N
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );
}
