import { httpErrorHandler } from "@/api/api";
import { usePostPasswordResetMutation } from "@/api/queries/authQueries";
import { BackgroundWrapper } from "@/components/ui/background-wrapper/BackgroundWrapper";
import { Button } from "@/components/ui/button/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form/Form";
import { ProgressiveImageLoader } from "@/components/ui/image/ProggresiveImageLoader";
import { Input } from "@/components/ui/input/Input";
import { useGetURLParams } from "@/hooks/useGetURLParams";
import { cn } from "@/lib/utils";
import {
  PasswordResetSchema,
  PasswordResetSchemaType,
} from "@/schemas/auth.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from "framer-motion";
import { Check } from "lucide-react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

export default function PasswordResetPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const authToken = useGetURLParams().get("t");

  const { mutateAsync, isPending, isSuccess } = usePostPasswordResetMutation();

  const form = useForm<PasswordResetSchemaType>({
    defaultValues: {
      email: "",
      authToken: authToken,
    },
    mode: "all",
    resolver: yupResolver(PasswordResetSchema),
  });

  const email = form.getValues("email");

  const checkIfCanGoBack = (): boolean => {
    return location.key !== "default";
  };

  const onSubmit = async (data: PasswordResetSchemaType) => {
    mutateAsync(data).catch((error) => {
      const { detail } = httpErrorHandler(error);
      form.setError("email", {
        type: "manual",
        message: detail,
      });
    });
  };

  return (
    <>
      <ProgressiveImageLoader
        alt="Background image"
        className={"absolute inset-0 z-0 opacity-80"}
        sources={{
          mobile: {
            lowRes: {
              webp: "/images/background/auth_bg_img_small.webp",
              jpeg: "/auth_bg/auth_bg_img_small.jpeg",
            },
            highRes: {
              webp: "/images/background/auth_bg_img_medium.webp",
              jpeg: "/images/background/auth_bg_img_medium.jpeg",
            },
          },
          tablet: {
            lowRes: {
              webp: "/images/background/auth_bg_img_medium.webp",
              jpeg: "/images/background/auth_bg_img_medium.jpeg",
            },
            highRes: {
              webp: "/images/background/auth_bg_img_large.webp",
              jpeg: "/images/background/auth_bg_img_large.jpeg",
            },
          },
          desktop: {
            lowRes: {
              webp: "/images/background/auth_bg_img_large.webp",
              jpeg: "/images/background/auth_bg_img_large.jpeg",
            },
            highRes: {
              webp: "/images/background/auth_bg_img_2k.webp",
              jpeg: "/images/background/auth_bg_img_2k.jpeg",
            },
          },
        }}
      />
      <div
        className={
          "flex min-h-[100dvh] w-full items-center justify-center p-6 sm:items-end sm:p-0 sm:pt-6"
        }
      >
        <section
          className={
            "z-30 flex min-h-[400px] w-[clamp(380px,50dvw,420px)] flex-col items-center gap-7 overflow-auto rounded-2xl border-1 border-border bg-bg-container px-6 py-6 shadow-md-sharp-border sm:h-[85dvh] sm:w-full sm:rounded-b-none sm:border-0 sm:border-t-1 sm:px-4"
          }
        >
          <Form {...form}>
            <form
              className={"flex w-full flex-grow flex-col gap-7"}
              onSubmit={form.handleSubmit(onSubmit)}
              noValidate
            >
              <h1 className={"py-3 text-4xl font-semibold text-fg-primary"}>
                Resetuj hasło
              </h1>
              <p
                className={
                  "rounded-md border-1 border-border p-3 text-sm text-fg-muted"
                }
              >
                Wprowadź adres email, na który zostanie wysłany link do resetu
                hasła.
              </p>
              <fieldset className={"flex flex-col gap-3"}>
                <FormField
                  name={"email"}
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder={"Email"} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{
                    opacity: isSuccess ? 1 : 0,
                    height: isSuccess ? "auto" : 0,
                  }}
                  transition={{ duration: 0.2 }}
                  className={"flex flex-row items-center gap-2 text-fg-success"}
                >
                  <Check className={"min-h-4 min-w-4"} />
                  <p className={"text-sm"}>
                    Wiadomość email została wysłana na podany adres email ({" "}
                    {email} )
                  </p>
                </motion.div>
              </fieldset>

              <footer className={"flex h-full grow flex-row items-end gap-3"}>
                <Button
                  type={"button"}
                  size={"lg"}
                  variant={"ghost"}
                  variantColor={"muted"}
                  onClick={() => navigate(-1)}
                  className={cn(checkIfCanGoBack() ? "w-full" : "hidden")}
                >
                  Cofnij
                </Button>
                <Button
                  type={"submit"}
                  size={"lg"}
                  isLoading={{
                    state: isPending,
                  }}
                  variant={"solid"}
                  variantColor={"brand"}
                  onClick={form.handleSubmit(onSubmit)}
                  className={"w-full"}
                >
                  Wyślij
                </Button>
              </footer>
            </form>
          </Form>
        </section>
      </div>
    </>
  );
}
