import { axiosInstance } from "@/api/api";
import { PostAddSchemaType } from "@/schemas/post.schema";
import { PostEditSchemaType } from "@/schemas/post.schema";
import { PostI, PostsI } from "@/types/posts";

export const getPosts = async (): Promise<PostsI[]> => {
  return await axiosInstance.get("/api/post/getAll");
};

export const getAssignedPosts = async (params?: {
  page?: number;
  pageSize?: number;
  sortOrder?: string;
}): Promise<PostsI[]> => {
  return await axiosInstance.get("/api/post/getAssigned", { params: params });
};

export const getPost = async (id?: number): Promise<PostI> => {
  return await axiosInstance.get("/api/post", { params: { id } });
};

export const postPost = async (data: PostAddSchemaType): Promise<PostI> => {
  const form = new FormData();

  form.append("name", data.name);
  form.append("active", String(data.active));
  if (data.content) {
    form.append("content", data.content);
  }

  if (data.attachments) {
    for (let i = 0; i < data.attachments.length; i++) {
      form.append("attachments", data.attachments[i]);
    }
  }

  return await axiosInstance.put("/api/post", form, {
    headers: { "content-type": "multipart/form-data" },
  });
};

export const editPost = async (data: PostEditSchemaType): Promise<PostsI> => {
  const form = new FormData();

  form.append("id", String(data.id));
  if (data.name) {
    form.append("name", data.name);
  }
  if (typeof data.active !== "undefined") {
    form.append("active", String(data.active));
  }
  if (data.content) {
    form.append("content", data.content);
  }
  if (data.attachments) {
    for (let i = 0; i < data.attachments.length; i++) {
      form.append("attachments", data.attachments[i]);
    }
  }

  return await axiosInstance.post("/api/post", form);
};

export const deletePost = async (data: { id: number }): Promise<string> => {
  return await axiosInstance.delete("/api/post", { data: data });
};
