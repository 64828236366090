import { timeToHeight } from "@/utils/timeToHeight";
import { format, isValid } from "date-fns";
import { useEffect, useState } from "react";

/**
 * TimeLineProps interface
 *
 * This interface defines the properties for the TimeLine component.
 *
 * @interface
 * @property {number} containerHeight - The height of the container.
 * @property {boolean} hide - A flag to hide the component, default is false.
 */
interface TimeLineProps {
  containerHeight: number;
  hide?: boolean;
}

/**
 * TimeLine component
 *
 * This component displays a timeline that updates every 15 seconds to reflect the current time.
 * The timeline's vertical position corresponds to the current time, with the top of the container representing the start of the day and the bottom representing the end of the day.
 * If the hide property is true, the component will not be rendered.
 *
 * @component
 * @param {TimeLineProps} props - The properties that define how the component should be rendered.
 * @returns {JSX.Element | null} A timeline that reflects the current time or null if the hide property is true.
 */

export default function CalendarContentTimeLine({
  containerHeight,
  hide = false,
}: TimeLineProps) {
  const [now, setNow] = useState<Date>(new Date());

  const handleDateTime = () => {
    if (isValid(now)) {return format(now, "HH:mm");}
    return "";
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  const offset = timeToHeight(now, containerHeight);

  if (hide) {return null;}

  return (
    <span
      className={"absolute z-10 h-px w-full bg-bg-accent"}
      style={{ top: `${offset}px` }}
    >
      <p
        className={
          "absolute -bottom-3 -left-10 z-10 flex h-6 w-11 flex-shrink-0 items-center justify-center rounded-sm bg-bg-accent p-1 text-xs text-fg-accent-on"
        }
      >
        {handleDateTime()}
      </p>
    </span>
  );
}
