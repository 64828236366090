import { httpErrorHandler } from "@/api/api";
import { PostMeetingPropsT } from "@/api/endpoints/meetings";
import {
  useEditMeetingMutation as useEditMeeting,
  useGetMeetingQuery as useGetMeeting,
} from "@/api/queries/meetingsQuery";
import ManipulateMeetingContent from "@/components/features/calendar/form/ManipulateMeetingContent";
import {
  convertDataToForm,
  convertFormToData,
} from "@/components/features/calendar/form/convert-data";
import { DialogContent } from "@/components/ui/dialog/Dialog";
import { Spinner } from "@/components/ui/spinner/Spinner";
import { useToast } from "@/components/ui/toast/useToast";
import { CalendarFormSchemaType } from "@/schemas/meeting.schema";
import { useMemo } from "react";

export default function EditMeeting({
  id,
  onOpenChange,
  queryParams,
}: {
  id: number;
  queryParams?: { after: string; before: string };
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) {
  const { toast } = useToast();

  const { mutateAsync, isPending } = useEditMeeting(queryParams);

  const { data: fetchedData, isFetching } = useGetMeeting(id);

  const formData = useMemo(() => convertDataToForm(fetchedData), [fetchedData]);
  const onSubmit = (data: CalendarFormSchemaType) => {
    const { userArr, groupArr, ...meeting } = convertFormToData(
      data,
    ) as PostMeetingPropsT;
    const memberChanges = [
      {
        userIds: userArr.filter(
          (userId) =>
            !(fetchedData?.users || []).some((user) => user.id === userId),
        ),
        groupIds: groupArr.filter(
          (groupId) =>
            !(fetchedData?.groups || []).some((group) => group.id === groupId),
        ),
        add: true,
        remove: false,
      },
      {
        userIds: (fetchedData?.users || [])
          .filter((user) => !userArr.some((userId) => userId === user.id))
          .map((user) => user.id),
        groupIds: (fetchedData?.groups || [])
          .filter((group) => !groupArr.some((groupId) => groupId === group.id))
          .map((group) => group.id),
        add: false,
        remove: true,
      },
    ];

    mutateAsync({ ...meeting, id: id, memberChanges: memberChanges }).catch(
      (error) => {
        const { title, detail } = httpErrorHandler(error);
        toast({
          title: title,
          description: detail,
          variant: "destructive",
        });
      },
    );
    onOpenChange(false);
  };
  return (
    <DialogContent
      className={"h-[calc(100dvh-var(--space-11))] max-w-[35rem]"}
      onInteractOutside={(e) => {
        if (!isFetching) {e.preventDefault();}
      }}
      onPointerDownOutside={(e) => {
        if (!isFetching) {e.preventDefault();}
      }}
    >
      {isPending && (
        <div
          className={
            "absolute inset-0 z-20 m-auto flex flex-wrap items-center justify-center gap-3 bg-bg-container"
          }
        >
          <Spinner size={"lg"} />
          <h5 className={"font-medium"}>Trwa zapisywanie zmian...</h5>
        </div>
      )}
      {isFetching && (
        <div
          className={
            "absolute inset-0 z-20 m-auto flex flex-wrap items-center justify-center gap-3 bg-bg-container"
          }
        >
          <Spinner size={"lg"} />
          <h5 className={"font-medium"}>Trwa ładowanie danych...</h5>
        </div>
      )}
      <ManipulateMeetingContent
        callback={onSubmit}
        formData={formData}
        defaultSelectedGroups={fetchedData?.groups}
        defaultSelectedUsers={fetchedData?.users}
        onOpenChange={onOpenChange}
      />
    </DialogContent>
  );
}
