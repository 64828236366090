import { AudioLayout } from "@/components/ui/media-viewer/AudioLayout";
import {
  MediaPlayer,
  MediaPlayerInstance,
  MediaPlayerProps,
  MediaProvider,
} from "@vidstack/react";
import { PlayerSrc } from "@vidstack/react";
import "@vidstack/react/player/styles/base.css";
import { useRef } from "react";

type AudioPlayerProps = {
  src?: PlayerSrc;
  title: string;
  variant?: "default" | "minimal";
} & Omit<MediaPlayerProps, "children">;

export default function AudioPlayer({
  title,
  src,
  variant = "default",
  ...props
}: AudioPlayerProps) {
  const player = useRef<MediaPlayerInstance>(null);

  return (
    <MediaPlayer
      className={
        "ring-media-focus relative w-full overflow-hidden rounded-sm bg-bg-container text-fg-muted ring-ring data-[focus]:ring-1"
      }
      title={title}
      src={src}
      ref={player}
      playsInline
      streamType={"on-demand"}
      storage={"audio-media-storage"}
      viewType={"audio"}
      {...props}
    >
      <MediaProvider />
      <AudioLayout variant={variant} />
    </MediaPlayer>
  );
}
