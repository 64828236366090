import { useBatchMutation } from "@/api/api-utils";
import {
  createDirectory,
  deleteDirectory,
  editDirectory,
  getDirectory,
  getDirectoryPermissions,
  getSharedDirectory,
} from "@/api/endpoints/directories";
import { queryClient } from "@/api/query-client";
import {
  CreateDirectorySchemaType,
  EditDirectoryType,
} from "@/schemas/directory.schema";
import { DirectoryI } from "@/types/files";
import { useMutation, useQueries, useQuery } from "@tanstack/react-query";

export const useGetDirectoryQuery = (
  directoryId: string,
  enable = true,
) => {
  return useQuery({
    queryKey: ["directory", directoryId],
    queryFn: () => getDirectory(directoryId),
    enabled: enable,
  });
};

export const useGetSharedDirectoryQuery = (enable: boolean) => {
  return useQuery({
    queryKey: ["sharedDirectory"],
    queryFn: () => getSharedDirectory(),
    enabled: enable,
  });
};

export const useGetDirectoryPermissionsQuery = (
  id?: string,
  enable = true,
) => {
  return useQuery({
    queryKey: ["directoryPermissions", id],
    queryFn: () => getDirectoryPermissions(id),
    enabled: enable && !!id,
  });
};

export const useGetDirectoryPermissionsQueries = (
  ids: string[],
  enabled = true,
) => {
  return useQueries({
    queries: ids.map((id) => ({
      queryKey: ["directoryPermissions", id],
      queryFn: () => getDirectoryPermissions(id),
      enabled: enabled && !!id,
    })),
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
        pending: results.some((result) => result.isPending),
      };
    },
  });
};

export const useCreateDirectoryMutation = () => {
  return useMutation({
    mutationFn: (data: CreateDirectorySchemaType) => createDirectory(data),
    onSuccess: (data, variables) => {
      queryClient.setQueryData<DirectoryI>(
        ["directory", variables.parentDirId],
        (prev) => {
          if (!prev) {return;}
          return {
            ...prev,
            childDirs: [...prev.childDirs, data],
          };
        },
      );
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["directory", variables?.parentDirId],
      });
    },
  });
};

export const useEditDirectoryMutation = (directoryId?: string) => {
  return useMutation({
    mutationKey: ["editDir"],
    mutationFn: (data: EditDirectoryType) => editDirectory(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["directory", directoryId],
      });
    },
  });
};

export const useEditDirectoriesMutation = (directoryId?: string) => {
  return useBatchMutation({
    mutationKey: ["editDir"],
    mutationFn: (data: EditDirectoryType) => editDirectory(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["directory", directoryId],
      });
    },
  });
};

export const useDeleteDirectoryMutation = (directoryId?: string) => {
  return useMutation({
    mutationKey: ["deleteDir"],
    mutationFn: (id: string) => deleteDirectory(id),
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey: ["directory", directoryId] });
      const prevDirectory = queryClient.getQueryData([
        "directory",
        directoryId,
      ]);

      if (prevDirectory) {
        queryClient.setQueryData<DirectoryI>(
          ["directory", directoryId],
          (prev) => {
            if (!prev) {return;}
            return {
              ...prev,
              files: prev.files.filter((file) => file.id !== id),
            };
          },
        );
      }

      return { prevDirectory };
    },
    onError: (err, variables, context) => {
      if (context?.prevDirectory) {
        queryClient.setQueryData(
          ["directory", directoryId],
          context.prevDirectory,
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["directory", directoryId],
      });
    },
  });
};

export const useDeleteDirectoriesMutation = (directoryId?: string) => {
  return useBatchMutation({
    mutationKey: ["deleteDir"],
    mutationFn: (id: string) => deleteDirectory(id),
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey: ["directory", directoryId] });
      const prevDirectory = queryClient.getQueryData<DirectoryI>([
        "directory",
        directoryId,
      ]);

      if (prevDirectory) {
        queryClient.setQueryData<DirectoryI>(
          ["directory", directoryId],
          (prev) => {
            if (!prev) {return;}
            return {
              ...prev,
              files: prev.files.filter((file) => file.id !== id),
            };
          },
        );
      }

      return { prevDirectory };
    },
    onError: (err, variables, context) => {
      if (context?.prevDirectory) {
        queryClient.setQueryData(
          ["directory", directoryId],
          context.prevDirectory,
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["directory", directoryId],
      });
    },
  });
};
