import Directory from "@/components/features/files/Directory";
import { DirectoryCardSkeleton } from "@/components/features/files/DirectoryCard";
import File from "@/components/features/files/File";
import { FIleCardSkeleton } from "@/components/features/files/FileCard";
import { Label } from "@/components/ui/label/Label";
import { cn } from "@/lib/utils";
import { DirectoryDirI, DirectoryFileI } from "@/types/files";

interface FilesStorageContentGridProps {
  files?: DirectoryFileI[];
  directories?: DirectoryDirI[];
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
}

export default function FilesStorageContentGrid({
  files,
  directories,
  isLoading,
  isSuccess,
}: FilesStorageContentGridProps) {
  return (
    <div className="flex h-full grow flex-col gap-4">
      <div className={cn(isSuccess && !directories?.length && "hidden")}>
        <Label className={"text-sm text-fg-secondary"}>Foldery</Label>
        <div
          className={
            "mt-3 grid grid-cols-[repeat(auto-fill,minmax(256px,1fr))] grid-rows-[auto] gap-4"
          }
        >
          {/* directory loading view */}
          {isLoading && (
            <>
              <DirectoryCardSkeleton />
              <DirectoryCardSkeleton />
              <DirectoryCardSkeleton />
            </>
          )}
          {/* directory data view */}
          {isSuccess &&
            directories &&
            directories.map((directory) => (
              <Directory key={directory.id} directory={directory} />
            ))}
        </div>
      </div>
      <div className={cn(isSuccess && !files?.length && "hidden")}>
        <Label className={"text-sm text-fg-secondary"}>Pliki</Label>
        <div
          className={
            "mt-3 grid grid-cols-[repeat(auto-fill,minmax(256px,1fr))] grid-rows-[auto] gap-4"
          }
        >
          {/* files loading view */}
          {isLoading && (
            <>
              <FIleCardSkeleton />
              <FIleCardSkeleton />
              <FIleCardSkeleton />
            </>
          )}
          {/* files data view */}
          {isSuccess &&
            files &&
            files.map((file) => <File key={file.id} file={file} />)}
        </div>
      </div>
    </div>
  );
}
