import WeekCards from "@/components/features/calendar/WeekCards";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { setNavigateT } from "@/types/calendar";
import { getWeek } from "@/utils/dateFormat";
import { format, getDate, isToday } from "date-fns";
import { pl } from "date-fns/locale";
import { useMemo } from "react";

interface CalendarContentNavWeekProps {
  date: Date;
  setNavigate: setNavigateT;
}

export default function CalendarHeaderWeek({
  date,
  setNavigate,
}: CalendarContentNavWeekProps) {
  const isMobile = useMediaQueryHook("sm");
  const weekArray = useMemo(() => getWeek(date), [date]);

  const onNavigate = (day: Date) => {
    setNavigate({ newDate: day });
  };

  const content = weekArray.map((weekDayDate) => {
    const dateDay = getDate(weekDayDate);
    const weekDay = format(weekDayDate, "EEE", { locale: pl });
    const today = isToday(weekDayDate);

    return (
      <div
        className={"flex w-full flex-col items-center gap-0.5"}
        key={weekDayDate.toString()}
      >
        <p className={cn("text-lg font-semibold", today && "text-fg-accent")}>
          {dateDay}
        </p>
        <span className={cn("text-xs", today && "text-fg-accent")}>
          {weekDay}
        </span>
      </div>
    );
  });
  return (
    <div className={"flex w-full flex-row justify-center pl-10 sm:pl-0"}>
      {isMobile ? (
        <WeekCards
          selected={date}
          onSelect={onNavigate}
          className={"w-full max-w-14"}
        />
      ) : (
        content
      )}
    </div>
  );
}
