import { Button } from "@/components/ui/button/Button";
import { MeetingI } from "@/types/meetings";
import { Check, Copy, Video } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function MeetingRemoteLink({ meeting }: { meeting: MeetingI }) {
  const { meetingURL } = meeting;
  const [copiedPassword, setCopiedPassword] = useState<boolean>(false);
  const copyContent = async () => {
    if (!meetingURL) return;
    await navigator.clipboard.writeText(meetingURL);
    setCopiedPassword(true);
    setTimeout(() => {
      setCopiedPassword(false);
    }, 1000);
  };

  return (
    <div>
      <div className={"flex w-full justify-between"}>
        {meetingURL ? (
          <Link to={meetingURL}>
            <Button
              disabled={!meetingURL}
              icon={<Video />}
              iconPosition={"left"}
            >
              Dołącz do spotkania
            </Button>
          </Link>
        ) : (
          <Button disabled icon={<Video />} iconPosition={"left"}>
            Brak linku do spotkania
          </Button>
        )}
        <Button
          onClick={copyContent}
          variant={"ghost"}
          variantColor={"muted"}
          disabled={!meetingURL}
          icon={copiedPassword ? <Check /> : <Copy />}
          iconPosition={"only"}
        />
      </div>
      <p className={"mt-3 truncate text-xs text-fg-muted empty:hidden"}>
        {meetingURL}
      </p>
    </div>
  );
}
