import useFSEntryPermissions from "@/components/features/files/hooks/useFSEntryPermissions";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar/Avatar";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer/Drawer";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card/HoverCard";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import {
  RadioGroup,
  RadioGroupItem,
} from "@/components/ui/input/radio-group/RadioGroup";
import { Separator } from "@/components/ui/separator/Separator";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { PermissionsI, SourceI } from "@/types/files";
import { getAvatar } from "@/utils/getAvatar";
import { Label } from "@radix-ui/react-label";
import { ChevronDown, Dot, UserX, Users } from "lucide-react";
import { useCallback, useMemo, useState } from "react";

interface PermissionLiEntityProps {
  data: {
    id: number;
    title: string;
    detail?: string;
    avatarURL?: string;
  };
  sources?: SourceI[];
  permissions: PermissionsI;
  onRemove: () => void;
  onPermissionChange: (id: number, newValue: string) => void;
}

export default function PermissionLiEntity({
  data,
  permissions,
  sources,
  onRemove,
  onPermissionChange,
}: PermissionLiEntityProps) {
  const breakpoint = useMediaQueryHook("sm");
  const { currentPermission, permissionsArray } = useFSEntryPermissions({
    permissions,
  });

  const [access, setAccess] = useState<string>(currentPermission.type);

  const isInherited = useMemo(
    () => sources?.find((source) => source?.isInherited),
    [sources],
  );

  const accessOnChange = useCallback(
    (value: string) => {
      onPermissionChange(data.id, value);
      setAccess(value);
    },
    [data.id, onPermissionChange],
  );

  const innerContent = useMemo(() => {
    if (isInherited) {
      return (
        <HoverCard openDelay={500}>
          <HoverCardTrigger asChild>
            <Badge variant={"outline"} size={"sm"}>
              Dziedziczone
            </Badge>
          </HoverCardTrigger>
          <HoverCardContent align={"end"} className={"rounded-sm p-2"}>
            <ul>
              {sources?.map((source, index) => {
                if (source === undefined || source?.title === undefined) {
                  return null;
                }
                return (
                  <li
                    key={source.title + "_" + index}
                    className={"flex items-center"}
                  >
                    <Dot className={"h-4 w-4 stroke-fg-secondary"} />
                    <p
                      className={
                        "max-w-full truncate text-sm text-fg-secondary"
                      }
                    >
                      {source?.isInherited ? "Dziedziczone: " : null}
                      {source.title}
                    </p>
                  </li>
                );
              })}
            </ul>
          </HoverCardContent>
        </HoverCard>
      );
    } else if (breakpoint) {
      return (
        <ChevronDown
          className={"m-3 h-4 min-h-4 w-4 min-w-4 stroke-fg-muted"}
        />
      );
    } else {
      return (
        <DropdownMenuTrigger asChild>
          <Button
            className={"w-[18ch] min-w-[18ch]"}
            variant={"outline"}
            variantColor={"muted"}
            icon={<ChevronDown className={"ml-auto"} />}
            iconPosition={"right"}
            size={"sm"}
          >
            {currentPermission.title}
          </Button>
        </DropdownMenuTrigger>
      );
    }
  }, [isInherited, breakpoint, sources, currentPermission.title]);

  const content = useMemo(() => {
    return (
      <li
        className={
          "group flex h-fit items-center justify-between gap-3 rounded-sm py-2 sm:cursor-pointer"
        }
      >
        <div className={"flex items-center gap-3"}>
          {data?.avatarURL !== undefined && (
            <Avatar size={"sm"}>
              <AvatarImage src={getAvatar(data.avatarURL)} alt={"avatar"} />
              <AvatarFallback>
                <Users className={"h-4 w-4"} />
              </AvatarFallback>
            </Avatar>
          )}
          <div className={"flex flex-col gap-1"}>
            {data.title ? (
              <h5 className={"w-full truncate text-md text-fg-primary"}>
                {data.title}
              </h5>
            ) : null}
            <small className={"text-xs text-fg-muted"}>
              {currentPermission.title}
            </small>
          </div>
        </div>
        {innerContent}
      </li>
    );
  }, [data.avatarURL, data.title, currentPermission.title, innerContent]);

  if (isInherited) {
    return content;
  }
  if (breakpoint) {
    return (
      <Drawer>
        <DrawerTrigger asChild>{content}</DrawerTrigger>
        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle>Poświadczenia</DrawerTitle>
          </DrawerHeader>
          <DrawerBody className={"flex flex-col gap-4"}>
            <RadioGroup value={access} onValueChange={accessOnChange}>
              {permissionsArray.map((permission) => (
                <div
                  key={permission.type}
                  className="flex items-center space-x-2"
                >
                  <RadioGroupItem
                    value={permission.type}
                    id={permission.type}
                  />
                  <Label htmlFor={permission.type}>{permission.title}</Label>
                </div>
              ))}
            </RadioGroup>
            <Separator />
            <Button
              className={"w-full"}
              variant={"ghost"}
              variantColor={"destructive"}
              icon={<UserX />}
              iconPosition={"left"}
            >
              Usuń dostęp
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }
  return (
    <DropdownMenu>
      {content}
      <DropdownMenuContent align={"end"}>
        <DropdownMenuRadioGroup value={access} onValueChange={accessOnChange}>
          {permissionsArray.map((permission) => (
            <DropdownMenuRadioItem
              key={permission.type}
              value={permission.type}
            >
              {permission.title}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem
            className={"text-fg-destructive"}
            onClick={onRemove}
          >
            <UserX className={"h-4 w-4"} />
            Usuń dostęp
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
