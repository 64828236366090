import { useInfiniteQueryResult } from "@/api/api-utils";
import { useGetNotificationsInfiniteQuery } from "@/api/queries/notificationsQueries";
import { usePostReadNotificationsQuery } from "@/api/queries/notificationsQueries";
import NotificationsList from "@/components/features/notifications/NotificationsList";
import NotificationsSheetFooter from "@/components/features/notifications/NotificationsSheetFooter";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer/Drawer";
import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet/Sheet";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { useCallback } from "react";

interface NotificationSheetProps {
  children: React.ReactNode;
}

function NotificationsSheet({ children, ...props }: NotificationSheetProps) {
  const breakpoint = useMediaQueryHook("sm");

  const getNotificationsQuery = useGetNotificationsInfiniteQuery();
  const { mutateAsync } = usePostReadNotificationsQuery();

  const { data: notifications } = useInfiniteQueryResult(
    getNotificationsQuery.data,
  );

  const makeNotificationsRead = useCallback(
    (array: number[]) => {
      mutateAsync({ arr: array });
    },
    [mutateAsync],
  );

  const makeAllVisibleNotificationsRead = useCallback(() => {
    mutateAsync({ arr: notifications.map((m) => m.id) });
  }, [notifications]);

  const makeAllNotificationsRead = useCallback(() => {}, []);

  if (breakpoint) {
    return (
      <Drawer {...props}>
        <DrawerTrigger asChild>{children}</DrawerTrigger>
        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle className={"sr-only"}>Powiadomienia</DrawerTitle>
          </DrawerHeader>
          <DrawerBody className={"flex flex-col gap-2"}>
            <NotificationsList
              {...getNotificationsQuery}
              makeNotificationsRead={makeNotificationsRead}
            />
          </DrawerBody>
          <DrawerFooter className={"flex flex-row justify-end"}>
            <NotificationsSheetFooter
              makeAllVisibleNotificationsRead={makeAllVisibleNotificationsRead}
            />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  }

  return (
    <Sheet {...props}>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetContent className={"max-w-[450px]"} side={"right"}>
        <SheetHeader>
          <SheetTitle>Powiadomienia</SheetTitle>
        </SheetHeader>
        <SheetBody className={"flex flex-col gap-2"}>
          <NotificationsList
            {...getNotificationsQuery}
            makeNotificationsRead={makeNotificationsRead}
          />
        </SheetBody>
        <SheetFooter className={"flex flex-row justify-end"}>
          <NotificationsSheetFooter
            makeAllVisibleNotificationsRead={makeAllVisibleNotificationsRead}
          />
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}
NotificationsSheet.displayName = "NotificationSheet";

export { NotificationsSheet };
