import { useCallback, useRef } from "react";

export interface optionsT {
  preventDefault?: boolean;
  delay?: number;
  distance?: number;
}

export default function useLongPress<Type>(
  longPressCallback: (
    event: React.PointerEvent<Type>,
    isTouch: boolean,
  ) => void,
  options: optionsT = {},
) {
  const { preventDefault = true, delay = 300, distance = 5 } = options;
  const timerRef = useRef<number | null>(null);
  const posRef = useRef<[number, number]>([0, 0]);

  const isTouchEvent = useCallback((event: React.PointerEvent<Type>) => {
    return event.pointerType === "touch";
  }, []);

  const timesup = useCallback(
    (event: React.PointerEvent<Type>) => {
      timerRef.current = null;
      const isTouch = isTouchEvent(event);
      longPressCallback?.(event, isTouch);
    },
    [timerRef, isTouchEvent, longPressCallback],
  );

  const onPointerDownCb = useCallback(
    (event: React.PointerEvent<Type>) => {
      if (preventDefault) {event.preventDefault();}
      const clientX = event.clientX;
      const clientY = event.clientY;
      posRef.current = [clientX, clientY];
      const timeoutId = window.setTimeout(
        timesup.bind(null, { ...event }),
        delay,
      );
      timerRef.current = timeoutId;
    },
    [posRef, preventDefault, delay, timesup],
  );

  const onPointerUpCb = useCallback(
    (event: React.PointerEvent<Type>) => {
      if (preventDefault) {event.preventDefault();}
      if (timerRef.current) {
        window.clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    },
    [timerRef, preventDefault],
  );

  const onPointerMoveCb = useCallback(
    (event: React.PointerEvent<Type>) => {
      if (preventDefault) {event.preventDefault();}
      // cancel hold operation if moved too much
      if (timerRef.current) {
        const clientX = event.clientX;
        const clientY = event.clientY;
        const d =
          (clientX - posRef.current[0]) ** 2 +
          (clientY - posRef.current[1]) ** 2;
        if (d > distance) {
          window.clearTimeout(timerRef.current);
          timerRef.current = null;
        }
      }
    },
    [preventDefault, timerRef, posRef, distance],
  );

  return {
    onPointerDown: onPointerDownCb,
    onPointerUp: onPointerUpCb,
    onPointerMove: onPointerMoveCb,
  };
}
