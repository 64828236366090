import {
  deleteLocation,
  editLocation,
  getLocation,
  getLocations,
  postLocation,
} from "@/api/endpoints/locations";
import { queryClient } from "@/api/query-client";
import {
  AddLocationSchemaType,
  EditLocationSchemaType,
} from "@/schemas/location.schema";
import { LocationI } from "@/types/locations";
import { useMutation, useQuery } from "@tanstack/react-query";

export const useGetLocationsQuery = () => {
  return useQuery({
    queryKey: ["locations"],
    queryFn: () => getLocations(),
  });
};

export const useGetLocationQuery = ({ id }: { id?: number }) => {
  return useQuery({
    queryKey: ["locations", id],
    queryFn: () => getLocation(id),
    enabled: !!id, // only run the query if id is not null or undefined
  });
};

export const useDeleteLocationMutation = () => {
  return useMutation({
    mutationFn: (data: { id: number }) => deleteLocation(data),
    onSuccess: (data, { id }) => {
      queryClient.setQueryData(["locations"], (prev: LocationI[]) =>
        prev.filter((location: LocationI) => location.id !== id),
      );
    },
  });
};

export const useEditLocationMutation = () => {
  return useMutation({
    mutationFn: (data: EditLocationSchemaType) => editLocation(data),
    onSuccess: (data) => {
      queryClient.setQueryData(["locations", data.id], (curr: LocationI) => {
        return { ...curr, ...data };
      });
      queryClient.setQueryData(["locations"], (curr: LocationI[]) =>
        curr.map((l) => (l.id === data.id ? data : l)),
      );
    },
  });
};

export const usePostLocationMutation = () => {
  return useMutation({
    mutationFn: (data: AddLocationSchemaType) => postLocation(data),
    onSuccess: (data) => {
      queryClient.setQueryData(["locations"], (curr: LocationI[]) => {
        if (!curr) {
          return;
        }
        return [...curr, data];
      });
    },
  });
};
