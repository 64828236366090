import CalendarNavigationOptions from "@/components/features/calendar/layout/header/CalendarNavigationOptions";
import CalendarToggle from "@/components/features/calendar/layout/header/CalendarToggle";
import { Button } from "@/components/ui/button/Button";
import { DatetimePicker } from "@/components/ui/input/datetime-picker/DatetimePicker";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { VIEW_KEY } from "@/page/pages/calendar/CalendarPage";
import { setNavigateT } from "@/types/calendar";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface CalendarHeaderProps {
  date: Date;
  changeDate: (value: number) => void;
  setNavigate: setNavigateT;
  viewKey: VIEW_KEY;
}

export default function CalendarHeader({
  date,
  changeDate,
  viewKey,
  setNavigate,
}: CalendarHeaderProps) {
  const isMobile = useMediaQueryHook("sm");

  const handleNavigate = (value: Date | null) => {
    if (!value) {return;}
    setNavigate({ newDate: value });
  };

  const navigationOptions = (
    <CalendarNavigationOptions setNavigate={setNavigate} date={date} />
  );

  return (
    <>
      <header
        className={
          "flex w-full flex-row justify-between gap-2 border-b border-b-border p-4 md:flex-col"
        }
      >
        <div className={"flex flex-row justify-between gap-2"}>
          <div className={"flex flex-row gap-2"}>
            {!isMobile && (
              <Button
                variant={"outline"}
                variantColor={"muted"}
                iconPosition={"only"}
                icon={<ChevronLeft />}
                onClick={() => changeDate(-1)}
              />
            )}
            <DatetimePicker
              aria-label={"date picker"}
              button={true}
              className={"w-[9rem]"}
              value={date}
              onChange={handleNavigate}
              granularity={"day"}
            />

            {!isMobile && (
              <Button
                variant={"outline"}
                variantColor={"muted"}
                iconPosition={"only"}
                icon={<ChevronRight />}
                onClick={() => changeDate(1)}
              />
            )}
          </div>
          {isMobile && navigationOptions}
        </div>
        <div className={"flex flex-row justify-between gap-2"}>
          <CalendarToggle setNavigate={setNavigate} viewKey={viewKey} />
          {!isMobile && navigationOptions}
        </div>
      </header>
    </>
  );
}
