import { httpErrorHandler } from "@/api/api";
import { useInfiniteQueryResult } from "@/api/api-utils";
import {
  usePostGroupMutation as useAddGroup,
  useEditGroupMutation as useEditGroup,
} from "@/api/queries/groupsQueries";
import { useGetUsersInfiniteQuery } from "@/api/queries/usersQueries";
import { Button } from "@/components/ui/button/Button";
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form/Form";
import { Input, InputActionButton } from "@/components/ui/input/Input";
import { Textarea } from "@/components/ui/input/textarea/Textarea";
import {
  LiElement,
  LiElementSeparator,
} from "@/components/ui/list/ListElement";
import SelectDataList from "@/components/ui/list/SelectDataList";
import { Separator } from "@/components/ui/separator/Separator";
import { Spinner } from "@/components/ui/spinner/Spinner";
import { useToast } from "@/components/ui/toast/useToast";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { GroupAddSchema, GroupAddSchemaType } from "@/schemas/group.schema";
import { UsersI } from "@/types/users";
import { yupResolver } from "@hookform/resolvers/yup";
import { Search, X } from "lucide-react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";

interface GroupAddFormPropsT {
  onOpenChange: (value: boolean) => void;
}

export default function CreateGroup({ onOpenChange }: GroupAddFormPropsT) {
  const breakpoint = useMediaQueryHook("sm");
  const { toast } = useToast();
  const rootRef = useRef<HTMLDivElement>(null);

  const [isPending, setIsPending] = useState<boolean>(false);
  const [searchUserValue, setSearchUserValue] = useState<string>("");
  const searchUserDebouncedValue = useDebounceValue(searchUserValue, 400);
  const [selectedUsers, setSelectedUsers] = useState<UsersI[]>([]);

  const { mutateAsync: addGroup } = useAddGroup();
  const { mutateAsync: editGroup } = useEditGroup();

  const getUsersInfiniteQuery = useGetUsersInfiniteQuery({
    name: searchUserDebouncedValue,
  });

  const {
    data: users,
    isEmpty: usersIsEmpty,
    noResults: usersNoResults,
  } = useInfiniteQueryResult(
    getUsersInfiniteQuery.data,
    searchUserDebouncedValue,
  );

  const handleClose = () => {
    onOpenChange(false);
    setSearchUserValue("");
    setSelectedUsers([]);
    form.reset();
  };

  const form = useForm<GroupAddSchemaType>({
    defaultValues: {
      name: "",
      desc: "",
    },
    mode: "onBlur",
    resolver: yupResolver(GroupAddSchema),
  });

  const onSubmit = async (data: GroupAddSchemaType) => {
    setIsPending(true);
    await addGroup(data)
      .then(async (response) => {
        await editGroup({
          id: response.id,
          memberChanges: [
            { userIds: selectedUsers.map((user) => user.id), add: true },
          ],
        })
          .then(() => {
            handleClose();
          })
          .catch((error) => {
            const { title, detail } = httpErrorHandler(error);
            toast({
              variant: "destructive",
              title: title,
              description: detail,
            });
          });
      })
      .catch((error) => {
        const { title, detail } = httpErrorHandler(error);
        toast({
          variant: "destructive",
          title: title,
          description: detail,
        });
      });
    setIsPending(false);
  };

  return (
    <DialogContent
      className={cn(!breakpoint && "h-[40rem] max-w-[120ch]")}
      onCloseAutoFocus={handleClose}
      onEscapeKeyDown={handleClose}
    >
      {isPending && (
        <div
          className={
            "absolute inset-0 z-20 m-auto flex flex-wrap items-center justify-center gap-3 bg-bg-container"
          }
        >
          <Spinner size={"lg"} />
          <h5 className={"font-medium"}>Trwa tworzenie grupy...</h5>
        </div>
      )}
      <DialogHeader>
        <DialogTitle>Utwórz Grupę</DialogTitle>
      </DialogHeader>
      <Form {...form}>
        <DialogBody className={"flex h-auto gap-4 sm:flex-col"}>
          <form
            className={cn(
              "flex h-fit w-full flex-col gap-3",
              !breakpoint && "sticky top-0",
            )}
            onSubmit={form.handleSubmit(onSubmit)}
            noValidate
          >
            <div className={"col-span-2 flex h-10 min-h-10 items-center"}>
              <h3 className={"text-lg font-medium"}>Dane:</h3>
            </div>
            <FormField
              name={"name"}
              control={form.control}
              render={({ field }) => (
                <FormItem className={"col-span-1"}>
                  <FormLabel>Nazwa</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder={"Nazwa"} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name={"desc"}
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Opis{" "}
                    <small className={"text-sm text-fg-muted"}>
                      (opcjonalne)
                    </small>
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder={"Opis"}
                      className={"min-h-[5rem]"}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
          <Separator orientation={"vertical"} />
          <div className={"flex w-full flex-col gap-2"}>
            <div
              className={cn(
                "z-10 flex flex-col gap-2 bg-bg-container pb-2",
                !breakpoint && "sticky top-0",
              )}
            >
              <div className={"col-span-2 flex h-10 min-h-10 items-center"}>
                <h3 className={"text-lg font-medium"}>Użytkownicy:</h3>
              </div>
              <Input
                placeholder={"Wyszukaj..."}
                value={searchUserValue}
                onChange={(e) => setSearchUserValue(e.target.value)}
                startContent={<Search className={"ml-2"} />}
                endContent={
                  <InputActionButton
                    disabled={!searchUserValue.length}
                    onClick={() => setSearchUserValue("")}
                    icon={<X />}
                  />
                }
              />
            </div>
            <div ref={rootRef}>
              <SelectDataList
                root={rootRef.current}
                query={getUsersInfiniteQuery}
                selected={selectedUsers}
                setSelected={setSelectedUsers}
                isEmpty={usersIsEmpty}
                isNoResults={usersNoResults}
                emptyMessage={"Wyszukaj użytkownika"}
                searchNoResultsMessage={"Nie znaleziono grupy o podanej nazwie"}
                loadingElementPros={{
                  hasAvatar: true,
                  hasDetail: true,
                  hasTitle: true,
                }}
              >
                {!searchUserDebouncedValue && (
                  <>
                    {selectedUsers.map((entity) => (
                      <LiElement
                        key={entity.id}
                        entity={entity}
                        data={{
                          title: entity.name + " " + entity.surname,
                          detail: entity.email,
                          avatarURL: entity.avatarURL,
                        }}
                      />
                    ))}
                    <LiElementSeparator />
                  </>
                )}
                {users.map((entity) => (
                  <LiElement
                    key={entity.id}
                    entity={entity}
                    data={{
                      title: entity.name + " " + entity.surname,
                      detail: entity.email,
                      avatarURL: entity.avatarURL,
                    }}
                  />
                ))}
              </SelectDataList>
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            onClick={handleClose}
            type={"button"}
            variant={"flat"}
            variantColor={"muted"}
            className={"sm:w-full"}
          >
            Anuluj
          </Button>
          <Button
            onClick={form.handleSubmit(onSubmit)}
            type={"submit"}
            variant={"flat"}
            variantColor={"brand"}
            className={"sm:w-full"}
          >
            Zapisz
          </Button>
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
