import { cn } from "@/lib/utils";
import { format, getDate, isToday, isValid } from "date-fns";
import { pl } from "date-fns/locale";

interface CalendarContentNavDayProps {
  date: Date;
}

export default function CalendarHeaderDay({
  date,
}: CalendarContentNavDayProps) {
  const handleFormatDate = (date: Date): string => {
    if (isValid(date)) {
      return format(date, "EEEE, d MMMM yyyy", { locale: pl });
    }
    return "";
  };

  return (
    <div className={"gap4 flex flex-row items-center gap-2"}>
      <div
        className={cn(
          "flex h-11 w-11 items-center justify-center rounded-md p-2",
          isToday(date)
            ? "bg-bg-brand text-fg-brand-on"
            : "bg-bg-muted text-fg-primary",
        )}
      >
        {getDate(date)}
      </div>
      <p className={"text-md font-medium text-fg-primary"}>
        {handleFormatDate(date)}
      </p>
    </div>
  );
}
