import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar/Avatar";
import { Button } from "@/components/ui/button/Button";
import { Identifiable, useUlElementContext } from "@/components/ui/list/List";
import { Separator } from "@/components/ui/separator/Separator";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { cn } from "@/lib/utils";
import { getAvatar } from "@/utils/getAvatar";
import { Plus, UserX, Users } from "lucide-react";
import {
  ReactElement,
  ReactNode,
  forwardRef,
  useCallback,
  useMemo,
} from "react";

interface UlElementProps<T extends Identifiable> {
  entity: T;
  data: {
    title?: string;
    detail?: string;
    avatarURL?: string;
  };
  className?: string;
  disabled?: boolean;
  actionButton?: (props: {
    icon?: ReactElement;
    onClick?: () => void;
    isSelected?: boolean;
  }) => ReactNode | undefined;
}

const LiElement = <T extends Identifiable>(props: UlElementProps<T>) => {
  const {
    entity,
    data: { title, detail, avatarURL },
    className,
    disabled = false,
    actionButton,
  } = props;

  const { removeElement, addElement, isSelected } = useUlElementContext();

  const handleAddElement = useCallback(() => {
    addElement(entity);
  }, [addElement, entity]);
  const handleRemoveElement = useCallback(() => {
    removeElement(entity.id);
  }, [removeElement, entity.id]);

  const state = isSelected(entity.id);

  const button = useMemo(() => {
    const actionButtonResult = actionButton?.({
      icon: state ? (
        <UserX className="h-4 w-4" />
      ) : (
        <Plus className="h-4 w-4" />
      ),
      isSelected: state,
      onClick: state ? () => handleRemoveElement() : () => handleAddElement(),
    });
    if (actionButtonResult) {
      return actionButtonResult;
    } else if (state) {
      return (
        <Button
          size={"sm"}
          variant={"ghost"}
          variantColor={"destructive"}
          onClick={handleRemoveElement}
          icon={<UserX />}
          iconPosition={"only"}
          disabled={disabled}
        />
      );
    }
    return (
      <Button
        size={"sm"}
        variant={"ghost"}
        variantColor={"brand"}
        onClick={handleAddElement}
        icon={<Plus />}
        iconPosition={"only"}
        disabled={disabled}
      />
    );
  }, [actionButton, disabled, handleAddElement, handleRemoveElement, state]);

  return (
    <li
      className={cn(
        "group flex h-fit items-center justify-between gap-3 overflow-hidden rounded-sm py-2",
        className,
      )}
    >
      <div className={"flex items-center gap-3"}>
        {avatarURL !== undefined && (
          <Avatar size={"sm"}>
            <AvatarImage src={getAvatar(avatarURL)} alt="avatar" />
            <AvatarFallback>
              <Users className={"h-4 w-4"} />
            </AvatarFallback>
          </Avatar>
        )}
        <div className={"flex flex-col gap-1"}>
          {title && (
            <h5 className={"w-full truncate text-md text-fg-primary"}>
              {title}
            </h5>
          )}
          {detail && (
            <small className={"truncate text-xs text-fg-muted"}>{detail}</small>
          )}
        </div>
      </div>
      {button}
    </li>
  );
};

interface LiElementLSkeletonProps {
  className?: string;
  hasAvatar?: boolean;
  hasTitle?: boolean;
  hasDetail?: boolean;
}

const LiElementLSkeleton = forwardRef<HTMLDivElement, LiElementLSkeletonProps>(
  (
    {
      className,
      hasAvatar = true,
      hasTitle = true,
      hasDetail = true,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={cn(
          "flex h-fit items-center gap-3 rounded-sm py-2",
          className,
        )}
        {...props}
      >
        {hasAvatar && <Skeleton className={"h-10 w-10 rounded-sm"} />}
        <div className={"flex flex-col gap-1"}>
          {hasTitle && <Skeleton className={"h-3 w-[7rem] rounded-sm"} />}
          {hasDetail && <Skeleton className={"h-3 w-[4rem] rounded-sm"} />}
        </div>
      </div>
    );
  },
);

LiElementLSkeleton.displayName = "LiElementLSkeleton";

const LiElementSeparator = ({
  message = "Wyszukiwane",
}: {
  message?: string;
}) => {
  return (
    <li className="my-2">
      <p className="text-xs text-fg-muted">{message}</p>
      <Separator className="mt-0.5" />
    </li>
  );
};

export { LiElement, LiElementLSkeleton, LiElementSeparator };
