import { axiosInstance } from "@/api/api";
import {
  LoginSchemaT,
  PasswordChangeSchemaType,
  PasswordResetSchemaType,
  SignupSchemaType,
} from "@/schemas/auth.schema";
import { CredentialsI } from "@/types/credentials";
import { UsersI } from "@/types/users";

interface PostLoginResponseT {
  redirectURL: string;
  user: CredentialsI;
}

export const postLogin = async (
  data: LoginSchemaT,
): Promise<PostLoginResponseT> => {
  return await axiosInstance.post("/auth/login", data);
};

export const postLogout = async (): Promise<string> => {
  return await axiosInstance.post("/auth/logout");
};

export const getSignup = async (token: string): Promise<UsersI> => {
  return await axiosInstance.get("/auth/signup", {
    params: { t: token },
  });
};

export const postSignup = async (data: SignupSchemaType): Promise<string> => {
  return await axiosInstance.post("/auth/signup", data);
};
export const postPasswordReset = async (
  data: PasswordResetSchemaType,
): Promise<string> => {
  return await axiosInstance.post("/auth/resetPassword", data);
};

export const postPasswordChange = async (
  data: PasswordChangeSchemaType,
): Promise<string> => {
  return await axiosInstance.post("/auth/passwordChange", data);
};
