import { MeetingI, MeetingsI } from "@/types/meetings";
import { UseInfiniteQueryResult, UseQueryResult } from "@tanstack/react-query";
import { ReactNode, createContext, useContext } from "react";

interface MeetingListContextT {
  meetings: MeetingI[] | MeetingsI[];
  isSuccess: boolean;
  isLoading: boolean;
  isFetching: boolean;
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
}

const MeetingListContext = createContext<MeetingListContextT>({
  meetings: [],
  isSuccess: false,
  isLoading: false,
  isFetching: false,
});

export const useMeetingListContext = () => {
  return useContext(MeetingListContext);
};

interface MeetingsListProps {
  query: UseInfiniteQueryResult | UseQueryResult;
  meetings: MeetingI[] | MeetingsI[];
  children: ReactNode;
}

export default function MeetingsList({
  children,
  meetings,
  query,
}: MeetingsListProps) {
  return (
    <MeetingListContext.Provider
      value={{
        meetings,
        isSuccess: query.isSuccess,
        isLoading: query.isLoading,
        isFetching: query.isFetching,
        fetchNextPage: (query as UseInfiniteQueryResult).fetchNextPage,
        hasNextPage: (query as UseInfiniteQueryResult).hasNextPage,
      }}
    >
      {children}
    </MeetingListContext.Provider>
  );
}
