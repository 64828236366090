import { httpErrorHandler } from "@/api/api";
import { useDeleteUserMutation as useDeleteUser } from "@/api/queries/usersQueries";
import { ProcessMultipleMutations } from "@/components/features/manage/ProcessMultipleMutations";
import {
  Alert,
  AlertContent,
  AlertDescription,
  AlertTitle,
} from "@/components/ui/alert/Alert";
import { Badge, badgeVariants } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import {
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/dialog/AlertDialog";
import { Spinner } from "@/components/ui/spinner/Spinner";
import { selectedRowsI } from "@/types/table";
import { UsersI } from "@/types/users";
import { VariantProps } from "class-variance-authority";
import { Check } from "lucide-react";
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";

export default function DeleteUser({
  selectedRows,
  setSelectedRows,
  open,
  onOpenChange,
}: {
  selectedRows: selectedRowsI<UsersI>[];
  setSelectedRows: Dispatch<SetStateAction<selectedRowsI<UsersI>[]>>;
  open: boolean;
  onOpenChange: (value: boolean) => void;
}) {
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const isSingle = selectedRows.length === 1;

  const { mutateAsync, isPending, error, isError, isSuccess, reset } =
    useDeleteUser();

  const errorResponse = httpErrorHandler(error);

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  const onSubmit = async () => {
    await ProcessMultipleMutations<UsersI>({
      selectedRows,
      setSelectedRows,
      mutateAsync,
    });
    setIsFinished(true);
  };

  const onClose = () => {
    setIsFinished(false);
    onOpenChange(false);
  };

  const header = useMemo(() => {
    if (isSingle) {
      return " Usunąć użytkownika ?";
    } else {
      return `Usunąć ${selectedRows.length} użytkowników?`;
    }
  }, [isSingle, selectedRows.length]);

  const content = useMemo(() => {
    if (isSingle && isSuccess) {
      return (
        <div className={"flex items-center gap-2"}>
          <span
            className={
              "flex h-9 w-9 items-center justify-center rounded-md bg-bg-success-subtle"
            }
          >
            <Check className={"a h-5 w-5 stroke-fg-success"} />
          </span>
          <p className={"text-fg-secondary"}>
            Użytkownik{" "}
            {selectedRows[0].data.name + " " + selectedRows[0].data.surname}{" "}
            został/a usunięty
          </p>
        </div>
      );
    } else if (isSingle && isError) {
      return (
        <Alert variant={"destructive"}>
          <AlertContent>
            <AlertTitle>{errorResponse.title}</AlertTitle>
            <AlertDescription>{errorResponse.detail}</AlertDescription>
          </AlertContent>
        </Alert>
      );
    } else if (isSingle) {
      return (
        <p className={"text-fg-secondary"}>
          Czy na pewno chcesz usunąć użytkownika{"  "}
          <b>
            {selectedRows[0].data.name + " " + selectedRows[0].data.surname}
          </b>
          ?
        </p>
      );
    } else {
      return (
        <p className={"text-fg-secondary"}>
          Czy na pewno chcesz usunąć wybranych użytkowników ?
        </p>
      );
    }
  }, [
    isSingle,
    isSuccess,
    isError,
    selectedRows,
    errorResponse.title,
    errorResponse.detail,
  ]);

  return (
    <AlertDialogContent className={"max-w-[45ch]"}>
      <AlertDialogHeader>
        <AlertDialogTitle>{header}</AlertDialogTitle>
      </AlertDialogHeader>
      <AlertDialogBody className={"flex flex-col gap-4"}>
        {content}
        {!isSingle && (
          <div className={"flex flex-col gap-3"}>
            <h5 className={"text-sm font-medium"}>Użytkownicy</h5>
            <div className={"flex flex-wrap gap-2"}>
              {selectedRows.map(({ data, status }) => {
                type VariantOnly = Pick<
                  VariantProps<typeof badgeVariants>,
                  "variant"
                >;
                const variantValue: VariantOnly = { variant: "muted" };
                if (status === "isError") {variantValue.variant = "destructive";}
                if (status === "isSuccess") {variantValue.variant = "success";}
                if (status === "isPending") {variantValue.variant = "brand";}

                return (
                  <Badge
                    key={data.id}
                    size={"sm"}
                    variant={variantValue.variant}
                    contentLeft={
                      status === "isPending" && <Spinner size={"sm"} />
                    }
                  >
                    {data.name + " " + data.surname}
                  </Badge>
                );
              })}
            </div>
          </div>
        )}
      </AlertDialogBody>
      <AlertDialogFooter>
        {isFinished ? (
          <Button variant={"ghost"} variantColor={"muted"} onClick={onClose}>
            Zamknij
          </Button>
        ) : (
          <Fragment>
            <Button variant={"ghost"} variantColor={"muted"} onClick={onClose}>
              Anuluj
            </Button>
            <Button
              autoFocus
              variant={"flat"}
              variantColor={"destructive"}
              onClick={onSubmit}
              isLoading={{ state: isPending }}
            >
              Potwierdź
            </Button>
          </Fragment>
        )}
      </AlertDialogFooter>
    </AlertDialogContent>
  );
}
