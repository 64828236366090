import { useCallback, useState } from "react";

interface UseHoverAndTouchProps {
  delay?: number;
}

export const useHover = ({ delay = 0 }: UseHoverAndTouchProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  const handleMouseEnter = useCallback(() => {
    if (timeoutId) {clearTimeout(timeoutId);}
    setIsHovered(true);
  }, [timeoutId]);

  const handleMouseLeave = useCallback(() => {
    timeoutId = setTimeout(() => {
      setIsHovered(false);
    }, delay);
  }, [delay, timeoutId]);

  const handleTouchStart = useCallback(() => {
    if (timeoutId) {clearTimeout(timeoutId);}
    setIsHovered(true);
  }, [timeoutId]);

  const handleTouchEnd = useCallback(() => {
    timeoutId = setTimeout(() => {
      setIsHovered(false);
    }, delay);
  }, [delay, timeoutId]);

  return {
    isHovered,
    setIsHovered,
    bind: {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
      onTouchStart: handleTouchStart,
      onTouchEnd: handleTouchEnd,
    },
  };
};
