import PageNotFound from "@/assets/images/errors/PageNotFound.svg?react";
import { Button } from "@/components/ui/button/Button";
import { useNavigate } from "react-router-dom";

export default function NotAllowedPage() {
  const navigate = useNavigate();

  return (
    <div>
      <div className={"default-page-row md:default-page-col"}>
        <div
          className={
            "flex w-full items-center justify-center md:h-fit md:max-w-[30rem]"
          }
        >
          <PageNotFound />
        </div>
        <div
          className={
            "flex h-full shrink-0 flex-col justify-center gap-6 md:h-fit"
          }
        >
          <div className={"flex flex-col gap-2"}>
            <h1
              className={
                "whitespace-nowrap text-start text-6xl font-semibold leading-snug sm:text-center"
              }
            >
              Nie masz <br /> wystarczających <br /> uprawnień
            </h1>
            <p className={"text-md text-fg-secondary"}>
              Niestety nie masz odpowiednich uprawnień aby otworzyć tą stronę.
            </p>
          </div>
          <div className={"flex flex-row gap-2 sm:flex-col-reverse"}>
            <Button
              variant={"flat"}
              variantColor={"brand"}
              onClick={() => navigate("/")}
            >
              Wróć do strony główna
            </Button>
            <Button
              variant={"solid"}
              variantColor={"muted"}
              onClick={() => navigate(-1)}
            >
              Wróć
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
