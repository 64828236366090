import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import Image from "@/components/ui/image/Image";
import { Link } from "@/components/ui/link/Link";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { cn } from "@/lib/utils";
import { NotificationI, NotificationType } from "@/types/notifications";
import { format, parseISO } from "date-fns";
import { pl } from "date-fns/locale";
import { Dot, LinkIcon } from "lucide-react";
import { forwardRef, useMemo } from "react";
import { useNavigate } from "react-router";
import { Z } from "vitest/dist/chunks/reporters.anwo7Y6a.js";

type NotificationCardProps = NotificationI & {
  makeRead: (id: number) => void;
};

const NotificationCard = forwardRef<HTMLDivElement, NotificationCardProps>(
  (props, ref) => {
    const { id, name, content, url, type, makeRead, read, createdAt } = props;
    const navigate = useNavigate();
    const isExternalUrl = useMemo((): boolean => {
      if (!url) return false;
      return url.startsWith("http://") || url.startsWith("https://");
    }, [url]);

    return (
      <div
        ref={ref}
        className={cn(
          "relative space-y-3 rounded-md border border-border bg-transparent p-2 transition-all duration-200 hover:bg-bg-element",
          read && "opacity-80",
        )}
      >
        <header className={"flex flex-col gap-2"}>
          <NotificationCardTypeBadge type={type} />
          <h5
            className={cn(
              "mr-8 truncate text-md",
              read ? "font-medium" : "font-semibold",
            )}
          >
            {name}
          </h5>
          <Button
            onClick={() => makeRead(id)}
            className={cn(
              "absolute right-0.5 top-0.5",
              read && "invisible opacity-0",
            )}
            size={"sm"}
            variant={"ghost"}
            variantColor={"muted"}
            iconPosition={"only"}
            icon={
              <Dot className={"fill-bg-brand stroke-bg-brand stroke-[10px]"} />
            }
          />
        </header>
        <div className={"flex flex-col gap-1"}>
          {url ? (
            isExternalUrl ? (
              <Link href={url} openInNewTab size={"sm"} className={"w-fit"}>
                <LinkIcon className={"h-4 w-4"} />
                Link
              </Link>
            ) : (
              <span
                className={
                  "flex w-fit cursor-pointer gap-1 text-sm text-fg-brand hover:underline"
                }
                onClick={() => navigate(url)}
              >
                <LinkIcon className={"h-4 w-4"} />
                Link
              </span>
            )
          ) : null}
          <p className={"line-clamp-4 text-pretty text-sm text-fg-secondary"}>
            {content}
          </p>
        </div>
        <footer className={"flex justify-between text-fg-muted"}>
          <p className={"text-xs"}>
            {format(parseISO(createdAt), "EEEE H:mm", { locale: pl })}
          </p>
          <p className={"text-xs"}>
            {format(parseISO(createdAt), "MMM d, yyyy", { locale: pl })}
          </p>
        </footer>
      </div>
    );
  },
);

const NotificationCardTypeBadge = ({ type }: { type: NotificationType }) => {
  const typeColor = {
    assignment: "bg-bg-accent",
    calendar: "bg-bg-accent",
    chat: "bg-bg-brand",
    group: "bg-bg-accent",
    grade: "bg-bg-accent",
  };
  const baseStyles = "h-3 w-1 min-w-1 grow rounded-xs";

  switch (type) {
    case "assignment":
      return (
        <Badge size={"sm"} variant={"muted"}>
          <span className={cn(baseStyles, typeColor[type])} /> Zadanie
        </Badge>
      );
    case "calendar":
      return (
        <Badge size={"sm"} variant={"muted"}>
          <span className={cn(baseStyles, typeColor[type])} /> Kalendarz
        </Badge>
      );
    case "chat":
      return (
        <Badge size={"sm"} variant={"muted"}>
          <span className={cn(baseStyles, typeColor[type])} /> Chat
        </Badge>
      );
    case "grade":
      return (
        <Badge size={"sm"} variant={"muted"}>
          <span className={cn(baseStyles, typeColor[type])} /> Ocena
        </Badge>
      );
    case "group":
      return (
        <Badge size={"sm"} variant={"muted"}>
          <span className={cn(baseStyles, typeColor[type])} /> Grupy
        </Badge>
      );
  }
};

const NotificationCardSkeleton = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      className={
        "relative space-y-3 rounded-md border border-border bg-transparent p-2 transition-all"
      }
    >
      <div className="flex flex-col gap-2">
        <Skeleton className={"h-4 w-13 rounded-sm"} />
        <Skeleton className={"h-4 w-1/2 rounded-sm"} />
      </div>
      <div className="flex flex-col gap-2">
        <Skeleton className={"h-3 w-full rounded-sm"} />
        <Skeleton className={"h-3 w-2/3 rounded-sm"} />
      </div>
      <div className="flex justify-between">
        <Skeleton className={"h-4 w-12 rounded-sm"} />
        <Skeleton className={"h-4 w-13 rounded-sm"} />
      </div>
    </div>
  );
});

export { NotificationCard, NotificationCardSkeleton };
