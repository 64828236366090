import { cn } from "@/lib/utils";

/**
 * ContentRowLinesProps interface
 *
 * This interface defines the properties for the ContentRowLines component.
 *
 * @interface
 * @property {number} space - The space between each line, default is 6.
 * @property {string} className - The CSS class to apply to the component.
 */

interface ContentRowLinesProps {
  space: number;
  className?: string;
}

export default function CalendarContentRowLines({
  className,
  space,
}: ContentRowLinesProps) {
  return (
    <div className={cn("", className)}>
      {Array.from({ length: 24 }).map((_, index) => (
        <div
          key={index}
          style={{ height: `${space}px` }}
          className={cn(
            "border-b-1 border-b-border",
            index === 0 && "border-t-1",
          )}
        />
      ))}
    </div>
  );
}
