import { Button } from "@/components/ui/button/Button";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { Spinner } from "@/components/ui/spinner/Spinner";
import { cn } from "@/lib/utils";
import { isEmptyString } from "@/utils/assertion";
import { getBase64 } from "@/utils/getBase64";
import { RefreshCcw } from "lucide-react";
import { forwardRef, useCallback, useMemo, useState } from "react";
import type { ImgHTMLAttributes, SyntheticEvent } from "react";

type NativeImageProps = ImgHTMLAttributes<HTMLImageElement>;

type ImageProps = {
  className?: string;
  layoutClassName?: string;
  loading?: NativeImageProps["loading"];
  onLoad?: NativeImageProps["onLoad"];
  onError?: NativeImageProps["onError"];
  type?: string;
  thumbnail?: string;
} & NativeImageProps;

export interface UseImageProps {
  onLoad?: NativeImageProps["onLoad"];
  onError?: NativeImageProps["onError"];
  width?: string | number;
  height?: string | number;
  type?: string;
  thumbnail?: string;
}

type Status = "error" | "idle" | "pending" | "success";

type ImageEvent = SyntheticEvent<HTMLImageElement, Event>;

const useImage = (props: UseImageProps) => {
  const {
    width: _width,
    height: _height,
    type,
    thumbnail,
    onLoad: _onLoad,
    onError: _onError,
  } = props;

  const [status, setStatus] = useState<Status>("pending");

  const isPending = status === "pending";
  const isError = status === "error";
  const isSuccess = status === "success";

  const onLoad = useCallback((event: ImageEvent) => {
    setStatus("success");
    _onLoad?.(event);
  }, []);
  const onError = useCallback((error: any) => {
    setStatus("error");
    _onError?.(error);
  }, []);

  const { width, height } = useMemo(() => {
    return {
      width: _width
        ? typeof _width === "number"
          ? `${_width}px`
          : _width
        : "fit-content",
      height: _height
        ? typeof _height === "number"
          ? `${_height}px`
          : _height
        : "auto",
    };
  }, [_width, _height]);

  const base64 = useMemo(() => {
    if (type && thumbnail) {
      return getBase64({
        fileType: type,
        base64: thumbnail,
        clear: true,
      });
    }
    return "";
  }, [thumbnail, type]);

  return {
    status,
    isPending,
    isError,
    isSuccess,
    onLoad,
    onError,
    width,
    height,
    base64,
  };
};

const Image = forwardRef<HTMLImageElement, ImageProps>(
  (
    {
      src,
      alt,
      className,
      layoutClassName,
      thumbnail,
      height: _height,
      width: _width,
      loading,
      ...props
    },
    ref,
  ) => {
    const {
      base64,
      width,
      height,
      isSuccess,
      isError,
      isPending,
      onLoad,
      onError,
    } = useImage({
      thumbnail: thumbnail,
      width: _width,
      height: _height,
      ...props,
    });
    return (
      <>
        <div
          className={cn(
            "relative flex h-full w-full overflow-hidden rounded-sm bg-bg-muted",
            isPending && "animate-pulse",
            layoutClassName,
          )}
        >
          {base64 && !isError ? (
            <img
              src={base64}
              alt={alt}
              className={cn(
                "absolute inset-0 z-10 h-full w-full grow object-contain text-center text-sm blur-md transition-all duration-700",
                isSuccess ? "opacity-0" : "opacity-100",
                className,
              )}
              {...props}
            />
          ) : null}

          {isPending && !isError ? (
            <Spinner
              size={"lg"}
              className={
                "absolute left-2/4 top-2/4 block -translate-x-2/4 -translate-y-2/4"
              }
              variant={"muted"}
            />
          ) : null}
          <img
            ref={ref}
            src={src}
            alt={alt}
            onLoad={onLoad}
            onError={onError}
            className={cn(
              "z-20 h-full w-full grow object-contain text-center text-sm transition-all duration-700",
              isSuccess ? "opacity-100" : "opacity-0",
              isPending && "blur-md",
              className,
            )}
            loading={loading}
            style={{ height: height, width: width }}
            {...props}
          />
        </div>
      </>
    );
  },
);
Image.displayName = "Image";

export default Image;
