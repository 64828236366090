import NotificationImage from "@/assets/images/notification.svg?react";
import { cn } from "@/lib/utils";

type NotificationsEmptyProps = {
  className?: string;
};

export default function NotificationsEmpty(props: NotificationsEmptyProps) {
  const { className } = props;
  return (
    <div className={cn("h-full space-y-8", className)}>
      <NotificationImage className="mx-auto" />

      <div
        className={"grid grid-cols-1 justify-items-center gap-4 text-center"}
      >
        <h5 className={"text-2xl font-semibold"}>Brak powiadomień</h5>
        <p className={"max-w-[35ch] text-xs text-fg-muted"}>
          W tej chwili nie masz nowych powiadomień. Wróć za jakiś czas, aby
          sprawdzić, czy pojawiły się nowe informacje.
        </p>
      </div>
    </div>
  );
}
