import { InferType, boolean, mixed, number, object, string } from "yup";

const PostAddSchema = object({
  name: string().required("Tytuł jest wymagany"),
  content: string().required("Treść jest wymagana"),
  active: boolean().default(true),
  attachments: mixed<File[]>().optional(),
});

type PostAddSchemaType = InferType<typeof PostAddSchema>;

const PostEditSchema = object({
  id: number().required(),
  name: string().required("Tytuł jest wymagany"),
  content: string().required("Treść jest wymagana"),
  active: boolean().default(true),
  attachments: mixed<File[]>().optional(),
});

type PostEditSchemaType = Partial<
  Omit<InferType<typeof PostEditSchema>, "id">
> &
  Pick<InferType<typeof PostEditSchema>, "id">;

export { PostAddSchema, PostEditSchema };
export type { PostAddSchemaType, PostEditSchemaType };
